import {Link, Route} from "react-router-dom";
import React, {useState} from "react";
import {batch, connect} from "react-redux";
import $ from "jquery";
import './AccountSettings.css';
import api from "../../../api/api";
import Cookies from "js-cookie";
import Loading from "../../Loading/Loading";


const formatNumber = (num : any) =>{
    return num.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const formatCurrency = (blur : boolean) => {
    let salaryVal = String($('#salary').val());

    if ((!salaryVal || salaryVal === '$' || (blur && salaryVal === '$.00')) && !$('#salary').is(':focus') ) {
        $('#salary').val('');
        $('#salary').next('label').removeClass('active');
        return;
    }

    const originalLen = salaryVal.length;
    let caretPos = $('#salary').prop('selectionStart');

    if (salaryVal.indexOf('.') >= 0){
        const decimalPos = salaryVal.indexOf('.');
        let leftSide = salaryVal.substring(0, decimalPos);
        let rightSide = salaryVal.substring(decimalPos);

        leftSide = formatNumber(leftSide);
        rightSide = formatNumber(rightSide)

        if (blur) rightSide += "00";

        rightSide = rightSide.substring(0, 2);

        salaryVal = "$" + leftSide + "." + rightSide;
    } else {
        salaryVal = formatNumber(salaryVal);
        salaryVal = "$" + salaryVal;

        if (blur) salaryVal += ".00";
    }

    $('#salary').val(salaryVal);

    const updatedLen = salaryVal.length;
    caretPos = updatedLen - originalLen + caretPos;

    let salaryInput = $('#salary').keyup()[0] as HTMLInputElement;
    salaryInput.setSelectionRange(caretPos, caretPos);

}

const SideMenu = () => {
    return(
        <div className={'settings-content border'}>
            <ul id={'side-menu-links'}>
                <li><Link to={'/client/settings/general/change-password'} className={'btn btn-link'}>Change Password</Link></li>
                <li><Link to={'/client/settings/general/address'} className={'btn btn-link'}>Address</Link></li>
                <li><Link to={'/client/settings/general/personal-info'} className={'btn btn-link'}>Personal Info</Link></li>
                <li><Link to={'/client/settings/general/questions'} className={'btn btn-link'}>Questions</Link></li>
            </ul>
        </div>
    )
}

const ChangePassword = (props : any) => {

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const submitPasswordChange = () => {

        const data = {
            current_password: currentPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
        }

        api.put('change-password/', data, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(()=>{
                props.setNotificationsList([{
                    title: 'Success',
                    body: res.data['details']
                }])
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            })
        }).catch(err => {
            console.log(err.response.data);
            props.setNotificationsList(Object.keys(err.response.data).map((key : string) => {
                return {
                    title: 'Error',
                    body: err.response.data[key]
                }
            }))
        })
    }

    return(
        <div>
            <h4>Change Password</h4>
            <div className={'general-settings-body'}>
                <table>
                    <tbody className={'md-form'}>
                    <tr>
                        <td><span>Current Password:</span></td>
                        <td><input type={'password'} className={'form-control'} onChange={(e)=>setCurrentPassword(e.target.value)} value={currentPassword}/></td>
                    </tr>
                    <tr>
                        <td><span>New Password:</span></td>
                        <td><input type={'password'} className={'form-control'} onChange={(e)=>setNewPassword(e.target.value)} value={newPassword}/></td>
                    </tr>
                    <tr>
                        <td><span>Confirm Password:</span></td>
                        <td><input type={'password'} className={'form-control'} onChange={(e)=>setConfirmPassword(e.target.value)} value={confirmPassword}/></td>
                    </tr>
                    <tr>
                        <td className={'text-center pt-4'} colSpan={2}>
                            <button className={'btn btn-success w-100'} onClick={submitPasswordChange}>Save</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const AddressSettings = (props : any) => {

    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [addressType, setAddressType] = useState('');
    const [addressDetails, setAddressDetails] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const stateCodeArr = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
    const addressTypeArr = ['House', 'Apartment', 'Condominium', 'Military Base', 'Dormitory', 'Other'];

    if(isLoading){
        api.get('clients/address/', {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(() => {
                setStreetAddress(res.data['street_address']);
                setCity(res.data['city']);
                setStateCode(res.data['state_code']);
                setZipCode(res.data['zip_code']);
                setAddressType(res.data['address_type'])
                setAddressDetails(res.data['address_type_details'])
            })
        }).then(() => {
            setIsLoading(false);
        })

        return (
            <Loading />
        )
    }

    const handleSubmit = () => {
        const data = {
            street_address: streetAddress,
            city,
            state_code: stateCode,
            zip_code: zipCode,
            address_type: addressType,
            address_type_details: addressType === 'Other' ? addressDetails : ''
        }
        api.put('clients/address/', data, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            props.setNotificationsList([{
                title: 'Success',
                body: res.data['details']
            }])
        }).catch(err =>{
            props.setNotificationsList(Object.keys(err.response.data).map((key : string) => {
                return {
                    title: 'Error',
                    body: err.response.data[key]
                }
            }));
        });
    }

    return(
        <div>
            <h4>Address</h4>
            <div className={'general-settings-body md-form'}>
                <div className="row">
                    <div className="col col-md-3">
                        Street Address:
                    </div>
                    <div className="col">
                        <input type="text" className={'form-control'} defaultValue={streetAddress} onChange={e => setStreetAddress(e.target.value)}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="row">
                            <div className="col col-md-2">
                                City:
                            </div>
                            <div className="col">
                                <input type="text" className={'form-control'} defaultValue={city} onChange={e => setCity(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="row">
                            <div className="col col-md-5">
                                Address Type:
                            </div>
                            <div className="col">
                                <select id="address-type" className="browser-default custom-select"
                                        onChange={e => setAddressType(e.target.value)}>
                                    <option value="default" disabled>Choose one</option>
                                    {
                                        addressTypeArr.map( (value) => {
                                            if(addressType === value)
                                                return <option value={value} selected>{value}</option>
                                            return <option value={value}>{value}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    addressType === 'Other' ?
                        <div className="row">
                            <div className="col col-md-3">
                                Address Details:
                            </div>
                            <div className="col">
                                <input type="text" className={'form-control'} onChange={e => setAddressDetails(e.target.value)} defaultValue={addressDetails} />
                            </div>
                        </div>
                        : null
                }
                <div className="row">
                    <div className="col-md">
                        <div className="row">
                            <div className="col col-md-2">
                                State:
                            </div>
                            <div className="col">
                                <select id="state-code" className="browser-default custom-select" onChange={e => setStateCode(e.target.value)}>
                                    <option value="default" disabled>Choose one</option>
                                    {
                                        stateCodeArr.map( (value) => {
                                            if(stateCode === value)
                                                return <option value={value} selected>{value}</option>
                                            return <option value={value}>{value}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="row">
                            <div className="col col-md-4">
                                Zip Code:
                            </div>
                            <div className="col">
                                <input type="text" className={'form-control'} defaultValue={zipCode} onChange={e => setZipCode(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-success w-100" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PersonalInfo = (props : any) => {

    const [salary, setSalary] = useState('');
    const [lastFour, setLastFour] = useState('');
    const [ownsCar, setOwnsCar] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    if(isLoading){
        api.get('clients/personal-info/', {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => setOwnsCar(res.data['owns_car']))
            .then(() => setIsLoading(false))

        return <Loading />;
    }

    const handleSubmit = () => {
        const data = {
            salary: parseFloat(salary.substring(1).replace(',', '')),
            ssn_last_four: lastFour,
            owns_car: ownsCar
        }
        api.put('clients/personal-info/', data, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            props.setNotificationsList([{
                title: 'Success',
                body: res.data['details']
            }])
        }).catch(err =>{
            props.setNotificationsList(Object.keys(err.response.data).map((key : string) => {
                return {
                    title: 'Error',
                    body: err.response.data[key]
                }
            }));
        })
    }

    return(
        <div>
            <h4>Personal Info</h4>
            <div className={'general-settings-body md-form'}>
                <div className="row">
                    <div className="col-lg">
                        <div className="row">
                            <div className="col">
                                Salary:
                            </div>
                            <div className="col">
                                <input type="text" id={'salary'} pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" data-type="currency"
                                       className="form-control" onKeyUp={() => formatCurrency(false)}
                                       onChange={e => setSalary(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg">
                        <div className="row">
                            <div className="col">
                                Last Four SSN:
                            </div>
                            <div className="col">
                                <input type="text" maxLength={4} className={'form-control'} onChange={e => setLastFour(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-md-3">
                        Do you own a car?
                    </div>
                    <div className="col">
                        <select className={'browser-default custom-select'} onChange={e => setOwnsCar(e.target.value)}>
                            {
                                ['Yes', 'No'].map(value => {
                                    if(value === ownsCar)
                                        return <option value={value} selected>{value}</option>
                                    return <option value={value}>{value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-success w-100" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const QuestionSettings = (props : any) => {
    return(
        <div>
            Question settings
        </div>
    )
}

const GeneralSettings = (props : any) => {
    const setNotificationsList = props.setNotificationsList;
    const notificationsList = props.notificationsList;
    let timeout = null;

    if (notificationsList.length > 0) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        timeout = setTimeout(() => {
            setNotificationsList([]);
        }, 7000)
    }

    return (
        <div className="row">
            <div className="col-md-3">
                <SideMenu />
            </div>
            <div className="col">
                <div className={'settings-content border'}>
                    <Route path={'/client/settings/general/change-password'} render={()=><ChangePassword setNotificationsList={setNotificationsList}/>}/>
                    <Route path={'/client/settings/general/address'} render={() => <AddressSettings setNotificationsList={setNotificationsList}/>}/>
                    <Route path={'/client/settings/general/personal-info'} render={() => <PersonalInfo setNotificationsList={setNotificationsList}/>}/>
                    <Route path={'/client/settings/general/questions'} render={() => <QuestionSettings setNotificationsList={setNotificationsList}/>}/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        clientInfo: state.clientInfo
    }
}

export default connect(mapStateToProps)(GeneralSettings)