import React, {useState} from "react";
import {batch} from "react-redux";
import api from "../../api/api";
import Cookies from "js-cookie";
import './Admin.css';


const ChangePassword = (props) => {

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const submitPasswordChange = () => {

        const data = {
            current_password: currentPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
        }

        api.put('change-password/', data, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(()=>{
                props.createNotifications('success', [
                    {
                        title: res.data['details'],
                        body: ''
                    }
                ]);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            })
        }).catch(err => {
            console.log(err.response.data);
            Object.keys(err.response.data).forEach(key => {
                props.createNotifications('error', [
                    {
                        body: err.response.data[key],
                        title: 'Error Occurred'
                    }
                ]);
            });
        })
    }

    return(
        <div>
            <h4>Change Password</h4>
            <div className={'general-settings-body'}>
                <table>
                    <tbody className={'md-form'}>
                    <tr>
                        <td><span>Current Password:</span></td>
                        <td><input type={'password'} className={'form-control'} onChange={(e)=>setCurrentPassword(e.target.value)} value={currentPassword}/></td>
                    </tr>
                    <tr>
                        <td><span>New Password:</span></td>
                        <td><input type={'password'} className={'form-control'} onChange={(e)=>setNewPassword(e.target.value)} value={newPassword}/></td>
                    </tr>
                    <tr>
                        <td><span>Confirm Password:</span></td>
                        <td><input type={'password'} className={'form-control'} onChange={(e)=>setConfirmPassword(e.target.value)} value={confirmPassword}/></td>
                    </tr>
                    <tr>
                        <td className={'text-center pt-4'} colSpan={2}>
                            <button className={'btn btn-success w-100'} onClick={submitPasswordChange}>Save</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}


const AdminGeneralSettings = props => {

    return (
        <div className="row form-body">
            <div className="col">
                <div className={'settings-content border'}>
                    <ChangePassword createNotifications={props.createNotifications}/>
                </div>
            </div>
        </div>
    );
}

export default AdminGeneralSettings