import {
    SET_CLIENTS_LIST,
    SET_CURRENT_PAGE_NUMBER,
    SET_IS_CLIENTS_LIST_LOADING,
    SET_TOTAL_PAGES
} from "../actions/actionTypes";


const initialState : any = {
    clientsList: [],
    isClientsListLoading: true,
    currentPageNumber: 1,
    totalPages: 1
}

const clientListReducer = (state = initialState, action : any) => {
    switch (action.type) {
        case SET_CLIENTS_LIST:
            return {
                ...state,
                clientsList: action.payload
            }
        case SET_IS_CLIENTS_LIST_LOADING:
            return {
                ...state,
                isClientsListLoading: action.payload
            }
        case SET_CURRENT_PAGE_NUMBER:
            return {
                ...state,
                currentPageNumber: action.payload
            }
        case SET_TOTAL_PAGES:
            return {
                ...state,
                totalPages: action.payload
            }
        default:
            return state;
    }
}

export default clientListReducer;