import React, {useState} from 'react';
import {batch, connect, useDispatch} from "react-redux";
import {setUserSession} from "../../../redux/actions/user";
import Loading from "../../Loading/Loading";
import {Route, useParams} from "react-router";
import api from "../../../api/api";
import Cookies from "js-cookie";
import formatPhoneNumber from "../../../api/formatPhoneNumber";
import './ClientConsole.css';
import {Link} from "react-router-dom";
import ClientTaskList from './ClientTaskList';
import ClientBudget from "./ClientBudget";
import ClientBudgetEditable from "./ClientBudgetEditable";


const parseBudgetResponse = (res, setBudgetList, setModifiedBudgetList, setMonthlyBudget, setMonthlyExpenses, setIdCounter) => {

    let id = 0;
    const tempBudgetList = res.data.map(budget => (
        {
            id: id++,
            title: budget.title,
            budgetedAmount: budget.budgeted_amount,
            expended: budget.expended
        }
    ));

    tempBudgetList.sort((x, y) =>
        (Math.abs(x.budgetedAmount - x.expended) < Math.abs(y.budgetedAmount - y.expended)) ?
            1 : -1
    )

    batch(() => {
        setIdCounter(id);
        setBudgetList(tempBudgetList);
        setModifiedBudgetList(tempBudgetList)
        setMonthlyBudget(
            tempBudgetList
                .map(budget => budget.budgetedAmount)
                .reduce((prev, next) => {
                    return prev + next
                })
        );
        setMonthlyExpenses(
            tempBudgetList
                .map(budget => budget.expended)
                .reduce((prev, next) => {
                    return prev + next
                })
        );
    });
}

const ClientConsoleMain = props => {

    const dispatch = useDispatch();
    const params = useParams();
    const clientId = params.userId;
    let initialActiveLink = 'budget'

    if(props.location.pathname.includes('task-list')) {
        initialActiveLink = 'tasks'
    }


    const [isClientLoading, setIsClientLoading] = useState(true);
    const [isBudgetLoading, setIsBudgetLoading] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [budgetList, setBudgetList] = useState([]);
    const [modifiedBudgetList, setModifiedBudgetList] = useState(budgetList);
    const [idCounter, setIdCounter] = useState(2);
    const [monthlyBudget, setMonthlyBudget] = useState(0);
    const [monthlyExpenses, setMonthlyExpenses] = useState(0);
    const [activeLink, setActiveLink] = useState(initialActiveLink)

    let isLoading = false;

    const handleBudgetHeaderChange = budgetList => {
        if(budgetList.length > 0) {
            setMonthlyBudget(
                budgetList
                    .map(budget => parseFloat(budget.budgetedAmount) || 0)
                    .reduce((prev, next) => {
                        return prev + next
                    })
            );
            setMonthlyExpenses(
                budgetList
                    .map(budget => parseFloat(budget.expended) || 0)
                    .reduce((prev, next) => {
                        return prev + next
                    })
            );
        } else {
            setMonthlyBudget(0);
            setMonthlyExpenses(0);
        }
    }

    if (isClientLoading) {
        isLoading = true;

        api.get('clients/' + clientId + '/', {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(() => {
                setName(res.data.first_name + ' ' + res.data.last_name);
                setEmail(res.data.email);
                setPhoneNumber(formatPhoneNumber(res.data.phone_number));
            })
        }).catch(err => {
            // TODO: handle error
        }).finally(() => {
            setIsClientLoading(false);
        })
    }

    if(isBudgetLoading) {
        isLoading = true;

        api.get('budgets/' + clientId + '/', {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            parseBudgetResponse(res, setBudgetList, setModifiedBudgetList, setMonthlyBudget, setMonthlyExpenses, setIdCounter);
        }).catch(err => {
            // TODO: handle error
        }).finally(() => {
            setIsBudgetLoading(false);
        })
    }

    if(props.userInfo.isUserLoading){
        dispatch(setUserSession());
        isLoading = true;
    }

    if(isLoading)
        return <Loading />;

    if(!isEditing)
        return (
            <div className={'d-flex'}>
                    <div>
                        <div className={'left-side-nav'}>
                            <li>
                                <Link
                                    to={`${props.match.url}`}
                                    id={'nav-budget-btn'}
                                    className={activeLink === 'budget' ? 'active' : ''}
                                    onClick={() => setActiveLink('budget')}
                                >
                                    <div><i className="fas fa-wallet fa-lg"></i></div>
                                    <div>Budget</div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`${props.match.url}/task-list`}
                                    id={'nav-task-list-btn'}
                                    className={activeLink === 'tasks' ? 'active' : ''}
                                    onClick={() => setActiveLink('tasks')}
                                >
                                    <div><i className="fas fa-list fa-lg"></i></div>
                                    <div>Tasks</div>
                                </Link>
                            </li>
                        </div>
                    </div>
                    <div className={'consultant-budget-container box-shadow'}>
                        <Route exact path={`${props.match.url}`} render={() => <ClientBudget
                            name={name}
                            setIsEditing={setIsEditing}
                            handleBudgetHeaderChange={handleBudgetHeaderChange}
                            modifiedBudgetList={modifiedBudgetList}
                            monthlyBudget={monthlyBudget}
                            monthlyExpenses={monthlyExpenses}
                            budgetList={budgetList}
                        />} />
                        <Route path={`${props.match.url}/task-list`} render={() =>
                            <ClientTaskList name={name} clientId={clientId} />} />
                    </div>
            </div>
        )
    else
        return (
            <div className={'d-flex'}>
                <div>
                    <div className={'left-side-nav'}>
                        <li>
                            <button id={'nav-budget-btn'} className={'active'}>
                                <div><i className="fas fa-wallet fa-lg"></i></div>
                                <div>Budget</div>
                            </button>
                        </li>
                        <li>
                            <button id={'nav-task-list-btn'}>
                                <div><i className="fas fa-list fa-lg"></i></div>
                                <div>Tasks</div>
                            </button>
                        </li>
                    </div>
                </div>
                <div className={'consultant-budget-container'}>
                    <ClientBudgetEditable
                        name={name}
                        setIsEditing={setIsEditing}
                        handleBudgetHeaderChange={handleBudgetHeaderChange}
                        modifiedBudgetList={modifiedBudgetList}
                        monthlyBudget={monthlyBudget}
                        monthlyExpenses={monthlyExpenses}
                        budgetList={budgetList}
                        setBudgetList={setBudgetList}
                        createNotifications={props.createNotifications}
                        setModifiedBudgetList={setModifiedBudgetList}
                        idCounter={idCounter}
                        setIdCounter={setIdCounter}
                    />
                </div>
            </div>
        )
}

const mapStateToProps = state => {
    return {
        userInfo : state.userInfo
    }
}

export default connect(mapStateToProps)(ClientConsoleMain);