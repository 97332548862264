import {
    SET_COLLABORATOR_LIST,
    SET_CONNECTED_CLIENTS,
    SET_CONNECTED_COLLABORATORS,
    SET_CURRENT_PAGE_NUMBER,
    SET_INCOMING_REQUESTS,
    SET_IS_COLLABORATORS_LOADING,
    SET_IS_CONNECTED_LIST_LOADING,
    SET_OUTGOING_REQUESTS,
    SET_TOTAL_PAGES
} from '../actions/actionTypes'

const initialState : any = {
    collaboratorList: [],
    incomingRequestsList: [],
    outgoingRequestsList: [],
    currentPageNum: 1,
    totalPages: 1,
    isCollaboratorsLoading: true,
    isConnectedListLoading: true,
    connectedAgentsList: [],
    connectedConsultantsList: [],
    connectedClientsList: []
}

const connectedListReducer = (state = initialState, action : any) => {
    switch(action.type) {
        case SET_COLLABORATOR_LIST:
            return {
                ...state,
                collaboratorList: action.payload
            };
        case SET_OUTGOING_REQUESTS:
            return{
                ...state,
                incomingRequestsList: action.payload
            }
        case SET_INCOMING_REQUESTS:
            return {
                ...state,
                outgoingRequestsList: action.payload
            }
        case SET_CURRENT_PAGE_NUMBER:
            return{
                ...state,
                currentPageNum: action.payload
            }
        case SET_TOTAL_PAGES:
            return{
                ...state,
                totalPages: action.payload
            }
        case SET_IS_COLLABORATORS_LOADING:
            return {
                ...state,
                isCollaboratorsLoading: action.payload
            }
        case SET_CONNECTED_COLLABORATORS:
            return {
                ...state,
                connectedAgentsList: [...action.payload.agents],
                connectedConsultantsList: [...action.payload.consultants]
            }
        case SET_CONNECTED_CLIENTS:
            return {
                ...state,
                connectedClientsList: [...action.payload]
            }
        case SET_IS_CONNECTED_LIST_LOADING:
            return {
                ...state,
                isConnectedListLoading: action.payload
            }
        default:
            return state;
    }
}

export default connectedListReducer;