import api from "../../api/api";
import Cookies from "js-cookie";
import {SET_ACCOUNT_LIST, SET_IS_ACCOUNT_LIST_LOADING} from "./actionTypes";


export const setAccountList = () => (dispatch : Function) => {

    api.get('plaid-client/account-info/',
        {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token'),
                Cookies: 'user_token=' + Cookies.get('user_token')
            },
            withCredentials: true
        })
        .then(res => {
            dispatch({
                type: SET_ACCOUNT_LIST,
                payload: {
                    isAccountListLoading: false,
                    accountList:res.data
                }
            })
        })
}

export const setIsAccountListLoading = (payload : any) => ({
    type: SET_IS_ACCOUNT_LIST_LOADING,
    payload
})