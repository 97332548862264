import {SET_ACCOUNT_LIST, SET_IS_ACCOUNT_LIST_LOADING} from "../actions/actionTypes";


const initialState : any = {
    isAccountListLoading: true,
    accountList: []
};

const clientAccountsReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case SET_IS_ACCOUNT_LIST_LOADING:
            return {
                ...state,
                isAccountListLoading: action.payload
            };
        case SET_ACCOUNT_LIST:
            return {
                ...state,
                accountList: action.payload.accountList,
                isAccountListLoading: action.payload.isAccountListLoading
            }
        default:
            return state;
    }
}

export default clientAccountsReducer;