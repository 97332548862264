import {ResponsiveBar} from "@nivo/bar";
import React from "react";

const BudgetBarGraph = ({ category }) => (
    <ResponsiveBar
        data={[
            {
                "index": '$',
                "Spent": category.expended ? category.expended.toFixed(2) : 0.00,
                "Remaining": category.budgetedAmount > category.expended ? (category.budgetedAmount - category.expended).toFixed(2) : 0,
            },
        ]}
        keys={[ 'Spent', 'Remaining' ]}
        indexBy="index"
        margin={{ top: 50, right: 130, bottom: 60, left: 60 }}
        padding={0.3}
        layout="horizontal"
        tooltip={({ id, value }) => (
            <strong>
                {id}: ${value}
            </strong>
        )}
        colors={category.budgetedAmount < category.expended ? ['#f87171'] : (category.expended > 0 ? ['#59c229', '#e3e3e3'] : ['#e3e3e3'])}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        markers={[
            {
                axis: 'x',
                value: category.budgetedAmount,
                lineStyle: { stroke: '#f47560', strokeWidth: 1 },
                textStyle: { fill: '#e25c3b' },
                legend: '$' + category.budgetedAmount,
                legendPosition: 'bottom-left',
                legendOrientation: 'horizontal',
                legendOffsetY: 70,
                legendOffsetX: -10,
            },
        ]}
    />
)

export default BudgetBarGraph