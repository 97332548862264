import {connect, useDispatch, batch} from "react-redux";
import React from "react";
import Cookies from "js-cookie";
import api from "../../../api/api";
import {setClientSession} from "../../../redux/actions/client";
import {PlaidLink, usePlaidLink} from "react-plaid-link";
import {setAccountList} from "../../../redux/actions/clientBankAccounts";
import Loading from "../../Loading/Loading";
import {setUserSession} from "../../../redux/actions/user";
import $ from 'jquery';

// TODO: On successfully adding a bank account, re-render bankaccount list

const PlaidLinkButton = (props) => {
    const dispatch = useDispatch();

    let configProps = {
        product: ['auth', 'transactions'],
        onSuccess: (publicToken, metadata) => {
            const authToken = Cookies.get('auth_token');
            const data = {
                public_token: publicToken
            };
            api.post('plaid-client/', data,
                {
                    headers: {
                        Authorization: 'Token ' + authToken,
                        Cookies: 'user_token=' + Cookies.get('user_token')
                    },
                    withCredentials: true
                }
            ).then(res => {
                dispatch(setClientSession());
            }).finally(() => dispatch(setUserSession(true)))
        }
    };

    if(props.bank) {
        configProps = {
            product: ['transactions'],
            token: props.bank.is_update_required ? props.bank.plaid_public_token : null,
            onSuccess: (publicToken, metadata) => {
                const authToken = Cookies.get('auth_token');
                const data = {
                    public_token: publicToken,
                    is_update: true
                };
                api.post('plaid-client/', data,
                    {
                        headers: {
                            Authorization: 'Token ' + authToken,
                            Cookies: 'user_token=' + Cookies.get('user_token')
                        },
                        withCredentials: true
                    }
                ).then(res => {
                    dispatch(setClientSession());
                }).finally(() => dispatch(setUserSession(true)))
            }
        }
    }

    const config = {
        ...configProps,
        clientName: 'LinkUBank',
        env: 'sandbox',
        token: props.clientInfo.plaidLinkToken,
        onExit: (error, metadata) => {
            batch(() => {
                dispatch(setClientSession());
                dispatch(setUserSession(true));
            })
        }
    };

    const { open } = usePlaidLink(config);

    if(props.bank && props.bank.is_update_required)
        return <button
            className={'btn icon-btn mr-3'}
            data-toggle={'tooltip'}
            title={'Update Login'}
            onClick={() => open()}
        >
            <i className="fa fa-lg fa-upload update-login-icon"></i>
        </button>
    else
        return <button className={'btn btn-one plaid-btn border-0'} onClick={() => open()}>
            Add Bank Account
        </button>
}

const rotateIcon = id  => {
    if ($('#open-account-icon-' + id).hasClass('accounts-expanded')) {
        $('#open-account-icon-' + id).removeClass('accounts-expanded')
        $('#open-account-icon-' + id).addClass('accounts-collapsed')
    } else {
        $('#open-account-icon-' + id).removeClass('accounts-collapsed')
        $('#open-account-icon-' + id).addClass('accounts-expanded')
    }
}

const deleteBankAccountItem = (dispatch, publicToken) => {
    const deleteRequest = {
        bank_account_id: publicToken
    }
    api.delete('/plaid-client/',
        {
            data: deleteRequest,
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token'),
                Cookies: 'user_token=' + Cookies.get('user_token')
            },
            withCredentials: true
        })
        .then(res => {
            dispatch(setAccountList());
        }).catch(err => {
            //TODO: handle error
    })
}

const BankAccountItem = props => {

    const cardId = 'bank-' + props.id;
    const collapsibleId = 'collapsible-' + props.id;
    const dispatch = useDispatch();

    return (
        <div className={'accordian w-100'}>
            <div className="card">
                <div className="card-header btn btn-light bank-card-header" id={cardId} data-toggle="collapse"
                     data-target={"#" + collapsibleId} aria-expanded="true" aria-controls={collapsibleId}
                     onClick={() => rotateIcon(props.id)}>
                    <h4 className="mb-0 float-left">
                        {props.bank.institution_name}
                    </h4>
                    <div className={'float-right'}>
                        <i className="fas fa-2x fa-angle-down" id={'open-account-icon-' + props.id}></i>
                    </div>
                </div>
                <div id={collapsibleId} className="collapse bank-collapsible" aria-labelledby={cardId}>
                    <div className="row account-header">
                        <div className="col">
                            <h5>Account Type</h5>
                        </div>
                        <div className="col">
                            <h5>Account Number</h5>
                        </div>
                        <div className="col-2 text-right">
                            {
                                props.bank.is_update_required ?
                                    <PlaidLinkButton clientInfo={props.clientInfo} bank={props.bank} />
                                    // <button
                                    //     className={'btn icon-btn mr-3'}
                                    //     data-toggle={'tooltip'}
                                    //     title={'Update Login'}
                                    //     onClick={() => open()}
                                    // >
                                    //     <i className="fa fa-lg fa-upload update-login-icon"></i>
                                    // </button>
                                :
                                    null
                            }
                            <button
                                className="btn icon-btn"
                                data-toggle={'tooltip'}
                                title={'Delete bank account'}
                                onClick={() => deleteBankAccountItem(props.dispatch, props.bank.public_token)}
                            >
                                <i className="fas fa-lg fa-trash-alt edit-delete-icon"></i>
                            </button>
                        </div>
                    </div>
                    <div className={'account-details'}>
                        {
                            props.bank.accounts.map(account => (
                                <div className="row border-top">
                                    <div className="col">
                                        {account.type}
                                    </div>
                                    <div className="col">
                                        {account.number}
                                    </div>
                                    <div className="col-2 text-right"/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const BankAccountList = props => {

    if (props.accountsList && props.accountsList.length > 0)
        return props.accountsList.map((bank, index) =>
            <BankAccountItem bank={bank} id={index} clientInfo={props.clientInfo}/>
        );
    else
        return (
            <div className={'row justify-content-center'}>
                <p>No bank accounts are linked</p>
            </div>
        )
};

const BankSettings = props => {

    const dispatch = useDispatch();

    // TODO: on success add loading overlay that Josh will create until created
    //       bank account has rendered
    const handleSuccess = publicToken => {
        const authToken = Cookies.get('auth_token');
        const data = {
            public_token: publicToken
        };
        api.post('plaid-client/', data,
            {
                headers: {
                    Authorization: 'Token ' + authToken,
                    Cookies: 'user_token=' + Cookies.get('user_token')
                },
                withCredentials: true
            }
        ).then(res => {

            if (res.status === 200) {
                dispatch(setClientSession());
            }
        }).catch(err => {
            // TODO: handle error if post plaid link submission failed
        }).finally(() => {
            dispatch(setAccountList())
        })
    };

    if (props.clientInfo.isClientSessionLoading) {
        dispatch(setClientSession());

        return <Loading />;
    } else {
        if (props.clientAccounts.isAccountListLoading) {
            dispatch(setAccountList());

            return <Loading />;
        }

        return (
            <div className={'justify-content-center'}>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et
                    dolore magna aliqua. Est ultricies integer quis auctor elit. Lectus vestibulum mattis ullamcorper
                    velit sed
                    ullamcorper morbi tincidunt ornare. Id nibh tortor id aliquet lectus. Quam id leo in vitae turpis
                    massa sed
                    elementum tempus. Consequat ac felis donec et odio. Id diam vel quam elementum pulvinar etiam. Mi
                    bibendum
                    neque egestas congue quisque egestas diam. Tristique magna sit amet purus gravida. Pharetra magna ac
                    placerat vestibulum lectus mauris ultrices eros. Nibh mauris cursus mattis molestie a iaculis.
                    Convallis a
                    cras semper auctor neque. Laoreet sit amet cursus sit amet dictum sit.
                </p>
                <div id={'bank-container'}>
                    <BankAccountList
                        accountsList={props.clientAccounts.accountList}
                        clientInfo={props.clientInfo}
                        dispatch={dispatch}
                    />
                    <div className={'d-flex justify-content-center mt-3'}>
                        <PlaidLinkButton clientInfo={props.clientInfo} />
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        clientInfo: state.clientInfo,
        clientAccounts: state.clientAccounts
    }
}

export default connect(mapStateToProps)(BankSettings);