import React, {useState} from "react";
import './ForgotPassword.css';
import api from "../../api/api";
import {Redirect} from "react-router";
import {batch} from "react-redux";

const ForgotPassword = (props : any) => {

    const [email, setEmail] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const submitResetRequest = () => {
        setIsDisabled(true);
        api.get('/forgot-password/' + email).then(() =>
            batch(()=>{
                setIsDisabled(false);
                setIsSubmitted(true);
            })
        ).then(res => props.createNotifications('success', [{body: '', title: 'Reset password email sent'}]))
    }

    if(isSubmitted) return <Redirect to={'/'} />

    return(
        <div className={'forgot-password-content'}>
            <h4>Forgot Password</h4>
            <div className={'reset-password-section'}>
                <div className={'row'}>
                    <div className="col">
                        <p>
                            Enter your email and click 'Reset Password'. You will be sent an email with a link to reset your password. If you cannot find the email after 10 minutes please check your junk or spam mail.
                        </p>
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col">
                        <div className="md-form">
                            <input type="text" id={'forgot-pass-input'} className={'form-control'} onChange={e => setEmail(e.target.value)}/>
                            <label htmlFor={'forgot-pass-input'} >Email</label>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col text-center">
                        <button disabled={isDisabled} className="btn btn-one" onClick={submitResetRequest}>Reset Password</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;