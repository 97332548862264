import './Register.css';

import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import api from '../../api/api';
import {setConfirmPassword, setEmail, setFirstName, setLastName, setPassword} from '../../redux/actions/user';

// TODO: check username against database
// TODO: check if email is an active email account

// TIP: if error enlarges and slides down when the label does the same, check that ^$ 
//      is an option in the regex, otherwise ensure the field className contains validate

// const usernameChecker = (usernameField : any) => {
//     const pattern = /^$|^(?=.{6,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/i;
//     const isValid = pattern.test(usernameField.val());
//     if (!isValid) {
//         usernameField.next('label').attr('data-error', 'Invalid');
//         usernameField.addClass('invalid');
//     }
// }

const nameChecker = (nameField : any) => {
    const pattern = /^$|^[a-z ,.'-]+$/i;
    const isValid = pattern.test(nameField.val());
    if (!isValid) {
        nameField.next('label').attr('data-error', 'Invalid');
        nameField.addClass('invalid');
    } else {
        nameField.next('label').attr('data-error', '');
        nameField.removeClass('invalid');
    }
}

const emailChecker = (emailField: any) => {
    const pattern = /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    const email = emailField.val();
    const isValid = pattern.test(email)
    if (!isValid) {
        emailField.next('label').attr('data-error', 'Invalid');
        emailField.addClass('invalid');
    } else if (!email) {
        emailField.next('label').attr('data-error', '');
        emailField.removeClass('invalid');
    } else {
        
        api.get('register/' + encodeURIComponent(email) + '/')
            .then(res => {
                if(res.status === 200){
                    emailField.next('label').attr('data-error', '');
                    emailField.removeClass('invalid');
                }
            }, err => {
                emailField.next('label').attr('data-error', err.response.data);
                emailField.addClass('invalid');
            })
    }
}

const passwordMatcher = (passwordField : any, confPasswordField : any) => {

    const doPasswordsMatch = (passwordField.val() === confPasswordField.val());

    if (!doPasswordsMatch) {
        passwordField.next('label').attr('data-error', '');
        passwordField.addClass('invalid');

        confPasswordField.next('label').attr('data-error', 'Passwords don\'t match');
        confPasswordField.addClass('invalid');
    }

    return doPasswordsMatch;
}

const passwordChecker = (passwordField : any, confPasswordField : any) => {
    const pattern = /^$|^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()/\\\-_=+[\](){}<>?;:'"|`~]{6,}$/i;
    const isValid = pattern.test(passwordField.val()) && pattern.test(confPasswordField.val());
    if (!isValid) {
        confPasswordField.next('label').attr('data-error', 'Doesn\'t meet requirements');
        passwordField.addClass('invalid');
        confPasswordField.addClass('invalid');
    } else {
        passwordField.removeClass('invalid');
        confPasswordField.removeClass('invalid');
    }
}

const validateUserInfo = (dispatch : any, data : any) => {
    if (data.attr('id') === 'first-name') {

        dispatch(setFirstName(data.val()));
        nameChecker(data);

    } else if (data.attr('id') === 'last-name') {

        dispatch(setLastName(data.val()));
        nameChecker(data);

    } else if (data.attr('id') === 'email') {

        // TODO: valid email check
        dispatch(setEmail(data.val()));
        emailChecker(data);

    } else if (data.attr('id') === 'password') {

        dispatch(setPassword(data.val()));
        
        const doPasswordsMatch = passwordMatcher(data, $('#confirm-password'));
        if(doPasswordsMatch)    
            passwordChecker(data, $('#confirm-password'));

    } else if (data.attr('id') === 'confirm-password') {

        dispatch(setConfirmPassword(data.val()));
        
        const doPasswordsMatch = passwordMatcher($('#password'), data);
        if(doPasswordsMatch) 
            passwordChecker($('#password'), data);
    }
    //  else if (data.attr('id') === 'username'){

    //     // TODO: check with username database
    //     dispatch(setUsername(data.val()));
    //     usernameChecker(data);

    // }
}

const validateForm = (data : any) => {
    if (data.attr('id') === 'first-name') {

        nameChecker(data);

    } else if (data.attr('id') === 'last-name') {

        nameChecker(data);

    } else if (data.attr('id') === 'email') {

        // TODO: valid email check
        emailChecker(data);

    } else if (data.attr('id') === 'password') {

        const doPasswordsMatch = passwordMatcher(data, $('#confirm-password'));
        if(doPasswordsMatch)    
            passwordChecker(data, $('#confirm-password'));

    } else if (data.attr('id') === 'confirm-password') {

        const doPasswordsMatch = passwordMatcher($('#password'), data);
        if(doPasswordsMatch) 
            passwordChecker($('#password'), data);
    }
}

const setLabelActivity = (password : String, confirmPassword: String) => {
    const passInput = $('#password'); 
    const confPassInput = $('#confirm-password');

    if (passInput.val() || confPassInput.val() ||
        password || confirmPassword || passInput.is(':focus') ||
        confPassInput.is(':focus') ){
        passInput.next('label').addClass('active');
        confPassInput.next('label').addClass('active');
    } else {
        passInput.next('label').removeClass('active');
        confPassInput.next('label').removeClass('active');
    }
}

const validateBasicForm = (setValidatedState : Function) => {

    let isFormValid = true;
    let doesContainEmptyFields = false;

    const fieldsArray = [ /*$('#username'),*/ $('#first-name'), $('#last-name'), $('#email'),
                            $('#password'), $('#confirm-password') ];

    fieldsArray.forEach( field => {
        if (!field.val()) {
            isFormValid = false;
            doesContainEmptyFields = true;
            if(!(field.attr('id') === 'confirm-password' || $('#password').val()))
                field.next('label').attr('data-error', '');
            field.addClass('invalid');
        } else {
            validateForm(field);
            if(field.hasClass('invalid'))
                isFormValid = false;
            else 
                field.addClass('valid');
        }
    })

    if (doesContainEmptyFields) {
        $('#error-msg').text('Please fill out all fields');
        $('#error-msg').addClass('alert alert-danger');
    } else if(isFormValid) {
        $('#error-msg').text('');
        $('#error-msg').removeClass('alert alert-danger');
    } else {
        $('#error-msg').text('Please correct the invalid information');
        $('#error-msg').addClass('alert alert-danger');
    }

    setValidatedState(isFormValid);
}

const PasswordRow = (props : any) => {

    useEffect(() => setLabelActivity(props.password, props.confirmPassword));

    return (
    <div className="row">
            <div className="col-md-6">
                <div className={'md-form'}>
                    <input type="password" id={'password'} className="form-control validate" defaultValue={props.password} 
                        onBlur={() => { validateUserInfo(props.dispatch, $('#password')); setLabelActivity(props.password, props.confirmPassword) }}
                        onLoad={() => setLabelActivity(props.password, props.confirmPassword)}/>
                    <label htmlFor={'password'}>Password</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className={'md-form'}>
                    <input type="password" id={'confirm-password'} className="form-control validate" defaultValue={props.confirmPassword} 
                        onKeyPress={() => { validateUserInfo(props.dispatch, $('#confirm-password')); setLabelActivity(props.password, props.confirmPassword) }}
                        onLoad={() => setLabelActivity(props.password, props.confirmPassword)}/>
                    <label htmlFor={'confirm-password'}>Confirm Password</label>
                </div>
            </div>
        </div>
    )
}

const FormRequirementsModal = () => (
    <div className="modal fade right" id="requirements-info-modal" tabIndex={-1} role="dialog" aria-labelledby="field-requirements-modal"
    aria-hidden="true">

        <div className="modal-dialog modal-full-height modal-fluid modal-right" role="document">


            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title w-100" id="field-requirements-modal">Field Requirements</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="list-group">
                        {/* <div className="list-group-item flex-column align-items-start">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-2 h5">Username</h5>
                            </div>
                            <p className="mb-2">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Must be 6-20 characters</li>
                                    <li className="list-group-item">Can only be composed of letters, numbers, underscores, or periods</li>
                                    <li className="list-group-item">May not begin or end with periods or underscores</li>
                                    <li className="list-group-item">May not use multiple periods or underscores in a row</li>
                                    <li className="list-group-item">
                                        <h6 className="mb-2 h6">Allowed Examples</h6>
                                        <ul className="list-group">
                                            <li className="list-group-item">sample.user, sample123_user</li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item">
                                        <h6 className="mb-2 h6">Not Allowed Examples</h6>
                                        <ul className="list-group">
                                            <li className="list-group-item">sample..user, _newuser</li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                        </div> */}
                        <div className="list-group-item flex-column align-items-start">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-2 h5">Email</h5>
                            </div>
                            <p className="mb-2">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Must be an active email</li>
                                    <li className="list-group-item">Must be a valid email format</li>
                                </ul>
                            </p>
                        </div>
                        <div className="list-group-item flex-column align-items-start">
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-2 h5">Password</h5>
                            </div>
                            <p className="mb-2">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Must be at least 6 characters</li>
                                    <li className="list-group-item">Must have at least one letter and one number</li>
                                    <li className="list-group-item">Recommended to use special characters</li>
                                    <li className="list-group-item">Passwords must match</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-two" data-dismiss="modal">Ok</button>
                </div>
            </div>
        </div>
    </div>
)

const BasicInfoRegistration = (props : any) => {

    const dispatch = useDispatch();    
    const [validatedState, setValidatedState] = useState(false);

    const { firstName, lastName, email, password, confirmPassword } = props.userInfo;

    if(validatedState)
        return <Redirect push to={'/register/personal'} />

    return (
        <div className="register form-group" >
            <FormRequirementsModal />
            <div className="row border-bottom" id="header">
                <div className="col">
                    <h2>Register - Basic Info</h2>
                </div>
                <div className="col-1">
                    <button type="button" id="requirements-info-btn" className="btn btn-one" data-toggle="modal" data-target="#requirements-info-modal">
                        ?
                    </button>
                </div>
            </div>
            <div className={'row'}>
                <div className="col" id="error-msg"></div>
            </div>
            <div className="row">
                <div className="col">
                    <div className={'md-form'}>
                        <input type="text" id={'email'} className="form-control validate" defaultValue={email} 
                            onBlur={() => validateUserInfo(dispatch, $('#email'))}/>
                        <label htmlFor={'email'} id={'registration-email-label'} className={email ? 'active' : ''} data-error={'jfdksl'}>Email</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className={'md-form'}>
                        <input type="text" id={'first-name'} className="form-control validate" defaultValue={firstName} 
                            onBlur={() => validateUserInfo(dispatch, $('#first-name'))} />
                        <label htmlFor={'first-name'} className={firstName ? 'active' : ''} >First Name</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={'md-form'}>
                        <input type="text" id={'last-name'} className="form-control validate" defaultValue={lastName} 
                            onBlur={() => validateUserInfo(dispatch, $('#last-name'))} />
                        <label htmlFor={'last-name'} className={lastName ? 'active' : ''} >Last Name</label>
                    </div>
                </div>
            </div>
            <PasswordRow password={password} confirmPassword={confirmPassword} dispatch={dispatch} />
            <div className="row">
                <div className="col-lg d-flex"></div>
                <div className={'col-lg-5 d-flex flex-row-reverse'}>
                    <button onClick={() => validateBasicForm(setValidatedState)} className="btn btn-outline-primary" id={'next-btn'}>
                        Next&nbsp;
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state : any) => {
    return {
        userInfo: state.userInfo
    }
}

export default connect(mapStateToProps)(BasicInfoRegistration)