import api from "./api";
import Cookies from "js-cookie";
import {setLinkRequests} from "../redux/actions/connectedLists";


// const updateProfileLinkStatus

const linkReview = (userId : any, dispatch : Function, action : boolean, setLinkStatus? : Function) => {
    const request = {
        user_id: userId,
        is_link_accepted: action
    }
    api.put('links/', request, {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token'),
        },
        withCredentials: true
    }).then(res => {
        if(res.status === 200) {
            dispatch(setLinkRequests());

            if(setLinkStatus)
                api.get('links/status/' + userId + '/', {
                    headers: {
                        Authorization: 'Token ' + Cookies.get('auth_token')
                    },
                    withCredentials: true
                }).then(res => {
                    setLinkStatus(res.data);
                })
        }
    }, err => {
        // TODO: add error
    })
}

export default linkReview;