import React, {useState} from "react";
import './Consultant.css';
import {connect, useDispatch} from "react-redux";
import ConsultantOnBoarding from "./ConsultantOnBoarding";
import {clearUserInfo, setUserSession} from "../../redux/actions/user";
import Cookies from 'js-cookie';
import {Route} from "react-router";
import Loading from "../Loading/Loading";
import {Link} from "react-router-dom";
import {setConnectedLists, setLinkRequests} from "../../redux/actions/connectedLists";
import linkReview from "../../api/linkReview";
import formatPhoneNumber from "../../api/formatPhoneNumber";
import defaultProfilePic from '../../media/images/default-profile-pic.png';
import api from "../../api/api";
import TaskListContainer from "../TaskList/TaskList";


const deleteLink = (userId: any, dispatch : Function) => {
    api.delete('links/' + userId + '/', {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        if (res.status === 200) {
            dispatch(setLinkRequests());
        }
    })
}

const IncomingRequestItem = (props: any) => {

    const dispatch = useDispatch();

    return (
        <div className={'row'}>
            <div className="col-10" id={'client-item-col'}>
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-5 text-center">
                                <Link
                                    to={'/client/profile/' + props.client.user_id}
                                    className={'btn-link card-title'}
                                >
                                    {props.client.first_name + ' ' + props.client.last_name}
                                </Link>
                                <p className="card-text">{formatPhoneNumber(props.client.phone_number)}</p>
                            </div>
                            <div className="col-md text-center">
                                <p className="card-text"></p>
                            </div>
                        {/*</div>*/}
                        {/*<div className="row" id={'request-action-btns'}>*/}
                            <div className="col-2-lg text-center p-0">
                                <button
                                    className={'btn request-action-btn'}
                                    id={'approve-btn'}
                                    data-toggle={'tooltip'}
                                    title={'Approve'}
                                    onClick={() => linkReview(props.client.user_id, dispatch, true)}
                                >
                                    <i className="fa fa-check-circle-o fa-3x" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="col-2-lg text-center p-0">
                                <button
                                    className={'btn request-action-btn'}
                                    id={'reject-btn'}
                                    data-toggle={'tooltip'}
                                    title={'Reject'}
                                    onClick={() => linkReview(props.client.user_id, dispatch, false)}
                                >
                                    <i className="fa fa-ban fa-3x" aria-hidden="true"></i>
                                </ button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const OutgoingRequestItem = (props: any) => {

    const dispatch = useDispatch();

    return (
        <div className={'row'}>
            <div className="col-10" id={'client-item-col'}>
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 text-center">
                                <Link
                                    to={'/client/profile/' + props.client.user_id}
                                    className={'btn-link card-title'}
                                >
                                    {props.client.first_name + ' ' + props.client.last_name}
                                </Link>
                                <p className="card-text">{formatPhoneNumber(props.client.phone_number)}</p>
                            </div>
                            <div className="col-md text-center">
                                <p className="card-text"></p>
                            </div>
                            <div className="col-md text-center">
                                <button
                                    className="btn btn-link btn-md"
                                    onClick={() => deleteLink(props.client.user_id, dispatch)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        {/*<div className="row" id={'request-action-btns'}>*/}
                        {/*    <div className="col-md text-center p-0">*/}
                        {/*        <button*/}
                        {/*            className={'btn btn-success'}*/}
                        {/*            id={'approve-request-button'}*/}
                        {/*            onClick={()=>linkReview(props.client.user_id, dispatch, true)}*/}
                        {/*        >*/}
                        {/*            Approve*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-md text-center p-0">*/}
                        {/*        <button*/}
                        {/*            className={'btn btn-danger'}*/}
                        {/*            id={'reject-request-button'}*/}
                        {/*            onClick={()=>linkReview(props.client.user_id, dispatch, false)}*/}
                        {/*        >*/}
                        {/*            Reject*/}
                        {/*        </ button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClientRequests = (props : any) => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const {incomingRequestsList, outgoingRequestsList} = props.connectedList;

    if(isLoading) {
        dispatch(setLinkRequests(setIsLoading));
        return <Loading />
    }


    return(
        <div className={'row'}>
            <div className="col">
                <div className="row clients-headers">
                    <div className={'md-form'}>
                        <div className={'row'}>
                            <h3>Incoming</h3>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 clients-list">
                    <div className="col">
                        {
                            incomingRequestsList.length > 0 ?
                                incomingRequestsList.map((client: any) => (
                                    <IncomingRequestItem client={client}/>
                                ))
                                :
                                <div className={'text-center'}>No incoming requests</div>
                        }
                    </div>
                </div>
            </div>
            <div className="col border-left">
                <div className="row clients-headers">
                    <div className={'md-form'}>
                        <div className={'row'}>
                            <h3>Outgoing</h3>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 clients-list">
                    <div className="col">
                        {
                            outgoingRequestsList.length > 0 ?
                                outgoingRequestsList.map((client: any) => (
                                    <OutgoingRequestItem client={client} />
                                ))
                                :
                                <div className={'text-center'}>No outgoing requests</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClientCard = (props : any) => {

    const {name, user_id, profile, email, phone_number} = props.client

    return (
        <div className="card client-card">
            <img className="card-img-top client-profile-picture" src={profile ? profile : defaultProfilePic} alt="Client profile" />
            <div className="card-body text-center">
                <h5 className="card-title">{name}</h5>
                <p className="card-text">{email}</p>
                <p className="card-text">{phone_number}</p>
                <Link to={'/consultant/client/' + user_id} className="btn btn-one w-100">View</Link>
            </div>
        </div>
    )
}

const ConsultantMain = (props : any) => {

    const dispatch = useDispatch();
    const [connectedQuery, setConnectedQuery] = useState('')
    const {connectedClientsList} = props.connectedList;

    if(props.connectedList.isConnectedListLoading){
        dispatch(setConnectedLists());

        return <Loading />;
    }

    return(
        <div>
            <div className={'row'}>
                <div className="col">
                    {/* TODO: add functionality and add back */}
                    {/*<div className={'md-form'} id={'search-connected-form'}>  /!*float-right*!/*/}
                    {/*    <div className={'row'}>*/}
                    {/*        <input*/}
                    {/*            type={'text'}*/}
                    {/*            className={'form-control'}*/}
                    {/*            name={'search-connected-input'}*/}
                    {/*            defaultValue={connectedQuery}*/}
                    {/*            onChange={(e) => setConnectedQuery(e.target.value)}*/}
                    {/*        />*/}
                    {/*        <label htmlFor={'search-connected-input'} className={connectedQuery ? 'active': ''}>Search</label>*/}
                    {/*        <button*/}
                    {/*            className={'btn'}*/}
                    {/*            id={'clear-search-btn'}*/}
                    {/*            // style={{visibility: cancelBtnVisiblility}}*/}
                    {/*            // onClick={() => {clearSearch(dispatch, setIsSearchActive, setSearchQuery);}}*/}
                    {/*        >*/}
                    {/*            <i className="fas fa-times"></i>*/}
                    {/*        </button>*/}
                    {/*        <button*/}
                    {/*            className={'btn'}*/}
                    {/*            id={'client-search-btn'}*/}
                    {/*            // onClick={() => searchClients(dispatch, searchQuery, setIsSearchActive)}*/}
                    {/*        >*/}
                    {/*            <i className="fas fa-search"></i>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="row justify-content-center" id={'client-cards-row'}>
                {
                    connectedClientsList && connectedClientsList.length > 0 ?
                        connectedClientsList.map((client : any, index : number) => {
                            return <ClientCard client={client}/>
                        })
                    : (<p className={'text-center pt-4'}>You aren't linked with any clients yet</p>)
                }
            </div>
        </div>
    )
}

const ConsultantHome = (props : any) => {

    const dispatch = useDispatch();
    const pathname = window.location.pathname;
    let activePathName = 'home'

    if(pathname === '/consultant/home/requests')
        activePathName = 'requests'
    else if(pathname === '/consultant/home/task-list')
        activePathName = 'task-list'

    const [activeTab, setActiveTab] = useState(activePathName)

    if(props.userInfo.isUserLoading){
        dispatch(setUserSession());

        return <Loading />;
    }

    if(Cookies.get('user_token') && Cookies.get('auth_token'))
        return (
            <div className={'consultant-container'}>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link to={'/consultant/home'}
                              className={'nav-link' + (activeTab === 'home' ? ' active' : '')}
                              onClick={() => setActiveTab('home')}
                        >Clients</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/consultant/home/requests'}
                              className={'nav-link' + (activeTab === 'requests' ? ' active' : '')}
                              onClick={() => setActiveTab('requests')}
                        >Requests</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/consultant/home/task-list'}
                              className={'nav-link' + (activeTab === 'task-list' ? ' active' : '')}
                              onClick={() => setActiveTab('task-list')}
                        >Task List</Link>
                    </li>
                </ul>
                {props.userInfo.isOnboardingActive ? <ConsultantOnBoarding createNotifications={props.createNotifications}/> : null}
                <div className={'consultant-tab-container border-right border-bottom border-left box-shadow'}>
                    <Route exact path={'/consultant/home'} render={() => <ConsultantMain connectedList={props.connectedList} />} />
                    <Route path={'/consultant/home/requests'} render={() => <ClientRequests connectedList={props.connectedList} />} />
                    <Route path={'/consultant/home/task-list'} component={TaskListContainer} />
                </div>
            </div>
        )
    else {
        Cookies.remove('user_token');
        Cookies.remove('auth_token');
        dispatch(clearUserInfo());

        window.location.href = '/';
        return null;
    }
}

const mapStateToProps = (state : any) => {
    return {
        userInfo: state.userInfo,
        connectedList: state.connectedList
    }
}

export default connect(mapStateToProps)(ConsultantHome);