import React, {useState} from "react";
import './ForgotPassword.css';
import {Redirect, useParams} from "react-router";
import api from "../../api/api";
import Loading from "../Loading/Loading";

const ResetPassword = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const params : any = useParams();

    if(isLoading) {
        api.get('reset-password/' + params.forgotPassToken)
            .then(res => {
                if(res.status === 200){
                    setIsValid(true);
                    setIsLoading(false);
                }
            })
            .catch(() => setIsLoading(false))

        return (
            <div className={'forgot-password-content'}>
                {/*TODO:NATHAN*/}
                <Loading />
            </div>
        )
    }

    if(!isValid) return <Redirect to={'/'} />

    if(isSubmitted) return <Redirect to={'/login/client'} />

    const submitPasswordChange = () => {
        api.put('reset-password/' + params.forgotPassToken, {password, confirm_password: confirmPassword})
            .then(res => {
                if(res.status === 200)
                    setIsSubmitted(true);
            })
            .catch(err => {}) //TODO: show notification errors like settings and calendar
    }

    return(
        <div className={'forgot-password-content'}>
            <h4>Reset Password</h4>
            <div className={'reset-password-section'}>
                <div className={'row'}>
                    <div className="col">
                        <div className="md-form">
                            <input type="password" id={'password-input'} className={'form-control'} onChange={e=>setPassword(e.target.value)}/>
                            <label htmlFor={'password-input'}>Password</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="md-form">
                            <input type="password" id={'confirm-password-input'} className={'form-control'} onChange={e=>setConfirmPassword(e.target.value)}/>
                            <label htmlFor={'confirm-password-input'}>Confirm Password</label>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col text-center">
                        <button className="btn btn-one" onClick={submitPasswordChange}>Change Password</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;