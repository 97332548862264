import {
    CLEAR_USER_INFO,
    CONFIRM_PASSWORD,
    EMAIL,
    FIRST_NAME, IS_ON_BOARDING, IS_PROFILE_LOADING, IS_USER_LOADING,
    LAST_NAME,
    PASSWORD, PROFILE_PICTURE,
    USER_TYPE
} from "../actions/actionTypes";

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    userType: '',
    isUserLoading: true,
    isOnboardingActive: false,
    profilePicture: '',
    isProfilePictureLoading: true
};

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USER_TYPE:
            return {
                ...state,
                userType: action.payload
            }
        case FIRST_NAME:
            return {
                ...state,
                firstName: action.payload
            };
        case LAST_NAME:
            return {
                ...state,
                lastName: action.payload
            };
        case EMAIL:
            return {
                ...state,
                email: action.payload
            };
        case PASSWORD:
            return {
                ...state,
                password: action.payload
            };
        case CONFIRM_PASSWORD:
            return {
                ...state,
                confirmPassword: action.payload
            };
        case IS_USER_LOADING:
            return {
                ...state,
                isUserLoading: action.payload
            };
        case IS_ON_BOARDING:
            return {
                ...state,
                isOnboardingActive: action.payload
            }
        case PROFILE_PICTURE:
            return {
                ...state,
                profilePicture: action.payload
            }
        case IS_PROFILE_LOADING:
            return {
                ...state,
                isProfilePictureLoading: action.payload
            }
        case CLEAR_USER_INFO:
            return {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                userType: '',
                isUserLoading: false,
                isOnboardingActive: false,
                profilePicture: '',
                isProfilePictureLoading: false
            };
        default:
            return state
    }
};

export default userReducer;