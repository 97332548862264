import {
    ADDRESS_TYPE,
    ADDRESS_TYPE_DETAILS,
    CITY,
    CLEAR_CLIENT_REGISTRATION_INFO,
    LAST_FOUR_SSN,
    OWNS_CAR,
    SALARY,
    STATE_CODE,
    STREET_ADDRESS,
    ZIP_CODE
} from './actionTypes';

export const setSalary = (payload : any) => ({
    type: SALARY,
    payload
});

export const setAddressType = (payload : any) => ({
    type: ADDRESS_TYPE,
    payload
});

export const setAddressTypeDetails = (payload : any) => ({
    type: ADDRESS_TYPE_DETAILS,
    payload
});

export const setOwnsCar = (payload : any) => ({
    type: OWNS_CAR,
    payload
});

export const setLastFourSsn = (payload : any) => ({
    type: LAST_FOUR_SSN,
    payload
});

export const setStreetAddress = (payload : any) => ({
    type: STREET_ADDRESS,
    payload
});

export const setCity = (payload : any) => ({
    type: CITY,
    payload
});

export const setStateCode = (payload : any) => ({
    type: STATE_CODE,
    payload
});

export const setZipCode = (payload : any) => ({
    type: ZIP_CODE,
    payload
});

export const clearClientRegistrationInfo = () => ({
    type: CLEAR_CLIENT_REGISTRATION_INFO
})