import React, {ChangeEvent, useState} from 'react';
import {Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {Link, Route} from "react-router-dom";
import './AccountSettings.css';
import {batch, connect, useDispatch} from "react-redux";
import BankSettings from './BankSettings';
import defaultProfilePic from "../../../media/images/default-profile-pic.png";
import formatPhoneNumber from "../../../api/formatPhoneNumber";
import {setAccountInfo} from "../../../redux/actions/client";
import Cookies from 'js-cookie';
import api from "../../../api/api";
import GeneralSettings from "./GeneralSettings";
import NotificationList from "./NotificationsList";
import Loading from "../../Loading/Loading";
import {clearUserInfo} from "../../../redux/actions/user";

declare let $: any;


const SettingsNavBar = () => (
    <Navbar id={'account-settings-nav'}
            className="navbar-expand-sm navbar-toggleable-sm border-bottom border-right border-left box-shadow mb-3"
            light>
        <Container>
            <NavbarToggler className="mr-2"/>
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse mt-2 justify-content-center" navbar>
                <ul className="navbar-nav flex-grow">
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/client/settings/general/change-password">General</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/client/settings/account">Account</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/client/settings/bank-account">Bank</NavLink>
                    </NavItem>
                </ul>
            </Collapse>
        </Container>
    </Navbar>
);

const deformatPhoneNumber = (phoneNumber: any) => {
    let number = null;
    if (phoneNumber)
        number = phoneNumber.replace(/\)|\(|-|\s/gi, '');
    return number;
}

const BioComponent = (props: any) => {

    if (props.isEditable)
        return (
            <>
                <div className="row">
                    <div className="col content-header">
                        Bio
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <textarea className={'form-control'} rows={8} id={'change-bio'}>{props.bio}</textarea>
                    </div>
                </div>
            </>
        )
    else
        return (
            <>
                <div className="row">
                    <div className="col content-header">
                        Bio
                    </div>
                </div>
                <div className="row">
                    <div className="col">{props.bio}</div>
                </div>
            </>
        )
}

const ContactInfo = (props: any) => {

    if (props.isEditable)
        return (
            <div id={'contact-info-section'} className={''}>
                <div className="row">
                    <div className="col content-header">
                        Contact Information
                    </div>
                </div>
                <div className="row md-form">
                    <div className="col">
                        Email
                    </div>
                    <div className="col">
                        <input type={'text'} id={'change-email'} className={'form-control'} defaultValue={props.email}/>
                    </div>
                </div>
                <div className="row md-form">
                    <div className="col">
                        Phone
                    </div>
                    <div className="col">
                        {/* TODO: check if phone number is 10 digits on submit */}
                        <input type={'text'} id={'change-phone'} maxLength={10} className={'form-control'}
                               defaultValue={deformatPhoneNumber(props.phoneNumber)}/>
                    </div>
                </div>
            </div>
        )
    else
        return (
            <div id={'contact-info-section'}>
                <div className="row">
                    <div className="col content-header">
                        Contact Information
                    </div>
                </div>
                <div className="row md-form">
                    <div className="col">
                        Email
                    </div>
                    <div className="col">
                        {props.email}
                    </div>
                </div>
                <div className="row md-form">
                    <div className="col">
                        Phone
                    </div>
                    <div className="col">
                        {props.phoneNumber}
                    </div>
                </div>
            </div>
        )
}

const emailChecker = (emailField: any) => {
    const pattern = /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    const email = emailField.val();
    const isValid = pattern.test(email)

    if (!isValid) {
        return ['Invalid email format', false]
    } else if (!email) {
        return ['Valid email must be provided', false]
    }

    return['',true]
}

const ButtonGroup = (props: any) => {

    if (!props.isEditable)
        return (
            <div className="col text-right">
                <button className="btn settings-btn" id={'edit-btn'} onClick={props.handleOnEdit}
                        data-toggle={'tooltip'} title={'Edit'}>
                    <i className="fa fa-pencil fa-lg" aria-hidden="true"></i>
                </button>
            </div>
        )
    else
        return (
            <div className="col text-right">
                <button className="btn settings-btn" id={'save-btn'} onClick={props.handleOnSave}
                        data-toggle={'tooltip'} title={'Save'}>
                    <i className="fa fa-floppy-o fa-lg" aria-hidden="true"></i>
                </button>
                <button className="btn settings-btn" id={'discard-btn'} onClick={props.handleOnDiscard}
                        data-toggle={'tooltip'} title={'Discard'}>
                    <i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                </button>
            </div>
        )
}

const ProfilePicture = (props: any) => {

    const {profilePicUrl, setProfilePicUrl, setProfilePic} = props;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0])
            batch(() => {
                // @ts-ignore
                setProfilePicUrl(URL.createObjectURL(e.target.files[0]));
                // @ts-ignore
                setProfilePic(e.target.files[0])
            })
    }

    if (!props.isEditable)
        return (
            <img
                src={profilePicUrl}
                alt="Profile pic"
                className={'img-thumbnail'}
                id={'profile-pic'}
            />
        )
    else
        return (
            <div>
                <img
                    src={profilePicUrl}
                    alt="Profile pic"
                    className={'img-thumbnail'}
                    id={'profile-pic'}
                />
                <div className="custom-file">
                    <input
                        type="file"
                        id="profile-pic-input"
                        name={'profile-pic-input'}
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleChange}
                    />
                    <label htmlFor="profile-pic-input">Update profile</label>
                </div>
            </div>
        )
}

const ClientAccountSettings = (props: any) => {

    const {firstName, lastName, email, phoneNumber, bio, profile} = props.clientInfo
    const [isEditable, setIsEditable] = useState(false);
    const setNotificationsList = props.setNotificationsList;
    const [profilePicUrl, setProfilePicUrl] = useState(profile ? profile : defaultProfilePic);
    const [profilePic, setProfilePic] = useState(profilePicUrl)

    let timeout: any = null;

    const handleOnEdit = () => {
        if (!isEditable)
            setIsEditable(!isEditable);
    }

    const handleOnSave = async () => {

        let notifications: any = [];

        const emailValidation : any = emailChecker($('#change-email'))

        if ($('#change-email').val().trim() !== email && emailValidation && !emailValidation[1]) {
            $('#change-email').addClass('invalid');

            notifications.push({
                title: 'Invalid Email',
                body: emailValidation[0]
            })
        } else {
            $('#change-email').removeClass('invalid');
        }

        if ($('#change-phone').val().trim().length !== 10 && $('#change-phone').val().trim()) {
            $('#change-phone').addClass('invalid');
            notifications.push({
                title: 'Invalid Phone Number',
                body: 'Phone number should only contain ten numbers'
            })
        } else {
            $('#change-phone').removeClass('invalid');
        }

        if (notifications.length > 0) {
            setNotificationsList(notifications)

            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }

            timeout = setTimeout(() => {
                setNotificationsList([]);
            }, 7000)
        } else {

            const phoneNumber = formatPhoneNumber($('#change-phone').val().trim());

            const formData = new FormData();
            formData.append('email', $('#change-email').val().trim());
            formData.append('phone_number', phoneNumber ? phoneNumber : '');
            formData.append('biography', $('#change-bio').val().trim());

            if(profilePic !== profile && profilePicUrl !== defaultProfilePic)
                formData.append('profile', profilePic);

            api.put('clients/', formData, {
                headers: {
                    Authorization: 'Token ' + Cookies.get('auth_token'),
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            }).then(res => {
                notifications.push({
                    title: 'Success',
                    body: 'Profile updated successfully'
                })

                batch(() => {
                    setNotificationsList(notifications)
                    props.setIsLoading(true);
                })

                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }

                timeout = setTimeout(() => {
                    setNotificationsList([]);
                }, 7000)
            }).catch(err => {
                // TODO: update to handle errors other than existing email

                $('#change-email').addClass('invalid');

                notifications.push({
                    title: 'Invalid Email',
                    body: 'Email matches another email on an existing account'
                })

                setNotificationsList(notifications)

                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }

                timeout = setTimeout(() => {
                    setNotificationsList([]);
                }, 7000)
            })
        }
    }

    const handleOnDiscard = () => {
        if (isEditable) {
            setProfilePicUrl(profile ? profile : defaultProfilePic)
            setIsEditable(!isEditable);
        }
    }

    return (
        <div className={'settings-content border'}>
            <div className="row">
                <ButtonGroup
                    handleOnEdit={handleOnEdit}
                    handleOnSave={handleOnSave}
                    handleOnDiscard={handleOnDiscard}
                    isEditable={isEditable}
                />
            </div>
            <div className={'row'}>
                <div className="col-md-4 text-center">
                    <div className="row">
                        <div className="col">
                            <span id={'profile-name'}>{firstName + " " + lastName}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ProfilePicture profilePicUrl={profilePicUrl} setProfilePicUrl={setProfilePicUrl}
                                            isEditable={isEditable} setProfilePic={setProfilePic}/>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <ContactInfo isEditable={isEditable} email={email} phoneNumber={phoneNumber}/>
                    <BioComponent isEditable={isEditable} bio={bio}/>
                </div>
            </div>
        </div>
    )
}

const ClientSettings = (props: any) => {
    const [isLoading, setIsLoading] = useState(true);
    const [notificationsList, setNotificationsList] = useState([]);
    const dispatch = useDispatch();

    if (isLoading) {
        dispatch(setAccountInfo(setIsLoading));

        return (
            <div>
                <SettingsNavBar/>
                <Loading />
            </div>
        )
    }

    if(Cookies.get('user_token') && Cookies.get('auth_token'))
        return (
            <div>
                <SettingsNavBar/>
                <NotificationList notificationsList={notificationsList} setNotificationsList={setNotificationsList}/>
                <Route path={'/client/settings/bank-account'} component={BankSettings}/>
                <Route path={'/client/settings/general'} render={() => <GeneralSettings notificationsList={notificationsList} setNotificationsList={setNotificationsList} /> }/>
                <Route path={'/client/settings/account'}
                       render={() => <ClientAccountSettings clientInfo={props.clientInfo} setIsLoading={setIsLoading} setNotificationsList={setNotificationsList}/>}/>
            </div>
        )
    else {
        Cookies.remove('user_token');
        Cookies.remove('auth_token');
        dispatch(clearUserInfo());

        window.location.href = '/';
        return null;
    }
};

const mapStateToProps = (state: any) => {
    return {
        clientInfo: state.clientInfo
    }
}

export default connect(mapStateToProps)(ClientSettings);