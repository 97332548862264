import React, {useState} from "react";
import {batch, connect, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
import api from "../../api/api";
import defaultProfilePic from "../../media/images/default-profile-pic.png";
import {setUserSession} from "../../redux/actions/user";
import Loading from "../Loading/Loading";
import {Route} from "react-router";
import {setLinkRequests} from "../../redux/actions/connectedLists";
import linkReview from "../../api/linkReview";

const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
};

const RenderTableData = (props: any) => {
    return props.clientList.map((client: any) => {
        return (
            <tr key={client.userId}>
                <td>
                    <Link
                        to={'/client/profile/' + client.userId}
                        className={'btn-link card-title'}
                    >
                        {client.name}
                    </Link>
                </td>
                <td>{client.email}</td>
                <td>{client.phoneNumber}</td>
            </tr>
        );
    });
};

const clearSearch = (setSearchIsActive: Function, setSearchQuery: Function, setClientList: Function) => {
    batch(() => {
        setSearchQuery('');
        setSearchIsActive(false);

        api.get("links/", {
            headers: {
                Authorization: "Token " + Cookies.get("auth_token"),
            },
            withCredentials: true,
        })
            .then((res) => {
                batch(() => {
                    // TODO: set the list using setClientList(res.data)
                    setClientList(
                        res.data.map((clientItem: any) => ({
                            firstName: clientItem.first_name,
                            lastName: clientItem.last_name,
                            email: clientItem.email,
                            phoneNumber: clientItem.phone_number,
                            userId: clientItem.user_id,
                        }))
                    );
                });
            })
            .catch((err) => {
                // setHasFailed(true);
            })
    })
}

const AgentMain = (props : any) => {

    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchActive, setIsSearchActive] = useState(false);

    let cancelBtnVisiblility: any = isSearchActive ? 'visible' : 'hidden';

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            setIsSearchActive(true);

            api.get('links/search/' + searchQuery, {
                headers: {
                    Authorization: "Token " + Cookies.get("auth_token"),
                },
                withCredentials: true,
            }).then((res) => {
                batch(() => {
                    props.setClientList(
                        res.data.map((clientItem: any) => ({
                            name: clientItem.name,
                            email: clientItem.email,
                            phoneNumber: clientItem.phone_number,
                            userId: clientItem.user_id,
                        }))
                    );
                });
            })
            .catch((err) => {
            //    TODO: handle error
            })
        }

    };

    return(
        <div>
            <div className="row">
                <div className="col">
                    <div className="md-form">
                        <input
                            type="text"
                            id="linked-client-search"
                            className={'form-control'}
                            defaultValue={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                        <label htmlFor={'linked-client-search'} className={searchQuery ? 'active' : ''}>Search for
                            client
                            names...</label>
                        <button
                            className={'btn'}
                            id={'clear-search-btn'}
                            style={{visibility: cancelBtnVisiblility}}
                            onClick={() => {
                                clearSearch(setIsSearchActive, setSearchQuery, props.setClientList);
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col">
                    <table className={'table'} id="students" style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                        </tr>
                        </thead>
                        <tbody>
                            <RenderTableData clientList={props.clientList}/>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const IncomingRequestItem = (props: any) => {

    const dispatch = useDispatch();

    return (
        <div className={'row'}>
            <div className="col-10" id={'client-item-col'}>
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-5 text-center">
                                <Link
                                    to={'/client/profile/' + props.client.user_id}
                                    className={'btn-link card-title'}
                                >
                                    {props.client.first_name + ' ' + props.client.last_name}
                                </Link>
                                <p className="card-text">{formatPhoneNumber(props.client.phone_number)}</p>
                            </div>
                            <div className="col-md text-center">
                                <p className="card-text"></p>
                            </div>
                            {/*</div>*/}
                            {/*<div className="row" id={'request-action-btns'}>*/}
                            <div className="col-2-lg text-center p-0">
                                <button
                                    className={'btn request-action-btn'}
                                    id={'approve-btn'}
                                    data-toggle={'tooltip'}
                                    title={'Approve'}
                                    onClick={() => linkReview(props.client.user_id, dispatch, true)}
                                >
                                    <i className="fa fa-check-circle-o fa-3x" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="col-2-lg text-center p-0">
                                <button
                                    className={'btn request-action-btn'}
                                    id={'reject-btn'}
                                    data-toggle={'tooltip'}
                                    title={'Reject'}
                                    onClick={() => linkReview(props.client.user_id, dispatch, false)}
                                >
                                    <i className="fa fa-ban fa-3x" aria-hidden="true"></i>
                                </ button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const deleteLink = (userId: any, dispatch : Function) => {
    api.delete('links/' + userId + '/', {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        if (res.status === 200) {
            dispatch(setLinkRequests());
        }
    })
}

const OutgoingRequestItem = (props: any) => {

    const dispatch = useDispatch();

    return (
        <div className={'row'}>
            <div className="col-10" id={'client-item-col'}>
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 text-center">
                                <Link
                                    to={'/client/profile/' + props.client.user_id}
                                    className={'btn-link card-title'}
                                >
                                    {props.client.first_name + ' ' + props.client.last_name}
                                </Link>
                                <p className="card-text">{formatPhoneNumber(props.client.phone_number)}</p>
                            </div>
                            <div className="col-md text-center">
                                <p className="card-text"></p>
                            </div>
                            <div className="col-md text-center">
                                <button
                                    className="btn btn-link btn-md"
                                    onClick={() => deleteLink(props.client.user_id, dispatch)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClientRequests = (props : any) => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const {incomingRequestsList, outgoingRequestsList} = props.connectedList;

    if(isLoading) {
        dispatch(setLinkRequests(setIsLoading));
        return <Loading />
    }


    return(
        <div className={'row'}>
            <div className="col">
                <div className="row clients-headers">
                    <div className={'md-form'}>
                        <div className={'row'}>
                            <h3>Incoming</h3>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 clients-list">
                    <div className="col">
                        {
                            incomingRequestsList.length > 0 ?
                                incomingRequestsList.map((client: any) => (
                                    <IncomingRequestItem client={client}/>
                                ))
                                :
                                <div className={'text-center'}>No incoming requests</div>
                        }
                    </div>
                </div>
            </div>
            <div className="col border-left">
                <div className="row clients-headers">
                    <div className={'md-form'}>
                        <div className={'row'}>
                            <h3>Outgoing</h3>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 clients-list">
                    <div className="col">
                        {
                            outgoingRequestsList.length > 0 ?
                                outgoingRequestsList.map((client: any) => (
                                    <OutgoingRequestItem client={client} />
                                ))
                                :
                                <div className={'text-center'}>No outgoing requests</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const AgentHome = (props: any) => {
    const dispatch = useDispatch();
    const pathname = props.location.pathname;

    const [isAgentProfileLoading, setIsAgentProfileLoading] = useState(true);
    const [isAgentConnectionsLoading, setIsAgentConnectionsLoading] = useState(true);
    const [clientList, setClientList] = useState([]);
    const [name, setName] = useState("");
    const [fName, setFName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [bio, setBio] = useState();
    const [professions, setProfessions] = useState([]);
    const [profilePic, setProfilePic] = useState(defaultProfilePic);
    const [company, setCompany] = useState("");
    const [isAgentMainActive, setIsAgentMainActive] = useState(pathname === '/agent/home' ? 'active' : '');
    const [isRequestsActive, setIsRequestsActive] = useState(pathname === '/agent/home/requests' ? 'active' : '');

    let isLoading = false;

    if (props.userInfo.isUserLoading) {
        isLoading = true;
        dispatch(setUserSession());
    }

    // TODO: if statement if isLoading
    if (isAgentProfileLoading) {
        isLoading = true;

        api.get("agents/" + Cookies.get('user_token') + "/", {
            headers: {
                Authorization: "Token " + Cookies.get("auth_token"),
            },
            withCredentials: true,
        }).then((res) => {
            batch(() => {
                setName(res.data.first_name + " " + res.data.last_name);
                setFName(res.data.first_name)
                setEmail(res.data.email);
                setPhoneNumber(formatPhoneNumber(res.data.phone_number));
                setBio(res.data.biography);
                setProfessions(res.data.professions);
                setProfilePic(
                    res.data.profile ? res.data.profile : defaultProfilePic
                );
                setCompany(res.data.company);
                setIsAgentProfileLoading(false)
            });
        }).catch((err) => {
            setIsAgentProfileLoading(false);
        })
    }

    if(isAgentConnectionsLoading){

        isLoading = true;

        api.get("links/", {
            headers: {
                Authorization: "Token " + Cookies.get("auth_token"),
            },
            withCredentials: true,
        }).then((res) => {
            batch(() => {
                setClientList(
                    res.data.map((clientItem: any) => ({
                        name: clientItem.name,
                        email: clientItem.email,
                        phoneNumber: clientItem.phone_number,
                        userId: clientItem.user_id,
                    }))
                );
                setIsAgentConnectionsLoading(false);
            });
        }).catch((err) => {
            // setHasFailed(true);
            setIsAgentConnectionsLoading(false);
        })
    }

    if(isLoading)
        return <Loading />

    return (
        <div id="agent-profile-container">
            <div className="row">
                <div className="col-md-3">
                    <div className="row">
                        <div className="col text-center">
                            <img
                                src={profilePic}
                                alt="Profile pic"
                                className={"img-thumbnail"}
                                id={"profile-pic"}
                            />
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col text-center">
                            <h3>{name}</h3>
                        </div>
                    </div>
                    <div className="row border-top profile-content-section">
                        <div className="col">
                            <table id={"agent-info-table"}>
                                <tbody id={"agent-info"}>
                                <tr>
                                    <td>
                                        <i className="far fa-envelope fa mr-4"></i>
                                    </td>
                                    <td>{email}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <i className="far fa-phone fa mr-4"></i>
                                    </td>
                                    <td>{phoneNumber}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <i className="far fa-building fa mr-4"></i>
                                    </td>
                                    <td>{company}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <i className="far fa-briefcase fa mr-4"></i>
                                    </td>
                                    <td>
                                        {professions.map((profession) => (
                                            <li>{profession}</li>
                                        ))}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row border-top profile-content-section"></div>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className={'consultant-container'}>
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link to={'/agent/home'}
                                              className={"nav-link " + isAgentMainActive}
                                              onClick={() => {setIsAgentMainActive('active'); setIsRequestsActive('')}}>Clients</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/agent/home/requests'}
                                              className={"nav-link " + isRequestsActive}
                                              onClick={() => {setIsAgentMainActive(''); setIsRequestsActive('active')}}>Requests</Link>
                                    </li>
                                </ul>
                                <div className={'consultant-tab-container border-right border-bottom border-left box-shadow'}>
                                    <Route exact path={'/agent/home'} component={() => <AgentMain connectedList={props.connectedList} setClientList={setClientList} clientList={clientList} />} />
                                    <Route path={'/agent/home/requests'} render={() => <ClientRequests connectedList={props.connectedList} />} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userInfo: state.userInfo,
        connectedList: state.connectedList
    };
}

export default connect(mapStateToProps)(AgentHome);
