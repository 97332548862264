import {batch, connect, useDispatch} from "react-redux";
import {Redirect, useParams} from "react-router";
import React, {useState} from "react";
import Cookies from 'js-cookie';
import defaultProfilePic from '../../../media/images/default-profile-pic.png';
import api from "../../../api/api";
import linkReview from "../../../api/linkReview";
import {setUserSession} from "../../../redux/actions/user";
import Loading from "../../Loading/Loading";
import './ClientProfile.css';

declare var $: any;


const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

const sendRequest = (userId: any, setLinkStatus: Function) => {
    api.post('links/', {client: userId}, {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token'),
        },
        withCredentials: true
    }).then(res => {
        setLinkStatus(res.data);
    })
}

const deleteLink = (userId : any, setLinkStatus : Function) => {
    api.delete('links/' + userId + '/', {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        $('#confirmation-modal').modal('hide');
        setLinkStatus(res.data)
    })
}

const ConfirmationModal = (props : any) => {
    return (
        <div className="modal fade" id="confirmation-modal" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Confirm Unlink</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p className={'text-center'}>Are you sure you want to unlink with {props.name}?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={()=>deleteLink(props.userId, props.setLinkStatus)}>Unlink</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LinkRequestButton = (props: any) => {

    if (props.linkStatus.pending_client)
        return (
            <span className={'btn btn-info w-100'} id={'pending-request-btn'}>
                Request Pending...
                <button className={'btn link-btn-icon'} data-toggle={'tooltip'} title={'Cancel Request'}
                        onClick={() => linkReview(props.profileUserId, props.dispatch, false, props.setLinkStatus)}><i
                    className="fas fa-times fa-lg"></i></button>
            </span>
        )

    else if (props.linkStatus.pending_collaborator)
        return (
            <div className={'w-100'}>
                <button className={'btn btn-secondary w-100'} role="button" id="accept-link-dd" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    Accept Link Request?
                    <span className={'link-btn-icon'}><i className="fas fa-angle-down fa-lg"></i></span>
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="accept-link-dd">
                    <button className="dropdown-item"
                            onClick={() => linkReview(props.profileUserId, props.dispatch, true, props.setLinkStatus)}>Accept
                    </button>
                    <div className="dropdown-divider"></div>
                    <button className="dropdown-item"
                            onClick={() => linkReview(props.profileUserId, props.dispatch, false, props.setLinkStatus)}>Reject
                    </button>
                </div>
            </div>
        )

    else if (props.linkStatus.is_active)
        return (
            <div className="w-100">
                <button className={'btn btn-success w-100'} id={'connected-btn-dd'} data-toggle={'dropdown'}>
                    Connected
                    <span className={'link-btn-icon'}><i className="fas fa-angle-down fa-lg"></i></span>
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="connected-btn-dd">
                    <button className="dropdown-item" onClick={()=>$('#confirmation-modal').modal('show')}>Unlink</button>
                </div>
            </div>
        )

    else
        return <button className={'btn btn-primary w-100'}
                       onClick={() => sendRequest(props.profileUserId, props.setLinkStatus)}>Send Link Request</button>
}

const ClientProfile = (props: any) => {

    const dispatch = useDispatch();
    const params: any = useParams();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [bio, setBio] = useState();
    const [isProfileLoading, setIsProfileLoading] = useState(true)
    const [isSuccess, setIsSuccess] = useState(false);
    const [linkStatus, setLinkStatus] = useState({});
    const [isLinkStatusLoading, setIsLinkStatusLoading] = useState(true);
    const [hasFailed, setHasFailed] = useState(false);
    const [profilePicture, setProfilePicture] = useState('');

    const mailTo = 'mailto:' + email;
    let isLoading = false;

    if (isProfileLoading) {
        isLoading = true;

        api.get('clients/' + params.userId + '/', {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(() => {
                setName(res.data.first_name + ' ' + res.data.last_name);
                setEmail(res.data.email);
                setPhoneNumber(formatPhoneNumber(res.data.phone_number));
                setBio(res.data.biography);
                setProfilePicture(res.data.profile);
                setIsSuccess(true);
            })
        }).catch(err => {
            setHasFailed(true);
        }).finally(() => {
            setIsProfileLoading(false);
        })
    }

    if (isLinkStatusLoading) {
        isLoading = true;

        api.get('links/status/' + params.userId + '/', {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            setLinkStatus(res.data);
        }).catch(err => {
            setHasFailed(true);
        }).finally(() => setIsLinkStatusLoading(false))
    }

    if(props.userInfo.isUserLoading) {
        isLoading = true;

        dispatch(setUserSession())
    }

    if (isLoading && !hasFailed) {
        // TODO: animate loading
        return <Loading />;
    } else if (!isProfileLoading && isSuccess && !isLinkStatusLoading) {
        if (Cookies.get('user_token') && Cookies.get('auth_token'))
            return (
                <div id="client-profile-container">
                    <ConfirmationModal name={name} userId={params.userId} setLinkStatus={setLinkStatus}/>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col text-center">
                                    <img
                                        src={profilePicture ? profilePicture : defaultProfilePic}
                                        alt="Profile pic"
                                        className={'img-thumbnail'}
                                        id={'profile-pic'}
                                    />
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col text-center">
                                    <h3>{name}</h3>
                                </div>
                            </div>
                            <div className="row border-top profile-content-section">
                                <div className="col">
                                    <ul id={'info-list'}>
                                        <li>
                                            <i className="far fa-envelope fa mr-4"></i>
                                            {email}
                                        </li>
                                        <li>
                                            <i className="fas fa-phone mr-4"></i>
                                            {phoneNumber}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* TODO: Check if linked already */}
                            <div className="row border-top profile-content-section">
                                <div className="col text-center">
                                    <LinkRequestButton profileUserId={params.userId} linkStatus={linkStatus}
                                                       setLinkStatus={setLinkStatus} dispatch={dispatch} />
                                </div>
                            </div>
                            <div className="row border-top profile-content-section">
                                <div className="col text-center">
                                    {/*<button className={'btn'} data-toggle={'tooltip'} title={'Send Message'}>*/}
                                    {/*    <i className="far fa-comment-alt fa-3x"></i>*/}
                                    {/*</button>*/}
                                    <button
                                        className={'btn w-100'}
                                        onClick={()=>window.location.href=mailTo}>
                                        Send Email
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col" id={'profile-bio'}>
                            <div className="row border-bottom">
                                <h3>{name}'s Bio</h3>
                            </div>
                            <div className="row pt-4">
                                <p>{bio}</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else
            return <Redirect to={'/'}/>
    } else {
        return (
            <div>
                error has occurred. this user does not exist in the system
            </div>
        )
    }
};

const mapStateToProps = (state : any) => {
    return { userInfo : state.userInfo }
}

export default connect(mapStateToProps)(ClientProfile);