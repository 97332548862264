import {Link} from "react-router-dom";
import {batch} from "react-redux";
import React from "react";
import ClientBudgetHeader from './ClientBudgetHeader';
import BudgetBarGraph from "./BudgetBarGraph";
import ExpenseChart from "./ExpenseChart";


const ClientBudget = ({name, setIsEditing, handleBudgetHeaderChange, modifiedBudgetList, monthlyBudget,
                                       monthlyExpenses, budgetList}) => (
    <>
        <div className="row">
            <div className="col-2">
                <Link to={'/consultant/home'} className={'btn btn-link'} id={'budget-back-btn'} data-toggle={'tooltip'} title={'Back to Dashboard'}>
                    <i className="fas fa-sign-out-alt fa-flip-horizontal fa-lg"></i>
                </Link>
            </div>
            <div className="col-8">
                <h3 className={'display'}>{name}'s Budget</h3>
            </div>
            <div className="col">
                <div className={'text-right'}>
                    <button type={'button'}
                            className={'btn btn-link'}
                            id={'edit-budget-btn'}
                            onClick={() => batch(() => {
                                setIsEditing(true);
                                handleBudgetHeaderChange(modifiedBudgetList);
                            })}
                            data-toggle="tooltip"
                            title="Edit"
                    >
                        <i className="far fa-edit fa-lg"></i>
                    </button>
                </div>
            </div>
        </div>
        <ClientBudgetHeader monthlyBudget={monthlyBudget} monthlyExpenses={monthlyExpenses} />
        <div className="row mt-5"></div>
        <div className="row mt-2 justify-content-center">
            {
                budgetList.length === 0 ?
                    <span className="text-center empty-budget">No budget exists for {name}</span>
                    :
                    budgetList.map(category => (
                        <div className="card category-card">
                            <div className="card-body text-center">
                                <h5 className="card-title">{category.title}</h5>
                                <div className="card-text" style={{height: "100%"}}>
                                    <BudgetBarGraph category={category} />
                                </div>
                            </div>
                        </div>
                    ))
            }
        </div>
        <div className="row mt-5">
            <div className="col" style={{height: "500px"}}>
                <ExpenseChart data={budgetList}/>
            </div>
        </div>
    </>
)

export default ClientBudget