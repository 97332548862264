import {
    CLEAR_CLIENT_INFO,
    IS_ACCOUNT_LINKED,
    IS_CLIENT_SESSION_LOADING,
    PLAID_LINK_TOKEN,
    SET_ACCOUNT_INFO
} from '../actions/actionTypes';

const initialState: any = {
    isClientSessionLoading: true,
    plaidLinkToken: '',
    isAccountLinked: true,
    email: '',
    phoneNumber: '',
    bio: '',
    firstName: '',
    lastName: '',
    profile: ''
};

const clientReducer = (state = initialState, action : any) => {
    switch(action.type) {
        case IS_CLIENT_SESSION_LOADING:
            return {
                ...state,
                isClientSessionLoading: action.payload
            };
        case PLAID_LINK_TOKEN:
            return {
                ...state,
                plaidLinkToken: action.payload
            };
        case IS_ACCOUNT_LINKED:
            return {
                ...state,
                isAccountLinked: action.payload
            };
        case CLEAR_CLIENT_INFO:
            return initialState;
        case SET_ACCOUNT_INFO:
            return {
                ...state,
                email: action.payload.email,
                phoneNumber: action.payload.phoneNumber,
                bio: action.payload.bio,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                profile: action.payload.profile
            }
        default:
            return state;
    }
}

export default clientReducer;