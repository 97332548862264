import {
    CLEAR_CLIENT_INFO,
    IS_ACCOUNT_LINKED,
    IS_CLIENT_ONBOARDING_ACTIVE,
    IS_CLIENT_SESSION_LOADING,
    PLAID_LINK_TOKEN,
    SET_ACCOUNT_INFO
} from "./actionTypes";
import Cookies from 'js-cookie';
import api from '../../api/api';
import {batch} from "react-redux";

export const clearInfo = () => ({
    type: CLEAR_CLIENT_INFO
});

export const setClientSession = () => (dispatch: Function) => {

    api.get("clients/session/", {
        headers: {
            Authorization: "Token " + Cookies.get("auth_token"),
        },
        withCredentials: true
    }).then(
        res => {
            if (res.status === 200) {
                batch(() => {
                    dispatch({
                        type: IS_ACCOUNT_LINKED,
                        payload: res.data['is_account_linked']
                    });
                    dispatch({
                        type: PLAID_LINK_TOKEN,
                        payload: res.data['link_token']
                    });
                });
            } else {
                Cookies.remove("user_token");
                Cookies.remove("auth_token");
            }
        },
        err => {
            Cookies.remove("user_token");
            Cookies.remove("auth_token");
        }
    ).then(() => {
        dispatch({
            type: IS_CLIENT_SESSION_LOADING,
            payload: false
        })
    });
};

export const setIsOnboardingActive = (payload: boolean) => ({
    type: IS_CLIENT_ONBOARDING_ACTIVE,
    payload
});

export const setAccountInfo = (setIsLoading : Function) => (dispatch : Function) => {

    api.get('clients/', {
        headers: {
            Authorization: "Token " + Cookies.get('auth_token'),
        },
        withCredentials: true
    }).then(res => {
        dispatch({
            type: SET_ACCOUNT_INFO,
            payload: {
                email: res.data['email'],
                phoneNumber: res.data['phone_number'],
                bio: res.data['biography'],
                firstName: res.data['first_name'],
                lastName: res.data['last_name'],
                profile: res.data['profile']
            }
        })
    }).then(() => {
        setIsLoading(false);
    })
}