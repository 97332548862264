// user action types
export const FIRST_NAME = 'FIRST_NAME';
export const LAST_NAME = 'LAST_NAME';
export const EMAIL = 'EMAIL';
export const PASSWORD = 'PASSWORD';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const USER_TYPE = 'USER_TYPE';
export const IS_USER_LOADING = 'IS_USER_LOADING';
export const IS_ON_BOARDING = 'IS_ON_BOARDING';
export const PROFILE_PICTURE = 'PROFILE_PICTURE';
export const IS_PROFILE_LOADING = 'IS_PROFILE_LOADING';

// client registration action types
export const SALARY = 'SALARY';
export const ADDRESS_TYPE = 'ADDRESS_TYPE';
export const ADDRESS_TYPE_DETAILS = 'ADDRESS_TYPE_DETAILS';
export const OWNS_CAR = 'OWNS_CAR';
export const LAST_FOUR_SSN = 'LAST_FOUR_SSN';
export const STREET_ADDRESS = 'STREET_ADDRESS';
export const CITY = 'CITY';
export const STATE_CODE = 'STATE_CODE';
export const ZIP_CODE = 'ZIP_CODE';
export const CLEAR_CLIENT_REGISTRATION_INFO = 'CLEAR_CLIENT_REGISTRATION_INFO';

// client action types
export const PLAID_LINK_TOKEN = 'PLAID_LINK_TOKEN';
export const CLEAR_CLIENT_INFO = 'CLEAR_CLIENT_INFO';
export const IS_ACCOUNT_LINKED = 'IS_ACCOUNT_LINKED';
export const IS_CLIENT_ONBOARDING_ACTIVE = 'IS_CLIENT_ONBOARDING_ACTIVE';
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO';

// client, consultant and agent action types
export const IS_CLIENT_SESSION_LOADING = 'IS_CLIENT_SESSION_LOADING';

// consultant action types
export const IS_CONSULTANT_SESSION_LOADING = 'IS_CONSULTANT_SESSION_LOADING';
export const CLEAR_CONSULTANT_INFO = 'CLEAR_CONSULTANT_INFO';
export const SET_CONSULTANT_PROFILE = 'SET_CONSULTANT_PROFILE';
export const IS_CONSULTANT_ONBOARDING_ACTIVE = 'IS_CONSULTANT_ONBOARDING_ACTIVE';

// calendar event types
export const IS_CALENDAR_LOADING = 'IS_CALENDAR_LOADING';
export const EVENTS_LIST = 'EVENTS_LIST';
export const CLEAR_CALENDAR = 'CLEAR_CALENDAR';
export const IS_EDIT_MODE_TOGGLED = 'IS_EDIT_MODE_TOGGLED';
export const EDITABLE_FROM_DATE = 'EDITABLE_FROM_DATE';
export const EDITABLE_TO_DATE = 'EDITABLE_TO_DATE';
export const ACTIVE_EVENT = 'ACTIVE_EVENT';
export const IS_ACTIVE_EVENT_LOADING = 'IS_ACTIVE_EVENT_LOADING';
export const SET_ATTENDEES_LIST = 'SET_ATTENDEES_LIST';
export const SET_INITIAL_ATTENDEES = 'SET_INITIAL_ATTENDEES';
export const IS_EVENT_LIST_REQUEST_PROCESSING = 'IS_EVENT_LIST_REQUEST_PROCESSING';

// client bank accounts types
export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';
export const SET_IS_ACCOUNT_LIST_LOADING = 'SET_IS_ACCOUNT_LIST_LOADING';

// client bank account's transactions
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_MEMO = 'SET_MEMO';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_IS_TRANSACTIONS_LOADING = 'SET_IS_TRANSACTIONS_LOADING';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';

//ClientConsole->Agent Referral request
export const SET_REFERRAL_ID = 'SET_REFERRAL_ID';
export const SET_CLIENT_NAME = 'SET_CLIENT_NAME';
export const SET_CLIENT_EMAIL = 'SET_CLIENT_NAME';
export const SET_CLIENT_GOAL = 'SET_CLIENT_GOAL';
export const SET_CLIENT_COMMENT = 'SET_CLIENT_COMMENT';
export const SET_CLIENT_EXPENSES = 'SET_CLIENT_EXPENSES';
export const SET_CLIENT_INCOME = 'SET_CLIENT_INCOME';
export const SET_CLIENT_SENT_DATE = 'SET_CLIENT_SENT_DATE';
export const SET_COMPLETED_DATE = 'SET_COMPLETED_DATE';
export const GET_QUOTE = 'GET_QUOTE';
export const SET_STATUS = 'SET_STATUS';

//TODO: Consultant registration
export const SPECIALITY = 'SPECIALITY';
export const HIRE_DATE = 'HIRE_DATE';
export const END_DATE = 'END_DATE';

//TODO: Consultant actions
export const SET_NEW_CLIENT = 'NEW_CLIENT';
// export const SET_CLIENT_LIST = 'SET_CLIENT_LIST';
export const SET_CALENDAR = 'SET_CALENDAR';
export const SET_NEW_QUOTE = 'SET_NEW_QUOTE';
export const SET_NEW_REFERRALS = 'SET_NEW_REFERRAL';
export const SET_REFERRAL_LIST = 'SET_REFERRAL_LIST';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const REMOVE_QUOTE = 'REMOVE_QUOTE';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
// export const CLEAR_CONSULTANT_INFO = 'CLEAR_CONSULTANT_INFO';

//TODO: Consultant ClientConsole Info
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_CLIENT_PLAN = 'SET_CLIENT_PLAN';

// connected lists types
export const SET_COLLABORATOR_LIST = 'SET_COLLABORATOR_LIST';
export const SET_OUTGOING_REQUESTS = 'SET_OUTGOING_REQUESTS';
export const SET_INCOMING_REQUESTS = 'SET_INCOMING_REQUESTS';
export const SET_CURRENT_PAGE_NUMBER = 'SET_CURRENT_PAGE_NUMBER';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_IS_COLLABORATORS_LOADING = 'SET_IS_COLLABORATORS_LOADING';
export const SET_CONNECTED_COLLABORATORS = 'SET_CONNECTED_COLLABORATORS';
export const SET_CONNECTED_CLIENTS = 'SET_CONNECTED_CLIENTS';
export const SET_IS_CONNECTED_LIST_LOADING = 'SET_IS_CONNECTED_LIST_LOADING';

// client list types
export const SET_CLIENTS_LIST = 'SET_CLIENTS_LIST';
export const SET_IS_CLIENTS_LIST_LOADING = 'SET_IS_CLIENTS_LIST_LOADING';

// client budget types
export const SET_CLIENT_BUDGET = 'SET_CLIENT_BUDGET';
export const TOGGLE_IS_BUDGET_LOADING = 'TOGGLE_IS_BUDGET_LOADING';

// task details types
export const SET_SELECTED_TASK_INDEX = 'SET_SELECTED_TASK_INDEX';
export const ADD_TODO_LIST_ITEM = 'ADD_TODO_LIST_ITEM';
export const SET_IS_TASK_LIST_LOADING = 'SET_IS_TASK_LIST_LOADING';
export const SET_TASK_LIST = 'SET_TASK_LIST'
export const TOGGLE_TASK_IS_FAVORITE = 'TOGGLE_TASK_IS_FAVORITE'
export const SET_TASK_DEADLINE = 'SET_TASK_DEADLINE'
export const SET_TASK_DETAILS = 'SET_TASK_DETAILS'
export const SET_TASK_TITLE = 'SET_TASK_TITLE'
export const TOGGLE_TASK_CHECKMARK = 'TOGGLE_TASK_CHECKMARK'
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER'
export const FILTER_TASK_LIST = 'FILTER_TASK_LIST'
export const UPDATE_TASK_LIST = 'UPDATE_TASK_LIST'
export const DELETE_TASK_ITEMS = 'DELETE_TASK_ITEMS'