import {batch, connect, useDispatch} from "react-redux";
import React, {useState} from "react";
import {Link, Redirect} from "react-router-dom";
import api from "../../api/api";
import {setEmail, setFirstName, setLastName, setUserSession} from "../../redux/actions/user";
import Cookies from "js-cookie";


const AdminLogin = (props: any) => {

    const [isSuccess, setIsSuccess] = useState(false);
    const [loginEmail, setLoginEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const onEnterClicked = (e : any) => {
        if (e.keyCode === 13)
            submitLogin();
    };

    const submitLogin = () => {

        if (loginEmail && password) {
            api.post('admins/login/', {email: loginEmail, password}, { withCredentials : true })
                .then(res => {
                    $('#error-msg').addClass('alert alert-success');
                    $('#error-msg').text('Success!');
                    batch(() => {
                        dispatch(setEmail(res.data['email']));
                        dispatch(setFirstName(res.data['first_name']));
                        dispatch(setLastName(res.data['last_name']));
                        dispatch(setUserSession());
                        setIsSuccess(true);
                    });
                }, err => {
                    $('#error-msg').addClass('alert alert-danger');
                    $('#error-msg').text('Invalid credentials');
                })
        }
    };

    if(isSuccess) {
        return <Redirect to={'/admin/home/create-consultant'}/>
    }else
        return (
            <div className="login form-group" onKeyDown={(e) => onEnterClicked(e)}>
                <div id="header">
                    <h2>Admin Login</h2>
                </div>
                <hr/>
                <div className='row'>
                    <div className="col">
                        <div id="error-msg"></div>
                    </div>
                </div>
                <div className="md-form">
                    <input type="text" id={'email'} className="form-control" onChange={(e) => setLoginEmail(e.target.value)}/>
                    <label htmlFor={'email'}>Email</label>
                </div>
                <div className="md-form">
                    <input type="password" id={'password'} className="form-control" onChange={(e) => setPassword(e.target.value)}/>
                    <label htmlFor={'password'}>Password</label>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-one" id="login-btn" onClick={submitLogin}>Login</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <Link to={'/forgot-password'} className="form-control" id="forgot-password-link">Forgot password?</Link>
                    </div>
                </div>
            </div>
        )
};

const mapStateToProps = (state: any) => {
    return {userInfo: state.userInfo}
};

export default connect(mapStateToProps)(AdminLogin);
