import './Login.css';

import React from 'react';
import {Link, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import ClientLogin from "./ClientLogin";
import ConsultantsLogin from "./ConsultantsLogin";
import AgentLogin from "./AgentLogin";


function LoginBase(){
    return(
        <div className="col text-center" >
            <Link to="/login/client">
                <img src="https://img.icons8.com/nolan/96/administrator-male.png" alt="my picture" height="128" width="128" />
            </Link>

            <Link to="/login/agent">
                <img src="https://img.icons8.com/nolan/96/commercial-development-management.png" alt="my picture" height="128" width="128" />
            </Link>

            <Link to="/login/consultant">
                <img src="https://img.icons8.com/nolan/96/men-age-group-5--v2.png" alt="my picture" height="128" width="128" />
            </Link>
            <Route path='/login/client' component={ClientLogin} />
            <Route path='/login/consultant' component={ConsultantsLogin} />
            <Route path='/login/agent' component={AgentLogin} />
        </div>
          
    )
}

export default connect()(LoginBase);

