import {ResponsivePie} from "@nivo/pie";
import React from "react";

const ExpenseChart = ({ data }) => (
    <ResponsivePie
        data={data.map(item => (
            {
                id: item.title,
                label: item.title,
                value: item.expended
            }
        ))}
        margin={{top: 30, right: 30, bottom: 30, left: 30}}
        innerRadius={0.6}
        padAngle={3}
        cornerRadius={3}
        colors={{scheme: 'dark2'}}
        borderWidth={3}
        borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{from: 'color'}}
        sliceLabel={(e) => {
            return "$" + e.value
        }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#ffffff"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={{
            fontSize: '16px'
        }}
    />
)

export default ExpenseChart