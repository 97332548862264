import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Layout from './components/Layout';
import Landing from './components/Landing';
import BasicInfoRegistration from './components/Register/BasicInfoRegistration';
import PersonalInfoRegistration from './components/Register/PersonalInfoRegistration';
import ClientHome from './components/Client/Home/ClientHome';
import CalendarView from './components/Client/Calendar/CalendarView';
import ClientSettings from "./components/Client/Settings/ClientSettings";
import AgentHome from './components/Agent/AgentHome';
import LoginHome from './components/Login/LoginBase';
import './custom.css';
import ClientLinks from "./components/Client/Links/ClientLinks";
import ConsultantProfile from "./components/Consultant/Profile";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import ErrorPage from "./components/Error/ErrorPage";
import AdminLogin from "./components/Login/AdminLogin";
import AdminHome from "./components/Admin/AdminHome";
import AdminGeneralSettings from "./components/Admin/AdminGeneralSettings";
import 'react-notifications/lib/notifications.css';
import AgentProfile from "./components/Agent/AgentProfile";
import ConsultantHome from "./components/Consultant/ConsultantHome";
import ConsultantCalendarView from "./components/Consultant/Calendar/ConsultantCalendarView";
import ConsultantSettings from "./components/Consultant/Settings/ConsultantSettings";
import AgentSettings from "./components/Agent/Settings/AgentSettings";
import AgentCalendarView from "./components/Agent/Calendar/AgentCalendarView";
import ClientSearch from "./components/ClientSearch/ClientSearch";
import ClientProfile from "./components/Client/Profile/ClientProfile";
import ConsultantBudget from "./components/Consultant/ClientConsole/ClientConsoleMain";

// TODO: redirect on login to home page
// TODO: if user is logged in then ensure he is redirected from login or registration
// TODO: if user is not logged in ensure he is redirected from any components that require logging in

const App = () => {

    const createNotifications = (type, notificationsList) => {
        if(type === 'success')
            notificationsList.forEach(notification => {
                NotificationManager.success(notification.body, notification.title, 5000);
            })
        else if(type === 'error')
            notificationsList.forEach(notification => {
                NotificationManager.error(notification.body, notification.title, 5000);
            })
    }

    return (
        <Router>
            <Layout>
                <NotificationContainer />
                <Switch>
                    <Route exact path='/' component={Landing} />
                    <Route path='/register/personal' render={() => <PersonalInfoRegistration createNotifications={createNotifications}/>} />
                    <Route path='/register/basic' component={BasicInfoRegistration} />
                    <Route path='/login' component={LoginHome} />
                    <Route path='/client/calendar' component={CalendarView} />
                    <Route path='/client/home' component={ClientHome} />
                    <Route path='/client/settings' component={ClientSettings} />
                    <Route path={'/client/links'} component={ClientLinks} />
                    <Route path={'/client/profile/:userId'} component={ClientProfile} />
                    <Route path={'/consultant/profile/:userId'} component={ConsultantProfile} />
                    <Route path={'/consultant/home'} render={() => <ConsultantHome createNotifications={createNotifications}/>} />
                    <Route path={'/consultant/calendar'} component={ConsultantCalendarView} />
                    <Route path={'/(consultant|agent)/search'} component={ClientSearch} />
                    <Route path={'/consultant/client/:userId'} render={props => <ConsultantBudget createNotifications={createNotifications} {...props} /> } />
                    <Route path={'/consultant/settings'} render={() => <ConsultantSettings createNotifications={createNotifications}/>} />
                    <Route path={'/reset-password/:forgotPassToken'} component={ResetPassword} />
                    <Route path={'/forgot-password'} render={() => <ForgotPassword createNotifications={createNotifications}/>} />
                    <Route path={'/admin-login'} component={AdminLogin} />
                    <Route path={'/admin/home'} render={() => <AdminHome createNotifications={createNotifications}/>} />
                    <Route path={'/admin/settings/general/change-password'} render={() => <AdminGeneralSettings createNotifications={createNotifications}/>} />
                    <Route path={'/agent/profile/:userId'} component={AgentProfile} />
                    <Route path={'/agent/calendar'} component={AgentCalendarView} />
                    <Route path='/agent/home' component={AgentHome} />
                    <Route path={'/agent/settings'} render={() => <AgentSettings createNotifications={createNotifications}/>} />
                    <Route component={ErrorPage} />
                </Switch>
            </Layout>
        </Router>
    );
}

export default App;