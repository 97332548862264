import {CLEAR_CLIENT_INFO, SET_CONSULTANT_PROFILE} from '../actions/actionTypes';

const initialState: any = {
    email: '',
    phoneNumber: '',
    bio: '',
    firstName: '',
    lastName: '',
    profile: '',
    professions: []
};

const collaboratorReducer = (state = initialState, action : any) => {
    switch(action.type) {
        case CLEAR_CLIENT_INFO:
            return initialState;
        case SET_CONSULTANT_PROFILE:
            return {
                ...state,
                email: action.payload.email,
                phoneNumber: action.payload.phoneNumber,
                bio: action.payload.bio,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                profile: action.payload.profile,
                professions: action.payload.professions
            }
        default:
            return state;
    }
}

export default collaboratorReducer;