import {
    ADDRESS_TYPE,
    ADDRESS_TYPE_DETAILS,
    CITY,
    CLEAR_CLIENT_REGISTRATION_INFO,
    LAST_FOUR_SSN,
    OWNS_CAR,
    SALARY,
    STATE_CODE,
    STREET_ADDRESS,
    ZIP_CODE
} from '../actions/actionTypes';

const initialState: any = {
    salary: 0,
    addressType: '',
    addressTypeDetails: '',
    ownsCar: true,
    lastFourSsn: '',
    streetAddress: '',
    city: '',
    stateCode: '',
    zipCode: ''
}

const clientRegistrationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SALARY:
            return {
                ...state,
                salary: action.payload
            };
        case ADDRESS_TYPE:
            return {
                ...state,
                addressType: action.payload
            };
        case ADDRESS_TYPE_DETAILS:
            return {
                ...state,
                addressTypeDetails: action.payload
            };
        case OWNS_CAR:
            return {
                ...state,
                ownsCar: action.payload
            };
        case LAST_FOUR_SSN:
            return {
                ...state,
                lastFourSsn: action.payload
            };
        case STREET_ADDRESS:
            return {
                ...state,
                streetAddress: action.payload
            };
        case CITY:
            return {
                ...state,
                city: action.payload
            };
        case STATE_CODE:
            return {
                ...state,
                stateCode: action.payload
            };
        case ZIP_CODE:
            return {
                ...state,
                zipCode: action.payload
            };
        case CLEAR_CLIENT_REGISTRATION_INFO:
            return initialState;
        default:
            return state;
    }
}

export default clientRegistrationReducer;