import React, {useState} from 'react';
import {batch, connect, useDispatch} from 'react-redux';
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment, {Moment} from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./AgentCalendar.css";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Cookies from 'js-cookie';
import api from "../../../api/api";
import {setEventsList} from "../../../redux/actions/event";
import Modals, {setEventModalContentById} from "./CalendarModals";
import {setConnectedLists} from "../../../redux/actions/connectedLists";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from '@material-ui/core';
import {clearUserInfo, setUserSession} from "../../../redux/actions/user";
import Loading from "../../Loading/Loading";
// TODO: add delete for events created by user
// TODO: add put (save changes)


declare var $: any;

const localizer = momentLocalizer(moment);

const createNewEvent = (fromDate: Moment | null, toDate: Moment | null, dispatch: Function, attendees: any, attendeesKey: number, setAttendeesKey: Function, isLoading : boolean, setIsLoading : Function) => {

    if(!isLoading)
        setIsLoading(true);

    let millisFromDate = null;
    let millisToDate = null;

    if (fromDate && toDate) {
        const timezoneOffset = fromDate.toDate().getTimezoneOffset();
        const millisOffset = timezoneOffset * 60 * 1000;
        millisFromDate = fromDate.toDate().getTime() + millisOffset;
        millisToDate = toDate.toDate().getTime() + millisOffset;
    } else {
        return null;
    }

    const newEvent = {
        title: $('#title').val().trim(),
        details: $('#details-text-area').val().trim(),
        organizer: Cookies.get('user_token'),
        attendees: attendees && attendees.length > 0 ? [...attendees] : [],
        start_date: millisFromDate,
        end_date: millisToDate,
        is_all_day: $('#all-day').is(':checked')
    };

    api.post('events/', newEvent,
        {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')

            },
            withCredentials: true
        })
        .then(res => {
            //TODO: show confirmation
            batch(() => {
                dispatch(setEventsList())
                setAttendeesKey(attendees + 1);
            })
            $('#new-event-modal').modal('hide');
            $('#title').val('');
            $('#details-text-area').val('');
        }).catch(err => {
        // TODO:
        // some error occurred
        console.log(err.response);
    }).finally(() => setIsLoading(false))

};

const toggleAllDay = () => {
    if ($('#all-day').prop('checked')) {
        $('.date-time-container').css('display', 'none');
        $('.date-container').css('display', 'block');
    } else {
        $('.date-time-container').css('display', 'block');
        $('.date-container').css('display', 'none');
    }
};

const NewEventModal = (props: any) => {

    const dispatch = useDispatch();
    const [toDate, setToDate] = useState<Moment | null>(moment().add(30, 'minutes'));
    const [fromDate, setFromDate] = useState<Moment | null>(moment());
    const handleDateChange = (date: Moment | null, setDate: Function) => {
        setDate(date);
    };
    const [attendees, setAttendees] = useState();
    const [isLoading, setIsLoading] = useState(false);

    // Can only programmatically clear autocomplete tags by changing associated key,
    // so solutions is to increment the key whenever the other form fields are cleared
    //
    // Ex. once form is submitted each field should be removed from state, only way
    //     to remove field in autocomplete input is to change teh key's state
    const [attendeesKey, setAttendeesKey] = useState(0);

    const defaultProps = {
        options: props.contacts,
        getOptionLabel: (option: any) => option.name,
    };

    return (
        <div className="modal fade" id="new-event-modal" tabIndex={-1} role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="event-title">Create new event</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body form-group" id="new-event-form">
                        <div className="row">
                            <div className="col">
                                <div className="md-form">
                                    <input type="text" id={'title'} className="form-control"/>
                                    <label htmlFor={'title'}>Event Title</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Autocomplete
                                    multiple
                                    key={attendeesKey}
                                    id="tags-standard"
                                    {...defaultProps}
                                    onChange={(event: any, attendees: any[] | undefined) => setAttendees(attendees)}
                                    renderInput={(params) => {
                                        return <TextField {...params} variant="standard" label="Attendees"/>
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id={'all-day'} className="custom-control-input"
                                           onClick={() => toggleAllDay()}/>
                                    <label className="custom-control-label" htmlFor={'all-day'}>All day</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <div className="date-time-container">
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="From"
                                            format="MM/DD/YYYY"
                                            value={fromDate}
                                            onChange={(date: Moment | null) => handleDateChange(date, setFromDate)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="To"
                                            format="MM/DD/YYYY"
                                            value={toDate}
                                            onChange={(date: Moment | null) => handleDateChange(date, setToDate)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </div>
                                    <div className="date-container">
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="From"
                                            format="MM/DD/YYYY"
                                            value={fromDate}
                                            onChange={(date: Moment | null) => handleDateChange(date, setFromDate)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col">
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <div className="date-time-container">
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label=" "
                                            value={fromDate}
                                            onChange={(date: Moment | null) => handleDateChange(date, setFromDate)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label=" "
                                            value={toDate}
                                            onChange={(date: Moment | null) => handleDateChange(date, setToDate)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    </div>
                                    <div className="date-container">
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="To"
                                            format="MM/DD/YYYY"
                                            value={toDate}
                                            onChange={(date: Moment | null) => handleDateChange(date, setToDate)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="row" id="details-row">
                            <div className="col form-group">
                                <label htmlFor="details-text-area">Details</label>
                                <textarea className="form-control" id="details-text-area" rows={7}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-two" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-one" disabled={isLoading}
                                onClick={() => createNewEvent(fromDate, toDate, dispatch, attendees, attendeesKey, setAttendeesKey, isLoading, setIsLoading)}>
                            {isLoading ? 'Loading...' : 'Create'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export const viewEventDetails = (event: any) => {

    $('#event-details-modal').modal('show');
    $('#event-title').text(event.title);
    $('#event-organizer').text(event.organizer);
    $('#event-details').text(event.details);

    if (event.allDay) {
        $('#event-from-date').text(moment(event.start).format('MMM Do YYYY'));
        $('#event-to-date').text(moment(event.end).format('MMM Do YYYY'));

        $('#all-day-details-row').css('display', 'block');
    } else {
        $('#event-from-date').text(moment(event.start).format('MMM Do YYYY, h:mm a'));
        $('#event-to-date').text(moment(event.end).format('MMM Do YYYY, h:mm a'));

        $('#all-day-details-row').css('display', 'none');
    }

    // if (Cookies.get('user_token') === event.organizerId)
    //     $('#modify-event-btn').css('display', 'block');
    // else
    //     $('#modify-event-btn').css('display', 'none');
};

const AgentCalendarView = (props: any) => {

    const dispatch = useDispatch();
    const {isCalendarLoading, eventsList, isEditModeToggled} = props.eventsInfo;
    const {connectedClientsList} = props.connectedList;

    let isLoading = false;

    if (props.userInfo.isUserLoading) {
        isLoading = true;

        dispatch(setUserSession());
    }

    if (isCalendarLoading) {
        isLoading = true;

        dispatch(setEventsList());
    }

    if (props.connectedList.isConnectedListLoading) {
        isLoading = true;

        dispatch(setConnectedLists());
    }

    if (isLoading) {
        // TODO: animate loading
        return <Loading/>
    } else {

        if(Cookies.get('user_token') && Cookies.get('auth_token'))
            return (
                <div id={'calendar-container'}>
                    <ul className={'notification-column'}>
                        <li><Modals.NotificationsModal/></li>
                    </ul>
                    <Modals.EventDetailsModal/>
                    <NewEventModal contacts={connectedClientsList}/>
                    <Modals.DiscardChangesModal/>
                    <Calendar
                        timeslots={4}
                        localizer={localizer}
                        eventPropGetter={(event, start, end, isSelected) => {
                            if (event['organizerId'] !== Cookies.get('user_token'))
                                return {
                                    className: '',
                                    style: {backgroundColor: '#D85438'}
                                }
                            else return {
                                className: '',
                                style: {},
                            };
                        }}
                        events={eventsList}
                        startAccessor="start"
                        endAccessor="end"
                        onDoubleClickEvent={(event: any) => {
                            viewEventDetails(event);
                            setEventModalContentById(dispatch, event.id, eventsList)
                        }}
                    />
                    <button className="btn btn-one float-right" data-toggle="modal"
                            data-target="#new-event-modal">New...
                    </button>
                </div>
            );
        else {
            Cookies.remove('user_token');
            Cookies.remove('auth_token');
            dispatch(clearUserInfo());

            window.location.href = '/';
            return null;
        }
    }
};

const mapStateToProps = (state: any) => {
    return {
        userInfo: state.userInfo,
        eventsInfo: state.eventsInfo,
        connectedList: state.connectedList
    }
};

export default connect(mapStateToProps)(AgentCalendarView);