import {Link} from "react-router-dom";
import React from "react";
import ClientBudgetHeader from './ClientBudgetHeader'
import {batch} from "react-redux";
import api from "../../../api/api";
import Cookies from "js-cookie";
import {useParams} from "react-router";
import BudgetBarGraph from "./BudgetBarGraph";
import ExpenseChart from "./ExpenseChart";


const ClientBudgetEditable = ({name, setIsEditing, handleBudgetHeaderChange, modifiedBudgetList, monthlyBudget,
                                  monthlyExpenses, budgetList, setBudgetList, createNotifications, setModifiedBudgetList,
                                  idCounter, setIdCounter}) => {

    const params = useParams()
    const clientId = params.userId

    const categoriesList = [
        "Bank Fees",
        "Community",
        "Food and Drink",
        "Healthcare",
        "Interest",
        "Payment",
        "Recreation",
        "Service",
        "Shops",
        "Tax",
        "Transfer",
        "Travel"
    ]

    const handleOnSaveChanges = (e) => {
        setBudgetList(modifiedBudgetList);

        const request = modifiedBudgetList.map(budget => (
            {
                category: budget.title,
                budgeted_amount: budget.budgetedAmount,
                expended: budget.expended
            }
        ))

        api.post('budgets/' + clientId + '/', request, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            setIsEditing(false);

            createNotifications('success', [{
                title: 'Success',
                body: 'ClientConsole updated successfully'
            }])
        }).catch(err => {
            console.log('err res: ', err.response.data)
            createNotifications('error', Object.keys(err.response.data).map(key => {
                return {
                    title: 'Error',
                    body: err.response.data[key]
                }
            }))
        })
    }

    const handleOnDiscardChanges = () => {
        batch(() => {
            setIsEditing(false);
            setModifiedBudgetList(budgetList);
            handleBudgetHeaderChange(budgetList);
        })
    }

    const handleAddCategory = () => {
        setModifiedBudgetList([...budgetList, {
            id: idCounter,
            title: 'Bank Fees',
            expended: 0,
            budgetedAmount: 0
        }])
        setIdCounter(idCounter + 1);
    }

    const handleCategoryChange = (e, category) => {
        setModifiedBudgetList(
            [...modifiedBudgetList].map(item => {
                if(item.id === category.id)
                    return {
                        id: item.id,
                        title: e.target.value,
                        expended: item.expended,
                        budgetedAmount: item.budgetedAmount
                    }
                return item
            })
        )
    }

    const handleBudgetValueChange = (e, category) => {
        const tempModified = [...modifiedBudgetList].map(item => {
            if(item.id === category.id)
                return {
                    id: item.id,
                    title: item.title,
                    expended: item.expended,
                    budgetedAmount: e.target.value
                }
            return item
        })

        batch(() => {
            setModifiedBudgetList(tempModified);
            handleBudgetHeaderChange(tempModified);
        })
    }

    const handleDeleteCategory = (id) => {
        setModifiedBudgetList(
            [...modifiedBudgetList].filter(item => item.id !== id)
        )
    }

    return (
        <div>
            <div className="row">
                <div className="col-2">
                    <Link to={'/consultant/home'} className={'btn btn-link'} id={'budget-back-btn'} data-toggle={'tooltip'}
                          title={'Back to Dashboard'}>
                        <i className="fas fa-sign-out-alt fa-flip-horizontal fa-lg"></i>
                    </Link>
                </div>
                <div className="col-8">
                    <h3 className={'display-4'}>{name}'s Budget</h3>
                </div>
                <div className="col">
                    <div className={'text-right'}>
                        <button className="btn title-btn"
                                id={'save-btn'}
                                onClick={handleOnSaveChanges}
                                data-toggle={'tooltip'}
                                title={'Save Changes'}
                        >
                            <i className="fa fa-floppy-o fa-lg" aria-hidden="true"></i>
                        </button>
                        <button className="btn title-btn"
                                id={'discard-btn'}
                                onClick={handleOnDiscardChanges}
                                data-toggle={'tooltip'}
                                title={'Discard Changes'}
                        >
                            <i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <ClientBudgetHeader monthlyBudget={monthlyBudget} monthlyExpenses={monthlyExpenses}/>
            <div className="row mt-5"></div>
            <div className="row mt-2 justify-content-center">
                {
                    modifiedBudgetList.map(category => (
                        <div className="card edit-category-card">
                            <div className="card-body text-center">
                                <div className="row card-title">
                                    <div className="col text-center">
                                        <select id="category-select" className={'form-control'}
                                                onChange={(e) => handleCategoryChange(e, category)}>
                                            {
                                                categoriesList.map(item => (
                                                    <option value={item} selected={category.title === item}>{item}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-1">
                                        <button className={'btn delete-btn float-right'}
                                                onClick={() => handleDeleteCategory(category.id)}>
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className={'card-text'} style={{height: '60%'}}>
                                    {/*<div style={{height: '100%'}}>*/}
                                    <BudgetBarGraph category={category}/>
                                    {/*</div>*/}
                                    <div className={'md-form budget-input-form'}>
                                        <input
                                            type="text"
                                            className={'form-control'}
                                            id={'budget-input-' + category.id}
                                            onChange={(e) => handleBudgetValueChange(e, category)}
                                            defaultValue={category.budgetedAmount && category.budgetedAmount != 0 ? category.budgetedAmount : null}
                                        />
                                        <label
                                            htmlFor={'budget-input-' + category.id}
                                            className={category.budgetedAmount ? 'active' : null}
                                        >{category.title} Budget</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

                {
                    modifiedBudgetList.length < 12 ?
                        <div className="card category-card" id={'add-category-card'}>
                            <div className="text-center" id={'add-category-body'}>
                                <button className={'btn'} id="add-category-btn" onClick={handleAddCategory}>
                                    <i className="fa fa-plus fa-5x" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className="row mt-5">
                <div className="col" style={{height: "500px"}}>
                    <ExpenseChart data={budgetList}/>
                </div>
            </div>
        </div>
    )
}

export default ClientBudgetEditable