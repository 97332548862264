import React from "react";

const formatMoney = number => {
    return number < 0 ? '-$' + parseFloat(Math.abs(number)).toFixed(2) : '$' + parseFloat(number).toFixed(2);
}

const ClientBudgetHeader = ({ monthlyExpenses, monthlyBudget }) => {

    const remainder = monthlyBudget - monthlyExpenses;

    return (
        <div className="row mt-2">
            <div className="col-md mt-2">
                <div className={'budget-card box-shadow text-center budget-danger'}>
                    <h4>Expenses</h4>
                    <h2>{formatMoney(monthlyExpenses)}</h2>
                </div>
            </div>
            <div className="col-md mt-2">
                <div className="budget-card box-shadow text-center budget-success">
                    <h4>Monthly Budget</h4>
                    <h2>{formatMoney(monthlyBudget)}</h2>
                </div>
            </div>
            <div className="col-md mt-2">
                <div className="budget-card box-shadow text-center">
                    <h4>Remainder</h4>
                    <h2>{formatMoney(remainder)}</h2>
                </div>
            </div>
        </div>
    )
}

export default ClientBudgetHeader