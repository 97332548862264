import {
    IS_CALENDAR_LOADING,
    EVENTS_LIST,
    CLEAR_CALENDAR,
    IS_EDIT_MODE_TOGGLED,
    EDITABLE_FROM_DATE,
    EDITABLE_TO_DATE,
    ACTIVE_EVENT, IS_ACTIVE_EVENT_LOADING, SET_ATTENDEES_LIST, SET_INITIAL_ATTENDEES, IS_EVENT_LIST_REQUEST_PROCESSING
} from "./actionTypes";
import api from "../../api/api";
import Cookies from "js-cookie";
import {batch} from "react-redux";
import moment, {calendarFormat} from "moment";

export const parseEvent = (event : any) => {

    const timezoneOffset = new Date().getTimezoneOffset();
    const millisOffset = timezoneOffset * 60 * 1000;
    const millisStartDate = event.start_date - millisOffset;
    const millisEndDate = event.end_date - millisOffset;

    return {
        id: event.id,
        title: event.title,
        details: event.details,
        organizer: event.organizer,
        organizerId: event.organizer_id,
        start: moment(millisStartDate).toDate(),
        end: moment(millisEndDate).toDate(),
        allDay: event.is_all_day,
        attendees: [...event.attendees],
        resource: event.resource
    }
};

const parseEvents = (eventsList : any) => {
    return eventsList.map( (calendarEvent : any) => {
        return parseEvent(calendarEvent);
    })
};

export const setEventsList = (activeEventId? : number, resolve? : Function) => (dispatch : Function, getState : Function) => {

    if(!getState().eventsInfo.isEventRequestProcessing) {
        dispatch({
            type: IS_EVENT_LIST_REQUEST_PROCESSING,
            payload: true
        })

        api.get('events/' + Cookies.get('user_token'), {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        })
        .then(res => {
            batch(() => {
                dispatch({
                    type: EVENTS_LIST,
                    payload: parseEvents(res.data['invited'].concat(res.data['organized']))
                })
                if (activeEventId)
                    dispatch(setActiveEventById(activeEventId, resolve));
            });
        })
        .catch(err => {
            if (err.response.status === 401) {
                Cookies.remove("user_token");
                Cookies.remove("auth_token");
            }
        })
        .then(() => {
            batch(() => {
                dispatch({
                    type: IS_CALENDAR_LOADING,
                    payload: false
                })
                dispatch({
                    type: IS_EVENT_LIST_REQUEST_PROCESSING,
                    payload: false
                })
            });
        });
    }
};

export const clearCalendar = () => ({
    type: CLEAR_CALENDAR
});

export const setIsEditModeToggled = () => ({
    type: IS_EDIT_MODE_TOGGLED
});

export const setEditableFromDate = (payload : any) => ({
    type: EDITABLE_FROM_DATE,
    payload
});

export const setEditableToDate = (payload : any) => ({
    type: EDITABLE_TO_DATE,
    payload
});

// export const setActiveEvent = (payload : any) => ({
//     type: ACTIVE_EVENT,
//     payload
// });

export const setActiveEventById = (payload : number, resolve? : Function) => (dispatch : Function, getState : any) => {

    dispatch({
        type: IS_ACTIVE_EVENT_LOADING,
        payload: true
    })

    const activeEvent = getState().eventsInfo.eventsList.find((calendarEvent : any) =>
        calendarEvent && calendarEvent.id === payload
    );

    if(resolve)
        resolve(activeEvent);

    batch(()=> {
        dispatch({
            type: ACTIVE_EVENT,
            payload: activeEvent
        });
        dispatch({
            type: SET_INITIAL_ATTENDEES,
            payload: activeEvent.attendees
        })
        dispatch({
            type: IS_ACTIVE_EVENT_LOADING,
            payload: false
        });
    })
};

export const toggleIsActiveEventOff = () => ({
    type: IS_ACTIVE_EVENT_LOADING,
    payload: false
})

export const setAttendeesList = (payload : any) => ({
    type: SET_ATTENDEES_LIST,
    payload
})
