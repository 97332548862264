import {
    SET_ACCOUNT,
    SET_CATEGORY,
    SET_END_DATE,
    SET_IS_TRANSACTIONS_LOADING,
    SET_MEMO,
    SET_START_DATE,
    SET_TRANSACTIONS
} from '../actions/actionTypes';

const intitialState = {
        institution_name: '',
        transactions: [],
        isTransactionsLoading: true
    };
    
    const transactionsReducer = (state = intitialState, action : any) => {
        switch(action.type){
            case SET_ACCOUNT:
                return {
                    ...state,
                    institution_name: action.payload,
                    transactions: action.payload.transactions,
                };
            case SET_CATEGORY:
                return {
                    ...state,
                    amount: action.payload
                };    
            case SET_MEMO:
                return {
                    ...state,
                    dueDate: action.payload
                };
            case SET_START_DATE:
                return {
                    ...state,
                    frequency: action.payload
                };
            case SET_END_DATE:
                return {
                    ...state,
                    type: action.payload
                };
            case SET_TRANSACTIONS:
                return {
                    ...state,
                    institution_name: action.payload.institution_name,
                    transactions: action.payload.transactions,
                    isTransactionsLoading: false
                };
            case SET_IS_TRANSACTIONS_LOADING:
                return {
                    ...state,
                    isTransactionsLoading: action.payload
                };
            default:
                return state;
        }
    };
    
    export default transactionsReducer;