import {Link} from "react-router-dom";
import React, {useState} from "react";
import {batch, connect, useDispatch} from "react-redux";
import {
    addTodoListItem,
    deleteCheckedTasks,
    filterTaskList,
    setSelectedTaskIndex,
    setTaskList,
    setTasksCompleted, toggleTaskIsFavorite
} from "../../../redux/actions/userTasks";
import Loading from "../../Loading/Loading";
import {AddTaskRow} from "../../TaskList/TaskList";
import RoundCheckbox from "../../utils/Checkbox/RoundCheckbox";
import Cookies from "js-cookie";
import moment from "moment";
import StarCheckbox from "../../utils/Checkbox/StarCheckbox";
import ClientTaskDetailsModal from "./ClientTaskDetailsModal";


const TaskItem = props => {

    const dispatch = useDispatch();

    const selectTaskDetails = () => {
        batch(() => {
            dispatch(setSelectedTaskIndex(props.taskIndex))

            props.setTaskModalVisibility(true)
        })
    }

    return (
        <div className={'border-bottom unselectable'}>
            <div className="row p-4 font-weight-bolder">
                <div className="col-1 task-checkbox-container">
                    <RoundCheckbox key={props.taskIndex} id={props.taskIndex} isChecked={props.task.isChecked} />
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col task-title-container">
                            <button className={'task-edit-btn'} onClick={selectTaskDetails}>
                                {props.task.title}
                            </button>
                            {
                                props.task.is_completed ?
                                    <span id={'completed-text'}>
                                        <i className="fas fa-check"></i>
                                        <span>
                                            Done!
                                        </span>
                                    </span>
                                    : null
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {
                                props.task.organizer_id !== Cookies.get('user_token') ?
                                    <div className="task-organizer-container">
                                        {props.task.organizer}
                                    </div>
                                    : null
                            }
                            {
                                props.task.deadline ?
                                    <div className={'task-deadline-container'}>
                                        <i className="fa fa-calendar-o" aria-hidden="true"></i>
                                        <span>{moment(props.task.deadline).toDate().toDateString()}</span>
                                        {
                                            moment().isAfter(moment(props.task.deadline).toDate()) && !props.task.is_completed ?
                                                <span className={'past-due-text'}>
                                                    Past Due!
                                                </span>
                                                : null
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className={'task-favorite-container'}>
                    <StarCheckbox key={props.taskIndex} taskIndex={props.taskIndex} id={props.id} isToggled={props.task.is_favorite} onClick={() => dispatch(toggleTaskIsFavorite(props.taskIndex, props.clientId))}/>
                </div>
            </div>
        </div>
    )
}

export const TaskList = props => (
    <div className="row">
        <div className="col">
            {props.taskIndexes.map(taskIndex =>
                <TaskItem
                    key={taskIndex}
                    task={props.taskList[taskIndex]}
                    id={props.taskList[taskIndex].id}
                    taskIndex={taskIndex}
                    setTaskModalVisibility={props.setTaskModalVisibility}
                    clientId={props.clientId}
                />
            )}
        </div>
    </div>
)

const NewTaskRow = ({ setIsEditing, clientId }) => {

    const [taskTitle, setTaskTitle] = useState('');
    const [isTitleInvalid, setIsTitleInvalid] = useState(false);
    const dispatch = useDispatch()

    const saveTaskItem = () => {
        const trimmedTaskTitle = taskTitle.trim();

        if(trimmedTaskTitle && trimmedTaskTitle !== '') {
            setIsTitleInvalid(false)

            // TODO: update to be handled by api
            dispatch(addTodoListItem(taskTitle, clientId))

            setIsEditing(false)
        } else {
            setIsTitleInvalid(true)
        }
    }

    const handleKeyDown = (keyDownEvent) => {
        const keyPressed = keyDownEvent.keyCode;

        if(keyPressed === 13) {
            saveTaskItem()
        } else if (keyPressed === 27) {
            setIsTitleInvalid(false)
            setIsEditing(false)
        }
    }

    const handleOnBlur = () => {
        saveTaskItem()
    }

    return (
        <div className={'border-bottom'}>
            <div className="row p-4 font-weight-bolder">
                <div className="col-1 task-checkbox-container">
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className="md-form">
                                <input
                                    type="text"
                                    id={'new-task-title'}
                                    className={'form-control ' + (isTitleInvalid ? 'is-invalid invalid' : '')}
                                    onChange={e => setTaskTitle(e.target.value)}
                                    onKeyDown={e => handleKeyDown(e)}
                                    onBlur={handleOnBlur}
                                    autoFocus={true}
                                />
                                <label htmlFor={'new-task-title'} className={taskTitle ? 'active' : ''}>Task Title</label>
                                <div class="invalid-feedback">
                                    Cannot be a blank title
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClientTaskList = props => {
    const [isAddingTask, setIsAddingTask] = useState(false);
    const [isTaskModalVisible, setTaskModalVisibility] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isInitialComponentLoad, setIsInitialComponentLoad] = useState(true)

    const dispatch = useDispatch()

    let todoTaskIndexes = []
    let completedTaskIndexes = []
    let isLoading = false;

    if(props.userTasks.isLoading || isInitialComponentLoad) {

        if(isInitialComponentLoad)
            batch(() => {
                dispatch(setTaskList(props.clientId))
                setIsInitialComponentLoad(false)
            })
        else
            dispatch(setTaskList(props.clientId))

        isLoading = true;
    }

    props.userTasks.filteredTaskList.forEach(taskIndex =>
        props.userTasks.taskList[taskIndex].is_completed ? completedTaskIndexes.push(taskIndex) : todoTaskIndexes.push(taskIndex)
    )

    const onEnterClicked = e => {
        if(e.keyCode === 13)
            dispatch(filterTaskList(e.target.value))
    }

    if(isLoading)
        return <Loading/>

    return (
        <>
            <div className="row">
                <div className="col-2">
                    <Link to={'/consultant/home'} className={'btn btn-link'} id={'budget-back-btn'} data-toggle={'tooltip'} title={'Back to Dashboard'}>
                        <i className="fas fa-sign-out-alt fa-flip-horizontal fa-lg"></i>
                    </Link>
                </div>
            </div>
            <div>
                <div className="col">
                    <div>
                        <ClientTaskDetailsModal
                            setTaskModalVisibility={setTaskModalVisibility}
                            isTaskModalVisible={isTaskModalVisible}
                            clientId={props.clientId}
                        />
                        <div className="row">
                            <div className="col" id={'task-list-header'}>
                                {props.name}'s Task List
                            </div>
                            <div className="col d-flex justify-content-end">
                                <div className="md-form">
                                    <input
                                        type="text"
                                        id={'task-list-search'}
                                        className={'form-control'}
                                        onChange={e => batch(() => {
                                            setSearchQuery(e.target.value)
                                            dispatch(filterTaskList(e.target.value))
                                        })}
                                        onKeyDown={onEnterClicked}
                                    />
                                    <label htmlFor={'task-list-search'}>Search</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul className="nav nav-tabs justify-content-end pr-4" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="todo-tasks-tab" data-toggle="tab" href="#todo-tasks"
                                           role="tab" aria-controls="todo-tasks" aria-selected="true">To Do ({todoTaskIndexes.length})</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="completed-tasks-tab" data-toggle="tab" href="#completed-tasks"
                                           role="tab" aria-controls="completed-tasks" aria-selected="false">Completed ({completedTaskIndexes.length})</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="todo-tasks" role="tabpanel"
                                         aria-labelledby="todo-tasks-tab">
                                        <div className="row">
                                            <div className="col">
                                                <TaskList
                                                    setTaskModalVisibility={setTaskModalVisibility}
                                                    taskList={props.userTasks.taskList}
                                                    taskIndexes={todoTaskIndexes}
                                                    clientId={props.clientId}
                                                />
                                                <div className="row">
                                                    <div className="col" onClick={() => setIsAddingTask(true)}>
                                                        {
                                                            isAddingTask ?
                                                                <NewTaskRow
                                                                    setIsEditing={setIsAddingTask}
                                                                    clientId={props.clientId}
                                                                />
                                                                :
                                                                <AddTaskRow />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <button className={'btn task-action-btn'} id={'complete-tasks-btn'} onClick={() => dispatch(setTasksCompleted(props.clientId))}>
                                                            <i className="fas fa-clipboard-check fa-lg"></i>
                                                            <span className={'ml-2'}>Mark as Complete</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="completed-tasks" role="tabpanel"
                                         aria-labelledby="completed-tasks-tab">
                                        {
                                            completedTaskIndexes.length > 0 ?
                                                <div className="row">
                                                    <div className="col">
                                                        <TaskList
                                                            setTaskModalVisibility={setTaskModalVisibility}
                                                            taskList={props.userTasks.taskList}
                                                            taskIndexes={completedTaskIndexes}
                                                            clientId={props.clientId}
                                                        />
                                                        <div className="row">
                                                            <div className="col">
                                                                <button className={'btn task-action-btn'} id={'delete-tasks-btn'} onClick={() => dispatch(deleteCheckedTasks(searchQuery, props.clientId))}>
                                                                    <i className="fa fa-trash-o fa-lg"></i>
                                                                    <span className={'ml-2'}>Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className={'row'}>
                                                    <div className="col m-4 text-center">
                                                        No completed tasks
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        userTasks : state.userTasks
    }
};

export default connect(mapStateToProps)(ClientTaskList);