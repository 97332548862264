import React, {useState} from 'react';
import {batch, connect, useDispatch} from "react-redux";
import Cookies from 'js-cookie';
import {clearUserInfo, setUserSession} from "../../redux/actions/user";
import {Redirect, Route} from "react-router";
import {Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import './Admin.css';
import api from "../../api/api";
import Loading from "../Loading/Loading";


const CreateAdmin = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isCreateSuccess, setIsCreateSuccess] = useState(false);

    const createUser = () => {
        const request = {
            first_name: firstName,
            last_name: lastName,
            email
        }

        api.post('admins/create/admin/', request, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(() => {
                props.createNotifications('success', [{
                    title: 'Admin created successfully',
                    body: ''
                }])
                setIsCreateSuccess(true);
                setFirstName(res.data['first_name']);
                setLastName(res.data['last_name']);
                setEmail(res.data['email']);
            })
        }).catch(err => {
            props.createNotifications('error', Object.keys(err.response.data).map(key => {
                return {
                    title: 'Error Occurred',
                    body: err.response.data[key]
                }
            }))
        })
    }

    const enterClicked = (e) => {
        if (e.keyCode === 13)
            createUser()
    }

    const resetState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setIsCreateSuccess(false);
    }

    if(isCreateSuccess)
        return (
            <div className={'created-content'}>
                <h4 className={'text-center'}>Admin Created</h4>
                <table>
                    <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{firstName + ' ' + lastName}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{email}</td>
                    </tr>
                    <tr>
                        <td className={'created-content-btn'} colSpan={2}><button className={'btn btn-two'} onClick={resetState}>Ok</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )

    return (
        <div onKeyDown={(e) => enterClicked(e)}>
            <h4>Create Admin</h4>
            <div className={'form-body'}>
                <table>
                    <tbody className={'md-form'}>
                    <tr>
                        <td><span>First Name:</span></td>
                        <td><input type={'text'} className={'form-control'} onChange={(e)=>setFirstName(e.target.value)} value={firstName}/></td>
                        <td><span>Last Name:</span></td>
                        <td><input type={'text'} className={'form-control'} onChange={(e)=>setLastName(e.target.value)} value={lastName}/></td>
                    </tr>
                    <tr>
                        <td><span>Email:</span></td>
                        <td colSpan={3}><input type={'text'} className={'form-control'} onChange={(e)=>setEmail(e.target.value)} value={email}/></td>
                    </tr>
                    <tr>
                        <td className={'text-center pt-4'} colSpan={4}>
                            <button className={'btn btn-success w-100'} onClick={createUser}>Create</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const CreateAgent = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [agentId, setAgentId] = useState('');
    const [isCreateSuccess, setIsCreateSuccess] = useState(false);

    const createUser = () => {
        const request = {
            first_name: firstName,
            last_name: lastName,
            email
        }

        api.post('admins/create/agent/', request, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(() => {
                props.createNotifications('success', [{
                    title: 'Agent created successfully',
                    body: ''
                }])
                setIsCreateSuccess(true);
                setAgentId(res.data['agent_id']);
                setFirstName(res.data['first_name']);
                setLastName(res.data['last_name']);
                setEmail(res.data['email']);
            })
        }).catch(err => {
            props.createNotifications('error', Object.keys(err.response.data).map(key => {
                return {
                    title: 'Error Occurred',
                    body: err.response.data[key]
                }
            }))
        })
    }

    const enterClicked = (e) => {
        if (e.keyCode === 13)
            createUser()
    }

    const resetState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setAgentId('');
        setIsCreateSuccess(false);
    }

    if(isCreateSuccess)
        return (
            <div className={'created-content'}>
                <h4 className={'text-center'}>Agent Created</h4>
                <table>
                    <tbody>
                    <tr>
                        <td>Agent ID</td>
                        <td>{agentId}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{firstName + ' ' + lastName}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{email}</td>
                    </tr>
                    <tr>
                        <td className={'created-content-btn'} colSpan={2}><button className={'btn btn-two'} onClick={resetState}>Ok</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )

    return (
        <div onKeyDown={(e) => enterClicked(e)}>
            <h4>Create Agent</h4>
            <div className={'form-body'}>
                <table>
                    <tbody className={'md-form'}>
                    <tr>
                        <td><span>First Name:</span></td>
                        <td><input type={'text'} className={'form-control'} onChange={(e)=>setFirstName(e.target.value)} value={firstName}/></td>
                        <td><span>Last Name:</span></td>
                        <td><input type={'text'} className={'form-control'} onChange={(e)=>setLastName(e.target.value)} value={lastName}/></td>
                    </tr>
                    <tr>
                        <td><span>Email:</span></td>
                        <td colSpan={3}><input type={'text'} className={'form-control'} onChange={(e)=>setEmail(e.target.value)} value={email}/></td>
                    </tr>
                    <tr>
                        <td className={'text-center pt-4'} colSpan={4}>
                            <button className={'btn btn-success w-100'} onClick={createUser}>Create</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const CreateConsultant = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [isCreateSuccess, setIsCreateSuccess] = useState(false);

    const createUser = () => {
        const request = {
            first_name: firstName,
            last_name: lastName,
            email
        }

        api.post('admins/create/consultant/', request, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(() => {
                props.createNotifications('success', [{
                    title: 'Consultant created successfully',
                    body: ''
                }])
                setIsCreateSuccess(true);
                setEmployeeId(res.data['employee_id']);
                setFirstName(res.data['first_name']);
                setLastName(res.data['last_name']);
                setEmail(res.data['email']);
            })
        }).catch(err => {
            console.log(err.response.data);
            Object.keys(err.response.data).forEach(key => {
                props.createNotifications('error', [{
                    body: err.response.data[key],
                    title: 'Error Occurred'
                }]);
            });
        })
    }

    const enterClicked = (e) => {
        if (e.keyCode === 13)
            createUser()
    }

    const resetState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setEmployeeId('');
        setIsCreateSuccess(false);
    }

    if(isCreateSuccess)
        return (
            <div className={'created-content'}>
                <h4  className={'text-center'}>Consultant Created</h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Employee ID</td>
                            <td>{employeeId}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{firstName + ' ' + lastName}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{email}</td>
                        </tr>
                        <tr>
                            <td className={'created-content-btn'} colSpan={2}><button className={'btn btn-two'} onClick={resetState}>Ok</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )

    return (
        <div onKeyDown={(e) => enterClicked(e)}>
            <h4>Create Consultant</h4>
            <div className={'form-body'}>
                <table>
                    <tbody className={'md-form'}>
                    <tr>
                        <td><span>First Name:</span></td>
                        <td><input type={'text'} className={'form-control'} onChange={(e)=>setFirstName(e.target.value)} value={firstName}/></td>
                        <td><span>Last Name:</span></td>
                        <td><input type={'text'} className={'form-control'} onChange={(e)=>setLastName(e.target.value)} value={lastName}/></td>
                    </tr>
                    <tr>
                        <td><span>Email:</span></td>
                        <td colSpan={3}><input type={'text'} className={'form-control'} onChange={(e)=>setEmail(e.target.value)} value={email}/></td>
                    </tr>
                    <tr>
                        <td className={'text-center pt-4'} colSpan={4}>
                            <button className={'btn btn-success w-100'} onClick={createUser}>Create</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const SettingsNavBar = () => (
    <Navbar id={'submenu-nav'}
            className="navbar-expand-sm navbar-toggleable-sm border-bottom border-right border-left box-shadow mb-3"
            light>
        <Container>
            <NavbarToggler className="mr-2"/>
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse mt-2 justify-content-center" navbar>
                <ul className="navbar-nav flex-grow">
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/admin/home/create-consultant">Consultant</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/admin/home/create-agent">Agent</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/admin/home/create-admin">Admin</NavLink>
                    </NavItem>
                </ul>
            </Collapse>
        </Container>
    </Navbar>
);

const AdminHome = (props) => {

    const dispatch = useDispatch();

    let isLoading = false;

    if(props.userInfo.isUserLoading) {
        isLoading = true;
        dispatch(setUserSession());
    }

    if(isLoading)
        return <Loading/>
    else{
        if(Cookies.get('user_token') && Cookies.get('auth_token') && props.userInfo.userType === 'admin') {
            return (
                <>
                    <SettingsNavBar/>
                    <div className={'forms-content border'}>
                        <Route path={'/admin/home/create-consultant'}
                               component={() => <CreateConsultant createNotifications={props.createNotifications}/>}/>
                        <Route path={'/admin/home/create-agent'}
                               component={() => <CreateAgent createNotifications={props.createNotifications}/>}/>
                        <Route path={'/admin/home/create-admin'}
                               component={() => <CreateAdmin createNotifications={props.createNotifications}/>}/>
                    </div>
                </>
            )
        } else {
            Cookies.remove('user_token');
            Cookies.remove('auth_token');
            dispatch(clearUserInfo());

            window.location.href = '/';
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {userInfo : state.userInfo}
}

export default connect(mapStateToProps)(AdminHome);