import React from "react";
import {connect, useDispatch} from "react-redux";
import Cookies from 'js-cookie';
import {setClientSession} from '../../../redux/actions/client';
import "./ClientHome.css";
import ClientOnBoarding from "./ClientOnBoarding";
import {Link} from "react-router-dom";
import moment from "moment";
import {setAccountList} from "../../../redux/actions/clientBankAccounts";
import {clearUserInfo, setUserSession} from "../../../redux/actions/user";
import Loading from '../../Loading/Loading';
import TaskListContainer from "../../TaskList/TaskList";
import {setClientBudget} from "../../../redux/actions/clientBudget";


// TODO: remove
const categories = [
    "Bank Fees",
    "Community",
    "Food and Drink",
    "Healthcare",
    "Interest",
    "Payment",
    "Recreation",
    "Service",
    "Shops",
    "Tax",
    "Transfer",
    "Travel"
]
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const TransactionItem = ({ item }) => (
    <div className="row m-0 border-top expense-row">
        <div className="col-1"></div>
        <div className="col-4">
            <span className={'pl-3'}>{item.title[0]}</span>
        </div>
        <div className="col">
            {item.date}
        </div>
        <div className="col">
            {currencyFormatter.format(item.expended)}
        </div>
    </div>
)

const TransactionsList = ({ subCategories }) => {

    if(subCategories.length > 0)
        return (
            <div>
                <div className="row m-0 border-top expense-row">
                    <div className="col-1"></div>
                    <div className="col-4">
                        <span className={'pl-3 category-header'}>Sub-Category</span>
                    </div>
                    <div className="col">
                        <span className={'category-header'}>Date</span>
                    </div>
                    <div className="col">
                        <span className={'category-header'}>Expended</span>
                    </div>
                </div>
                {subCategories.map(subCategory => <TransactionItem item={subCategory} />)}
            </div>
        )
    else return null
}

const BudgetCategory = ({ category, id }) => {

    const collapsibleCategoryId = 'collapsible-category-' + id
    const budgetedAmount = currencyFormatter.format(category.budgeted_amount)
    const expended = category.sub_category.reduce((acc, category) => acc + category.expended, 0)
    const expendedAmount = currencyFormatter.format(expended)
    const remaining = category.budgeted_amount - expended
    const remainingAmount = currencyFormatter.format(remaining)

    return (
        <div className="border-bottom accordion">
            <div
                className={'budget-category-row unselectable'}
                data-toggle={'collapse'}
                data-target={'#' + collapsibleCategoryId}
                aria-expanded="true"
                aria-controls={collapsibleCategoryId}
            >
                <div className="row m-0">
                    <div className="col-1">
                    </div>
                    <div className="col-3">
                        {category.title}
                    </div>
                    <div className="col">
                        {budgetedAmount}
                    </div>
                    <div className="col">
                        {expendedAmount}
                    </div>
                    <div className={'col'} id={remaining >= 0 ? 'net-remaining-pos' : 'net-remaining-neg'}>
                        {remainingAmount}
                    </div>
                </div>
            </div>
            <div className="collapse" id={collapsibleCategoryId}>
                <TransactionsList subCategories={category.sub_category} />
            </div>
        </div>
    )
}

const ClientBudget = ({ budgetList }) => {

    return (
        <div className={'client-home-container'}>
            <div className="row">
                <div className="col" id={'task-list-header'}>
                    Budget
                </div>
            </div>
            <div className="border-bottom">
                <div className="row m-0" id={'budget-category-header'}>
                    <div className="col-1"></div>
                    <div className="col-3 category-header">
                        Category
                    </div>
                    <div className="col category-header">
                        Budgeted Amount
                    </div>
                    <div className="col category-header">
                        Expended
                    </div>
                    <div className="col category-header">
                        Remaining
                    </div>
                </div>
            </div>
            {
                budgetList.map((category, index) => <BudgetCategory category={category} id={index}/>)
            }
        </div>
    )

}

const UnlinkedAccountContent = () => (
    <div>
        Please link your bank account to LinkUBank. To do so <Link to={'/client/settings/bank-account'}>click here</Link>
    </div>
)

const ClientContentContainer = props => {
    return (
        <div>
            <div className="row">
                <div className="col">
                    <ClientBudget budgetList={props.budgetList}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className={'client-home-container'}>
                        <TaskListContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClientHome = props => {

    const dispatch = useDispatch();
    let end_date = moment().format("YYYY-MM-DD");
    let start_date = moment().subtract(2, 'month').format("YYYY-MM-DD");

    let isLoading = false;

    if(props.userInfo.isUserLoading) {
        isLoading = true;
        dispatch(setUserSession());
    }

    if(props.clientInfo.isClientSessionLoading){
        isLoading = true;
        dispatch(setClientSession());
    }

    if (props.clientAccounts.isAccountListLoading) {
        isLoading = true;
        dispatch(setAccountList());
    }

    if (props.clientBudget.isBudgetLoading) {
        isLoading = true;
        dispatch(setClientBudget());
    }
        
    if(isLoading){
        return <Loading />;
    } else {

        if(Cookies.get('user_token') && Cookies.get('auth_token'))
            return (
                <div>
                    { props.userInfo.isOnboardingActive ? <ClientOnBoarding /> : null }
                    { props.clientInfo.isAccountLinked ? <ClientContentContainer budgetList={props.clientBudget.budgetList}/> : <UnlinkedAccountContent /> }
                </div>
            );
        else {
            Cookies.remove('user_token');
            Cookies.remove('auth_token');
            dispatch(clearUserInfo());

            window.location.href = '/';
            return null;
        }
    }
};

const mapStateToProps = state => {
    return {
        clientInfo : state.clientInfo,
        clientAccounts: state.clientAccounts,
        accountTransactions: state.accountTransactions,
        userInfo: state.userInfo,
        clientBudget: state.clientBudget
    }
};

export default connect(mapStateToProps)(ClientHome);