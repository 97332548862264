import * as React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import Cookies from "js-cookie";
import financialSecurityImg from '../media/images/financial_security_img.jpg';
import './Landing.css';

const Landing = (props : any) => {

    if(props.userInfo.isUserLoading) return null;
    else if(Cookies.get('user_token') && Cookies.get('auth_token') && props.userInfo.userType) {
        if(props.userInfo.userType !== 'admin')
            return <Redirect to={'/' + props.userInfo.userType + '/home'}/>
        else
            return <Redirect to={'/' + props.userInfo.userType + '/home/create-consultant'}/>
    } else
        return (
            <div id={'landing-page-container'}>
                <div className="row">
                    <div className="col-lg-3 content-container">
                        <h4 className="display content-heading text-center">
                            News
                        </h4>
                    </div>
                    <div className="col content-container p-0">
                        <h4 className="display content-heading">
                            Welcome to LinkUBank
                        </h4>
                        <p className={'m-4'}>
                            LinkUBank is a financial consultation application designed to connect you with financial
                            consultants and agents. The financial consultants at LinkUBank are professionally trained finance
                            experts. Once you reach your budgeting goals LinkUBank can also help by quickly linking you with
                            an agent that specializes in what it is you may be budgeting for.
                        </p>
                        <p className={'m-4'}>
                            Getting started with LinkUBank is completely free, all you have to do is click Register
                            and the process explains itself along the way. Once registered, you can link your bank and
                            working the multiple consultants and agents a part of LinkUBank.
                        </p>
                        <img src={financialSecurityImg}
                             width={'400px'}
                             alt={'Image'}
                             className={'img-fluid rounded float-right'}
                        />
                    </div>
                </div>
            </div>
        );
};

const mapStateToProps = (state : any) => {
  return { userInfo: state.userInfo }
}

export default connect(mapStateToProps)(Landing);
