import axios from 'axios';

export default axios.create(
    {
        baseURL: process.env.NODE_ENV  === 'development' || process.env.NODE_ENV === 'test' ?
            'http://localhost:8000/api/'
            :
            'https://www.linkubank.com/api/'
    }
);
