import React, {useState} from "react";
import {batch, connect, useDispatch} from "react-redux";
import {setClientsList} from "../../redux/actions/clientList";
import {Link} from "react-router-dom";
import formatPhoneNumber from "../../api/formatPhoneNumber";
import './ClientSearch.css';
import Loading from "../Loading/Loading";


const ClientItem = (props: any) => {

    const mailTo = 'mailto:' + props.client.email;

    return (
        <div className={'row'}>
            <div className="col-5" id={'client-item-col'}>
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-5 text-center">
                                <Link
                                    to={'/client/profile/' + props.client.user_id}
                                    className={'btn-link card-title'}
                                >
                                    {props.client.name}
                                </Link>
                                <p className="card-text">{formatPhoneNumber(props.client.phone_number)}</p>
                            </div>
                            <div className="col-md text-center p-0">
                                <p className="card-text text-right pr-5">
                                    <button
                                        className={'btn'}
                                        id={'email-icon-button'}
                                        onClick={()=>window.location.href=mailTo}
                                    >
                                        <i className="far fa-envelope fa-3x"></i>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClientList = (props : any) => {

    const { clientsList, totalPages, currentPageNumber, isClientsListLoading } = props.clientsList;
    const [isListRequested, setIsListRequested] = useState(false);
    const [pageSpan, setPageSpan] = useState([1]);
    const dispatch = useDispatch();

    let isLoading = false;

    if(!isListRequested) {
        batch(() => {
            viewPage(dispatch, 1);
            setIsListRequested(true);
        })
        isLoading = true;
    }

    if(!isLoading && totalPages != pageSpan.length){
        let temp : number[] = [];
        let i = currentPageNumber - 2 >= 1 ? currentPageNumber - 2 : 1;

        for(; i <= totalPages && i <= 5; i++)
            temp.push(i)

        batch(()=> {
            setPageSpan(temp);
        })

        isLoading = true;
    }

    if(isLoading || isClientsListLoading)
        return <Loading />

    return (
        <div>
            <div className="row clients-list">
                <div className="col text-center">
                    {
                        clientsList.length > 0 ?
                            clientsList.map((client: any) => (
                                <ClientItem client={client}/>
                            ))
                            :
                            <span>There are no clients that match your search</span>
                    }
                </div>
            </div>
            <div className="row" id={'pagination-footer'}>
                <div className="col text-center">
                    <span className={'page-arrow'}>
                        {
                            currentPageNumber - 1 > 0 && clientsList.length > 0?
                                <button className={'btn paging-btn'} onClick={() => viewPage(dispatch, currentPageNumber - 1, props.searchQuery)}>
                                    <i className="fas fa-caret-left"></i>
                                </button>
                                : null
                        }
                    </span>
                    {
                        clientsList.length > 0 ?
                            pageSpan.map(pageNum => {
                                if(pageNum == currentPageNumber)
                                    return (
                                        <span className={'page-number selected-page'}>{pageNum}</span>
                                    )
                                return (
                                    <button className={'btn btn-link paging-btn'} onClick={() => viewPage(dispatch, pageNum, props.searchQuery)}>
                                        <span className={'page-number'}>{pageNum}</span>
                                    </button>
                                )
                            })
                            : null
                    }
                    <span className={'page-arrow'}>
                        {
                            currentPageNumber + 1 <= totalPages && clientsList.length > 0 ?
                                <button className={'btn paging-btn'} onClick={() => viewPage(dispatch, currentPageNumber + 1, props.searchQuery)}>
                                    <i className="fas fa-caret-right"></i>
                                </button>
                                : null
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}

const viewPage = (dispatch : Function, pageNumber : number, searchQuery? : string) => {
    if(searchQuery && searchQuery.trim())
        dispatch(setClientsList('clients/search/' + pageNumber + '/' + searchQuery.trim() + '/'));
    dispatch(setClientsList('clients/search/' + pageNumber + '/'));
}

const clearSearch = (dispatch : Function, setSearchIsActive : Function, setSearchQuery : Function) => {
    batch(() => {
        dispatch(setClientsList('clients/search/1'))
        setSearchQuery('');
        setSearchIsActive(false);
    })
}

const searchClients = (dispatch: Function, searchQuery : string, setIsSearchActive : Function) => {

    if(searchQuery) {
        batch(()=>{
            setIsSearchActive(true);
            viewPage(dispatch, 1, searchQuery)
        })
    }
}

const onEnterClicked = (e: any, dispatch: Function, setIsSearchActive : Function, searchQuery : string) => {
    if (e.keyCode === 13)
        searchClients(dispatch, searchQuery, setIsSearchActive);
};

const ClientSearch = (props : any) => {

    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);

    let cancelBtnVisiblility : any = isSearchActive ? 'visible' : 'hidden';
    let isLoading = false;

    if(isLoading)
        return <Loading />;

    return(
        <div>
            <div className="row mt-2">
                <div className="col">
                    <h3 className={'m-0 text-center'}>Client Search</h3>
                </div>
            </div>
            <div className="row clients-headers">
                <div className={'md-form'}>
                    <div className={'row'}>
                        <input
                            type={'text'}
                            id={'search-clients-input'}
                            className={'form-control'}
                            defaultValue={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => onEnterClicked(e, dispatch, setIsSearchActive, searchQuery)}
                        />
                        <label htmlFor={'search-clients-input'} className={searchQuery ? 'active': ''}>Search</label>
                        <button
                            className={'btn'}
                            id={'clear-search-btn'}
                            style={{visibility: cancelBtnVisiblility}}
                            onClick={() => {clearSearch(dispatch, setIsSearchActive, setSearchQuery);}}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                        <button
                            className={'btn'}
                            id={'client-search-btn'}
                            onClick={() => searchClients(dispatch, searchQuery, setIsSearchActive)}
                        >
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ClientList clientsList={props.clientsList} searchQuery={searchQuery}/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state : any) => {
    return {clientsList : state.clientsList}
}

export default connect(mapStateToProps)(ClientSearch)