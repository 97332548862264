import {CLEAR_CONSULTANT_INFO, SET_CONSULTANT_PROFILE} from "./actionTypes";
import Cookies from 'js-cookie';
import api from '../../api/api';

export const clearInfo = () => ({
    type: CLEAR_CONSULTANT_INFO
});

export const setAccountInfo = (setIsLoading : Function, userType : string) => (dispatch : Function, getState : Function) => {
    api.get(userType + 's/profile/', {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token'),
        },
        withCredentials: true
    }).then(res => {
        dispatch({
            type: SET_CONSULTANT_PROFILE,
            payload: {
                email: res.data['email'],
                phoneNumber: res.data['phone_number'],
                bio: res.data['biography'],
                firstName: res.data['first_name'],
                lastName: res.data['last_name'],
                profile: res.data['profile'],
                professions: res.data['professions'] || []
            }
        })
    }).finally(() => {
        setIsLoading(false);
    })
}