import api from '../../api/api';
import Cookies from "js-cookie";
import {batch} from "react-redux";
import {
    SET_COLLABORATOR_LIST, SET_CONNECTED_CLIENTS, SET_CONNECTED_COLLABORATORS, SET_CURRENT_PAGE_NUMBER,
    SET_INCOMING_REQUESTS, SET_IS_COLLABORATORS_LOADING, SET_IS_CONNECTED_LIST_LOADING,
    SET_OUTGOING_REQUESTS, SET_TOTAL_PAGES
} from "./actionTypes";


export const setCollaboratorsList = (url : string) => (dispatch : Function) => {

    batch(() => {
        dispatch({
             type: SET_COLLABORATOR_LIST,
             payload: []
         })
        dispatch({
             type: SET_IS_COLLABORATORS_LOADING,
             payload: true
         })
    })

    api.get(url,
        {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }
    ).then(res => {
        batch(() =>{
            dispatch({
                type: SET_COLLABORATOR_LIST,
                payload: [...res.data.users]
            })
            if(res.data.current_page && res.data.total_pages) {
                dispatch({
                    type: SET_CURRENT_PAGE_NUMBER,
                    payload: res.data.current_page
                })
                dispatch({
                    type: SET_TOTAL_PAGES,
                    payload: res.data.total_pages
                })
            }
            dispatch({
                type: SET_IS_COLLABORATORS_LOADING,
                payload: false
            })
        })
    }, err => {
        //TODO: add on error handler
    })
}

export const setConnectedLists = () => (dispatch : Function, getState : Function) => {

    if(!getState().connectedList.isConnectedListLoading)
        batch(() => {
            dispatch({
                type: SET_CONNECTED_COLLABORATORS,
                payload: {
                    agents: [],
                    consultants: [],
                    clients: []
                }
            })
            dispatch({
                type: SET_IS_CONNECTED_LIST_LOADING,
                payload: true
            })
        })

    api.get('links/',
        {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }
    ).then(res => {
        batch(() =>{
            res.data['agents'] || res.data['consultants'] ?
                dispatch({
                    type: SET_CONNECTED_COLLABORATORS,
                    payload: res.data
                })
            :
                dispatch({
                    type: SET_CONNECTED_CLIENTS,
                    payload: res.data
                })
            dispatch({
                type: SET_IS_CONNECTED_LIST_LOADING,
                payload: false
            })
        })
    }).catch(err => {
        Cookies.remove('user_token');
        Cookies.remove('auth_token');
    }).finally(() => {
        dispatch({
            type: SET_IS_CONNECTED_LIST_LOADING,
            payload: false
        })
    });
}

export const clearCollaboratorList = () => ({
    type: SET_COLLABORATOR_LIST,
    payload: []
})

export const setLinkRequests = (setIsLoading? : Function) => (dispatch : Function) => {

    api.get('links/requests/',
        {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }
    ).then(res => {
        batch(() => {
            if (dispatch)
                dispatch({
                    type: SET_COLLABORATOR_LIST,
                    payload: []
                });

            dispatch({
                type: SET_INCOMING_REQUESTS,
                payload: [...res.data.incoming]
            })
            dispatch({
                type: SET_OUTGOING_REQUESTS,
                payload: [...res.data.outgoing]
            })
            if(setIsLoading) setIsLoading(false);
        })
    }).catch(err => {
        //TODO: add on error handler
        if(setIsLoading) setIsLoading(false);
    })
}

// export const setIsLoading = (payload : boolean) => ({
//     type: SET_IS_COLLABORATORS_LOADING,
//     payload
// })

// TODO: build search response
// export const setSearchList = (payload : any) => {
//
// }