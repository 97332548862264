import {batch} from "react-redux";
import {
    SET_CLIENTS_LIST,
    SET_COLLABORATOR_LIST,
    SET_CURRENT_PAGE_NUMBER, SET_IS_CLIENTS_LIST_LOADING,
    SET_IS_COLLABORATORS_LOADING,
    SET_TOTAL_PAGES
} from "./actionTypes";
import api from "../../api/api";
import Cookies from "js-cookie";

export const setClientsList = (url : string) => (dispatch : Function) => {

    batch(() => {
        dispatch({
            type: SET_CLIENTS_LIST,
            payload: []
        })
        dispatch({
            type: SET_IS_CLIENTS_LIST_LOADING,
            payload: true
        })
    })

    api.get(url,
        {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }
    ).then(res => {
        batch(() =>{
            dispatch({
                type: SET_CLIENTS_LIST,
                payload: [...res.data.clients]
            })
            if(res.data.current_page && res.data.total_pages) {
                dispatch({
                    type: SET_CURRENT_PAGE_NUMBER,
                    payload: res.data.current_page
                })
                dispatch({
                    type: SET_TOTAL_PAGES,
                    payload: res.data.total_pages
                })
            }
            dispatch({
                type: SET_IS_CLIENTS_LIST_LOADING,
                payload: false
            })
        })
    }, err => {
        //TODO: add on error handler
        dispatch({
            type: SET_IS_CLIENTS_LIST_LOADING,
            payload: false
        })
    })
}