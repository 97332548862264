import React from "react";
import './ErrorPage.css';

const ErrorPage = () => {

    return(
        <div className={'error-content'}>
            <div className="row">
                {/*<div className="col">*/}
                {/*    <span />*/}
                {/*</div>*/}
                {/*<div className="col error-msg">*/}
                {/*    <h3 className={'display-4'}>Error 404</h3>*/}
                {/*    <p>Oops, this page doesn't exist!</p>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default ErrorPage;