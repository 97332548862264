import React, {useEffect, useState} from "react";
import {batch, connect, useDispatch} from "react-redux";
import StarCheckbox from "../../utils/Checkbox/StarCheckbox";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
    setSelectedTaskIndex,
    setTaskDeadline,
    setTaskDetails,
    setTaskTitle,
    toggleTaskIsFavorite
} from "../../../redux/actions/userTasks";


const ClientTaskDetailsModal = (props) => {

    const taskIndex = props.userTasks.selectedTaskIndex
    const selectedTask = props.userTasks.taskList[taskIndex] || {}
    const dispatch = useDispatch()
    const timerDelay = 1000

    let timer = null

    const handleDateChange = date => {
        dispatch(setTaskDeadline(taskIndex, date, props.clientId))
    }

    const handleDetailsChange = details => {
        clearTimeout(timer)

        timer = setTimeout(() => dispatch(setTaskDetails(taskIndex, details, props.clientId)), timerDelay)
    }

    const handleTitleChange = title => {
        clearTimeout(timer)

        timer = setTimeout(() => dispatch(setTaskTitle(taskIndex, title, props.clientId)), timerDelay)
    }

    const handleTitleKeyDown = (keyCode, title) => {
        if(keyCode == 13){
            clearTimeout(timer)
            dispatch(setTaskTitle(taskIndex, title, props.clientId))
        }
    }

    return (
        <div id={'task-info-modal'} className={props.isTaskModalVisible ? 'client-home-container visible' : 'client-home-container hidden'}>
            <div className="row">
                <div className="col">
                    <div id={'task-info-fav-checkbox'}>
                        <StarCheckbox key={taskIndex} taskIndex={taskIndex} id={selectedTask.id} isToggled={selectedTask.is_favorite} onClick={() => dispatch(toggleTaskIsFavorite(taskIndex, props.clientId))}/>
                    </div>
                </div>
                <div className="col text-right">
                    <button
                        id={'close-task-info-btn'}
                        onClick={() =>
                            batch(() => {
                                props.setTaskModalVisibility(false)
                                setTimeout(() => dispatch(setSelectedTaskIndex(-1)), 300)
                            })
                        }
                    >
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="md-form">
                        <input
                            type="text"
                            id={'task-info-edit-title'}
                            placeholder={'Title'}
                            key={selectedTask.id}
                            defaultValue={selectedTask.title}
                            onChange={e => handleTitleChange(e.target.value)}
                            onKeyDown={e => handleTitleKeyDown(e.keyCode, e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <div>
                            <DatePicker
                                margin="normal"
                                id={'date-picker-dialog'+selectedTask.id}
                                placeholder={'Deadline'}
                                format="MM/DD/YYYY"
                                style={{width: '100%', 'padding-bottom': '15px'}}
                                value={selectedTask.deadline}
                                clearable
                                onChange={ date => handleDateChange(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <div className="row edit-details-row">
                <div className="col">
                    <textarea
                        className={'form-control'}
                        onChange={e => handleDetailsChange(e.target.value)}
                        key={selectedTask.id}
                        id={'task-info-edit-details'}
                        placeholder={'Details'}
                        defaultValue={selectedTask.details ? selectedTask.details : ""}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userTasks: state.userTasks
    }
}

export default connect(mapStateToProps)(ClientTaskDetailsModal)