import React from 'react';
import './Checkbox.css'
import {useDispatch} from "react-redux";
import {toggleTaskCheckmark} from "../../../redux/actions/userTasks";


export default ({ id, isChecked }) => {

    const dispatch = useDispatch()

    const handleOnClick = () => {
        dispatch(toggleTaskCheckmark(id))
    }

    return (
        <div className="round">
            <button class={'task-toggle-btn' + (isChecked ? ' toggled' : '')} id={'task-' + id} onClick={handleOnClick}/>
            <label htmlFor={'task-' + id}></label>
        </div>
    )
}