import {SET_CLIENT_BUDGET, TOGGLE_IS_BUDGET_LOADING} from "../actions/actionTypes";


const initialState = {
    budgetList: [],
    isBudgetLoading: true
}

const clientBudgetReducer = (state = initialState, action : any) => {
    switch(action.type) {
        case SET_CLIENT_BUDGET:
            return {
                ...state,
                budgetList: action.payload
            }
        case TOGGLE_IS_BUDGET_LOADING:
            return {
                ...state,
                isBudgetLoading: false
            }
        default:
            return state;
    }
}

export default clientBudgetReducer