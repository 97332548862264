import 'bootstrap/dist/css/bootstrap.css';
import "mdbreact/dist/css/mdb.css";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import store from './redux/store'

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const history = createBrowserHistory({ basename: baseUrl })

ReactDOM.render(
    <Provider store={store}>
            <App />
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();
