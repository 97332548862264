import React from 'react';
import {connect} from 'react-redux';
import NavMenu from './NavMenu';
import '../custom.css';

const Layout = props => {

    return (
        <React.Fragment>
            <NavMenu/>
            <div className={'container'} id={'main-layout-container'}>
                {props.children}
            </div>
        </React.Fragment>
    );
}

export default connect()(Layout);