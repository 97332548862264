import React from 'react';


const StarCheckbox = (props) => (
    <div className={'star-container'}>
        <button
            className={'star-btn'}
            onClick={props.onClick}
            id={'star-btn-' + props.id}
            data-toggle={'tooltip'}
            title={'Favorite'}
        >
            {
                props.isToggled ?
                    <i className="fa fa-star fa-lg" aria-hidden="true"></i>
                :
                    <i className="fa fa-star-o fa-lg" aria-hidden="true"></i>
            }
        </button>
    </div>
)

export default StarCheckbox;