import React, {useEffect} from "react";
import {batch, connect, useDispatch} from "react-redux";
import 'animate.css/animate.css'
import {usePlaidLink} from "react-plaid-link";
import Cookies from "js-cookie";
import api from "../../../api/api";
import {setClientSession} from "../../../redux/actions/client";
import {setUserSession} from "../../../redux/actions/user";

declare let $ : any;

// TODO: add fade out left and fade in right effect on next
// TODO: reverse on prev
// TODO: maybe prefer element disappears before end of screen
// TODO: look into keyframes

const nextModal = (current : any, next : any) => {
    current.removeClass('fadeOutRight');
    next.removeClass('fadeInLeft');

    current.addClass('fadeOutLeft');
    next.addClass('fadeInRight');
    next.modal('show');
};

const WelcomeModal = (props : any) =>{
    return (
        <div className="modal animated" id={'welcome-modal'} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop={'false'}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Welcome to LinkUBank!</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus. Et tortor consequat id porta nibh venenatis cras. Ac felis donec et odio pellentesque diam volutpat. Viverra accumsan in nisl nisi scelerisque eu ultrices. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Ac turpis egestas integer eget aliquet nibh praesent tristique magna. Vitae justo eget magna fermentum iaculis eu non. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Sit amet justo donec enim diam vulputate ut. Facilisi nullam vehicula ipsum a arcu cursus. Et sollicitudin ac orci phasellus egestas.
                        </p>
                        <p>
                            Fermentum iaculis eu non diam phasellus vestibulum lorem sed risus. Amet massa vitae tortor condimentum lacinia quis vel. Sed ullamcorper morbi tincidunt ornare massa eget. Faucibus a pellentesque sit amet porttitor eget dolor. Nullam non nisi est sit amet facilisis magna etiam. Scelerisque purus semper eget duis. Quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus. Facilisis magna etiam tempor orci eu lobortis elementum nibh. Rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Feugiat nisl pretium fusce id velit ut tortor.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className={'btn btn-one'} onClick={() => nextModal($('#welcome-modal'), $('#question1'))}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Question1Modal = (props : any) => {
    return (
        <div className="modal animated" id={'question1'} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop={'false'}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Question 1</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus. Et tortor consequat id porta nibh venenatis cras. Ac felis donec et odio pellentesque diam volutpat. Viverra accumsan in nisl nisi scelerisque eu ultrices. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Ac turpis egestas integer eget aliquet nibh praesent tristique magna. Vitae justo eget magna fermentum iaculis eu non. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Sit amet justo donec enim diam vulputate ut. Facilisi nullam vehicula ipsum a arcu cursus. Et sollicitudin ac orci phasellus egestas.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className={'btn btn-one'} onClick={() => nextModal($('#question1'), $('#question2'))}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Question2Modal = (props : any) => {
    return (
        <div className="modal animated" id={'question2'} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop={'false'}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Question 2</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus. Et tortor consequat id porta nibh venenatis cras. Ac felis donec et odio pellentesque diam volutpat. Viverra accumsan in nisl nisi scelerisque eu ultrices. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Ac turpis egestas integer eget aliquet nibh praesent tristique magna. Vitae justo eget magna fermentum iaculis eu non. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Sit amet justo donec enim diam vulputate ut. Facilisi nullam vehicula ipsum a arcu cursus. Et sollicitudin ac orci phasellus egestas.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className={'btn btn-one'} onClick={() => nextModal($('#question2'), $('#question3'))}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

 {

}

const endOnboarding = (dispatch : Function, openPlaidLink? : Function) => {
    api.put('onboarding/',
        {'is_onboarding_active': false},
        {
                headers: {
                    Authorization: 'Token ' + Cookies.get('auth_token'),
                    Cookies: 'user_token=' + Cookies.get('user_token')
                },
                withCredentials: true
            }
    ).then(res => {
        $('#question3').addClass('fadeOutLeft');
        $('.modal-overlay').css('display', 'none');

        if(openPlaidLink) openPlaidLink();
    }).catch(err => {
            // TODO: add error handling
    })
};

const Question3Modal = (props : any) => {

    return (
        <div className="modal animated" id={'question3'} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop={'false'}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Question 3</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus. Et tortor consequat id porta nibh venenatis cras. Ac felis donec et odio pellentesque diam volutpat. Viverra accumsan in nisl nisi scelerisque eu ultrices. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Ac turpis egestas integer eget aliquet nibh praesent tristique magna. Vitae justo eget magna fermentum iaculis eu non. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Sit amet justo donec enim diam vulputate ut. Facilisi nullam vehicula ipsum a arcu cursus. Et sollicitudin ac orci phasellus egestas.
                        </p>
                    </div>
                    <div className="modal-footer">
                        {
                            !props.isAccountLinked ?
                                <button className={'btn btn-one'} onClick={() => {
                                    endOnboarding(props.dispatch, props.openPlaidLink);
                                    setTimeout(() => {$('.modal-overlay').css('display', 'none')}, 500);
                                }}>Link your bank</button>
                                :
                                <button className={'btn btn-one'} onClick={() => endOnboarding(props.dispatch)}>Done</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const ClientOnBoarding = (props : any) => {

    const { isAccountLinked, plaidLinkToken } = props.clientInfo;
    const dispatch = useDispatch();

    useEffect(() => {
        $('#welcome-modal').modal('show');
        $('.modal-overlay').css('display', 'block');
    });

    const config = {
        clientName: 'LinkUBank',
        env: 'sandbox',
        product: ['auth', 'transactions'],
        token: plaidLinkToken,
        onExit: (error : any, metadata : any) => {
            batch(() => {
                dispatch(setClientSession());
                dispatch(setUserSession(true));
            })
        },
        onSuccess: (publicToken: any, metadata: any) => {
            const authToken = Cookies.get('auth_token');
            const data = {
                public_token: publicToken
            };
            api.post('plaid-client/', data,
                {
                    headers: {
                        Authorization: 'Token ' + authToken,
                        Cookies: 'user_token=' + Cookies.get('user_token')
                    },
                    withCredentials: true
                }
            ).then(res => {
                dispatch(setClientSession());
            }).finally(() => dispatch(setUserSession(true)))
        }
    };

    const { open } = usePlaidLink(config);

    return (
        <div id={'client-modals'}>
            <div className={'modal-overlay'} />
            <WelcomeModal isAccountLinked={isAccountLinked}/>
            <Question1Modal/>
            <Question2Modal/>
            <Question3Modal openPlaidLink={open} dispatch={dispatch}/>
        </div>
    )
};

const mapStateToProps = (state : any) => {
    return { clientInfo : state.clientInfo }
};

export default connect(mapStateToProps)(ClientOnBoarding)