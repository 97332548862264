import React, {useState} from 'react';
import {Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {Link, Route} from "react-router-dom";
import {batch, connect, useDispatch} from "react-redux";
import defaultProfilePic from "../../../media/images/default-profile-pic.png";
import formatPhoneNumber from "../../../api/formatPhoneNumber";
import {setAccountInfo} from "../../../redux/actions/collaborator";
import Cookies from 'js-cookie';
import api from "../../../api/api";
import $ from 'jquery';
import {clearUserInfo} from "../../../redux/actions/user";
import './AgentSettings.css';
import Loading from "../../Loading/Loading";

const SettingsNavBar = () => (
    <Navbar id={'account-settings-nav'}
            className="navbar-expand-sm navbar-toggleable-sm border-bottom border-right border-left box-shadow mb-3"
            light>
        <Container>
            <NavbarToggler className="mr-2"/>
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse mt-2 justify-content-center" navbar>
                <ul className="navbar-nav flex-grow">
                    <NavItem>
                        <NavLink tag={Link} className="text-dark"
                                 to={'/agent/settings/change-password'}>Change Password</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark"
                                 to={'/agent/settings/address'}>Address</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark"
                                 to={'/agent/settings/profile'}>Profile</NavLink>
                    </NavItem>
                </ul>
            </Collapse>
        </Container>
    </Navbar>
);

const deformatPhoneNumber = phoneNumber => {
    let number = null;
    if (phoneNumber)
        number = phoneNumber.replace(/\)|\(|-|\s/gi, '');
    return number;
}

const BioComponent = props => {

    if (props.isEditable)
        return (
            <>
                <div className="row">
                    <div className="col content-header">
                        Bio
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <textarea className={'form-control'} rows={8} id={'change-bio'}>{props.bio}</textarea>
                    </div>
                </div>
            </>
        )
    else
        return (
            <>
                <div className="row">
                    <div className="col content-header">
                        Bio
                    </div>
                </div>
                <div className="row">
                    <div className="col">{props.bio}</div>
                </div>
            </>
        )
}

const emailChecker = (emailField) => {
    const pattern = /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    const email = emailField.val();
    const isValid = pattern.test(email)

    if (!isValid) {
        return ['Invalid email format', false]
    } else if (!email) {
        return ['Valid email must be provided', false]
    }

    return['',true]
}

const ButtonGroup = props => {

    if (!props.isEditable)
        return (
            <div className="col text-right">
                <button className="btn settings-btn" id={'edit-btn'} onClick={props.handleOnEdit}
                        data-toggle={'tooltip'} title={'Edit'}>
                    <i className="fa fa-pencil fa-lg" aria-hidden="true"></i>
                </button>
            </div>
        )
    else
        return (
            <div className="col text-right">
                <button className="btn settings-btn" id={'save-btn'} onClick={props.handleOnSave}
                        data-toggle={'tooltip'} title={'Save'}>
                    <i className="fa fa-floppy-o fa-lg" aria-hidden="true"></i>
                </button>
                <button className="btn settings-btn" id={'discard-btn'} onClick={props.handleOnDiscard}
                        data-toggle={'tooltip'} title={'Discard'}>
                    <i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                </button>
            </div>
        )
}

const ProfilePicture = props => {

    const {profilePicUrl, setProfilePicUrl, setProfilePic} = props;

    const handleChange = e => {
        if (e.target.files && e.target.files[0])
            batch(() => {
                setProfilePicUrl(URL.createObjectURL(e.target.files[0]));
                setProfilePic(e.target.files[0])
            })
    }

    if (!props.isEditable)
        return (
            <img
                src={profilePicUrl}
                alt="Profile pic"
                className={'img-thumbnail'}
                id={'profile-pic'}
            />
        )
    else
        return (
            <div>
                <img
                    src={profilePicUrl}
                    alt="Profile pic"
                    className={'img-thumbnail'}
                    id={'profile-pic'}
                />
                <div className="custom-file">
                    <input
                        type="file"
                        id="settings-profile-pic-input"
                        name={'settings-profile-pic-input'}
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleChange}
                    />
                    <label htmlFor="settings-profile-pic-input">Update profile</label>
                </div>
            </div>
        )
}

const ProfessionInput = props => (
    <div className="row item-row" key={props.item.id}>
        <div className="col p-0 mr-2">
            <input
                type={'text'}
                className={'form-control'}
                defaultValue={props.item.value}
                onChange={(e) => props.handleChange(props.item, e.target.value)}
            />
        </div>
        <div className="col-1">
            <button className={'btn'} id={'add-item-btn'} onClick={props.addItem}><i className="fa fa-plus fa-lg"
                                                                                     aria-hidden="true"></i></button>
        </div>
        <div className="col-1">
            {
                props.professions.length > 1 ?
                    <button className={'btn'} id={'remove-item-btn'} onClick={() => props.removeItem(props.item)}><i
                        className="fa fa-times fa-lg" aria-hidden="true"></i></button>
                    : null
            }
        </div>
    </div>
)

const ProfessionItems = props => {

    let {professionsState, setProfessionsState, isEditable} = props;
    let professions = professionsState.professions;

    const handleChange = (item, value) => {
        setProfessionsState({
            nextId: professionsState.nextId,
            professions: professions.map(profession =>{
                if(profession.id == item.id)
                    return{
                        id: item.id,
                        value
                    }
                return{
                    id: profession.id,
                    value: profession.value
                }
            })
        })
    }

    const addItem = () => {
        setProfessionsState({
            nextId: professionsState.nextId + 1,
            professions: [...professions, {id: professionsState.nextId, value: ''}]
        })
    }

    const removeItem = (item) => {
        setProfessionsState({
            nextId: professionsState.nextId,
            professions: [...professions.filter(profession => profession.id !== item.id)]
        })
    }

    if (isEditable)
        return professions.map(item => (
            <ProfessionInput item={item} professions={professions} addItem={addItem} removeItem={removeItem} handleChange={handleChange} />
        ));

    return professions.map(item => (
        <div className="row">
            <div className="col">{item.value}</div>
        </div>
    ))
}

const ProfessionsList = props => (
    <div>
        <div className="row">
            <div className="col content-header">
                Professions
            </div>
        </div>
        <ProfessionItems professionsState={props.professionsState} setProfessionsState={props.setProfessionsState} isEditable={props.isEditable}/>
    </div>
);

const AgentInfo = props => {

    if (props.isEditable)
        return (
            <div id={'contact-info-section'} className={'container'}>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col content-header">
                                Contact Information
                            </div>
                        </div>
                        <div className="row align-items-end md-form">
                            <div className="col-3 mb-2">
                                <span className={''}>Email</span>
                            </div>
                            <div className="col">
                                <input type={'text'} id={'change-email'} className={'form-control'}
                                       defaultValue={props.email}/>
                            </div>
                        </div>
                        <div className="row align-items-end md-form">
                            <div className="col-3 mb-2">
                                Phone
                            </div>
                            <div className="col">
                                {/* TODO: check if phone number is 10 digits on submit */}
                                <input type={'text'} id={'change-phone'} maxLength={10} className={'form-control'}
                                       defaultValue={deformatPhoneNumber(props.phoneNumber)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <ProfessionsList professionsState={props.professionsState} setProfessionsState={props.setProfessionsState} isEditable={props.isEditable}/>
                    </div>
                </div>
            </div>
        )
    else
        return (
            <div id={'contact-info-section'}>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col content-header">
                                Contact Information
                            </div>
                        </div>
                        <div className="row md-form">
                            <div className="col">
                                Email
                            </div>
                            <div className="col">
                                {props.email}
                            </div>
                        </div>
                        <div className="row md-form">
                            <div className="col">
                                Phone
                            </div>
                            <div className="col">
                                {props.phoneNumber}
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <ProfessionsList professionsState={props.professionsState} setProfessionsState={props.setProfessionsState} isEditable={props.isEditable}/>
                    </div>
                </div>
            </div>
        )
}

const AgentProfileSettings = props => {

    const {firstName, lastName, email, phoneNumber, bio, profile, professions} = props.collaboratorInfo
    const [isEditable, setIsEditable] = useState(false);

    const [profilePicUrl, setProfilePicUrl] = useState(profile ? profile : defaultProfilePic);
    const [profilePic, setProfilePic] = useState(profilePicUrl)
    const initialState = {
        nextId: professions && professions.length > 0 ? professions.length : 1,
        professions: professions && professions.length > 0 ?
            professions.map((profession, index) => (
                {
                    id: index,
                    value: profession
                }
            ))
            : [{id: 0, value: ''}]
    }
    const [professionsState, setProfessionsState] = useState(initialState);

    console.log(professionsState);

    const handleOnEdit = () => {
        if (!isEditable)
            setIsEditable(!isEditable);
    }

    const handleOnSave = async () => {

        let notifications = [];

        const emailValidation = emailChecker($('#change-email'), 'agent')

        if ($('#change-email').val().trim() !== email && emailValidation && !emailValidation[1]) {

            $('#change-email').addClass('invalid');
            notifications.push({
                title: 'Invalid Email',
                body: emailValidation[0]
            })
        } else {
            $('#change-email').removeClass('invalid');
        }

        if ($('#change-phone').val().trim().length !== 10 && $('#change-phone').val().trim()) {
            $('#change-phone').addClass('invalid');
            notifications.push({
                title: 'Invalid Phone Number',
                body: 'Phone number should only contain ten numbers'
            })
        } else {
            $('#change-phone').removeClass('invalid');
        }

        if (notifications.length > 0) {
            props.createNotifications('error', notifications);
        } else {
            const phoneNumber = formatPhoneNumber($('#change-phone').val().trim());

            const professionsList = professionsState.professions.map(item => {
                if(item.value && item.value.trim() !== '')
                    return item.value
            })

            const formData = new FormData();
            formData.append('email', $('#change-email').val().trim());
            formData.append('phone_number', phoneNumber ? phoneNumber : '');
            formData.append('biography', $('#change-bio').val().trim());

            if (profilePic !== profile && profilePicUrl !== defaultProfilePic)
                formData.append('profile', profilePic);

            const professionData ={
                professions: professionsList.filter(profession => profession && profession.trim() !== '')
            }

            api.put('agents/profile/', formData, {
                headers: {
                    Authorization: 'Token ' + Cookies.get('auth_token'),
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            }).then(res => {
                api.put('agents/professions/', professionData, {
                    headers: {
                        Authorization: 'Token ' + Cookies.get('auth_token')
                    },
                    withCredentials: true
                }).then(() => {
                    batch(() => {
                        props.createNotifications('success', [{
                            body: '',
                            title: res.data['details']
                        }])
                        props.dispatch(setAccountInfo(props.setIsLoading, 'agent'));
                        setIsEditable(false);
                    })
                })
            //    TODO: handle errors
            }).catch(err => {
                // TODO: update to handle errors other than existing email

                $('#change-email').addClass('invalid');

                props.createNotifications('error', [{
                    title: 'Invalid Email',
                    body: 'Email matches another email on an existing account'
                }]);
            })

        }
    }

    const handleOnDiscard = () => {
        if (isEditable) {
            setProfilePicUrl(profile ? profile : defaultProfilePic)
            setIsEditable(!isEditable);
        }
        setProfessionsState(initialState);
    }

    return (
        <div className={'settings-content border'}>
            <div className="row">
                <ButtonGroup
                    handleOnEdit={handleOnEdit}
                    handleOnSave={handleOnSave}
                    handleOnDiscard={handleOnDiscard}
                    isEditable={isEditable}
                />
            </div>
            <div className={'row'}>
                <div className="col-md-4 text-center">
                    <div className="row">
                        <div className="col">
                            <span id={'profile-name'}>{firstName + " " + lastName}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ProfilePicture profilePicUrl={profilePicUrl} setProfilePicUrl={setProfilePicUrl}
                                            isEditable={isEditable} setProfilePic={setProfilePic}/>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <AgentInfo isEditable={isEditable} email={email} phoneNumber={phoneNumber}
                               professionsState={professionsState} setProfessionsState={setProfessionsState}/>
                    <BioComponent isEditable={isEditable} bio={bio}/>
                </div>
            </div>
        </div>
    )
}

const ChangePassword = props => {

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const submitPasswordChange = () => {

        const data = {
            current_password: currentPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
        }

        api.put('change-password/', data, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(() => {
                props.createNotifications('success', [{
                    body: '',
                    title: res.data['details']
                }])
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            })
        }).catch(err => {
            props.createNotifications('error', Object.keys(err.response.data).map(key => {
                return {
                    title: 'Error Occurred',
                    body: err.response.data[key]
                }
            }))
        })
    }

    return (
        <div className={'settings-content border'}>
            <h4>Change Password</h4>
            <div className={'general-settings-body'}>
                <table>
                    <tbody className={'md-form'}>
                    <tr>
                        <td><span>Current Password:</span></td>
                        <td><input type={'password'} className={'form-control'}
                                   onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword}/></td>
                    </tr>
                    <tr>
                        <td><span>New Password:</span></td>
                        <td><input type={'password'} className={'form-control'}
                                   onChange={(e) => setNewPassword(e.target.value)} value={newPassword}/></td>
                    </tr>
                    <tr>
                        <td><span>Confirm Password:</span></td>
                        <td><input type={'password'} className={'form-control'}
                                   onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword}/></td>
                    </tr>
                    <tr>
                        <td className={'text-center pt-4'} colSpan={2}>
                            <button className={'btn btn-success w-100'} onClick={submitPasswordChange}>Save</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const AddressSettings = props => {

    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [addressType, setAddressType] = useState('');
    const [addressDetails, setAddressDetails] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const stateCodeArr = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    if (isLoading) {
        api.get('agents/address/', {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            batch(() => {
                setStreetAddress(res.data['street_address']);
                setCity(res.data['city']);
                setStateCode(res.data['state_code']);
                setZipCode(res.data['zip_code']);
                setAddressType(res.data['address_type'])
                setAddressDetails(res.data['address_type_details'])
            })
        }).then(() => {
            setIsLoading(false);
        })

        return <Loading />
    }

    const handleSubmit = () => {
        const data = {
            street_address: streetAddress,
            city,
            state_code: stateCode,
            zip_code: zipCode,
            address_type: addressType,
            address_type_details: addressType === 'Other' ? addressDetails : ''
        }
        api.put('agents/address/', data, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).then(res => {
            props.createNotifications('success', [{
                body: '',
                title: res.data['details']
            }])
        }).catch(err => {
            props.createNotifications('error', Object.keys(err.response.data).map(key => {
                return {
                    title: 'Error Occurred',
                    body: err.response.data[key]
                }
            }));
        });
    }

    return (
        <div className={'settings-content border'}>
            <h4>Address</h4>
            <div className={'general-settings-body md-form'}>
                <div className="row">
                    <div className="col col-md-2">
                        Street Address:
                    </div>
                    <div className="col">
                        <input type="text" className={'form-control'} defaultValue={streetAddress}
                               onChange={e => setStreetAddress(e.target.value)}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="row">
                            <div className="col col-md-2">
                                City:
                            </div>
                            <div className="col">
                                <input type="text" className={'form-control'} defaultValue={city}
                                       onChange={e => setCity(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="row">
                            <div className="col col-md-2">
                                State:
                            </div>
                            <div className="col">
                                <select id="state-code" className="browser-default custom-select"
                                        onChange={e => setStateCode(e.target.value)}>
                                    <option value="default" disabled>Choose one</option>
                                    {
                                        stateCodeArr.map((value) => {
                                            if (stateCode === value)
                                                return <option value={value} selected>{value}</option>
                                            return <option value={value}>{value}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="row">
                            <div className="col col-md-4">
                                Zip Code:
                            </div>
                            <div className="col">
                                <input type="text" className={'form-control'} defaultValue={zipCode}
                                       onChange={e => setZipCode(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-success w-100" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AgentSettings = props => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    if (isLoading) {
        dispatch(setAccountInfo(setIsLoading, 'agent'));

        return (
            <div>
                <SettingsNavBar/>
                <Loading />
            </div>
        )
    }

    if(Cookies.get('user_token') && Cookies.get('auth_token'))
        return (
            <div>
                <SettingsNavBar />
                <Route path={'/agent/settings/profile'}
                       render={() =>
                           <AgentProfileSettings dispatch={dispatch}
                                                 collaboratorInfo={props.collaboratorInfo}
                                                 setIsLoading={setIsLoading}
                                                 professions={props.userInfo.professions}
                                                 createNotifications={props.createNotifications}/>
                       }/>

                <Route path={'/agent/settings/change-password'}
                       render={() => <ChangePassword dispatch={dispatch} createNotifications={props.createNotifications}/>}/>
                <Route path={'/agent/settings/address'}
                       render={() => <AddressSettings dispatch={dispatch}
                                                      createNotifications={props.createNotifications}/>}/>
            </div>
        )
    else {
        Cookies.remove('user_token');
        Cookies.remove('auth_token');
        dispatch(clearUserInfo());

        window.location.href = '/';
        return null;
    }
};

const mapStateToProps = state => {
    return {
        collaboratorInfo: state.collaboratorInfo,
        userInfo: state.userInfo,
    }
}

export default connect(mapStateToProps)(AgentSettings);