import React, {useEffect, useState} from "react";
import {batch, connect, useDispatch} from "react-redux";
import 'animate.css/animate.css'
import Cookies from "js-cookie";
import api from "../../api/api";
import './Consultant.css';
import defaultProfilePic from '../../media/images/default-profile-pic.png';
import formatPhoneNumber from "../../api/formatPhoneNumber";
import $ from 'jquery';
import {disableOnBoarding, setUserSession} from "../../redux/actions/user";

// TODO: add fade out left and fade in right effect on next
// TODO: reverse on prev
// TODO: maybe prefer element disappears before end of screen
// TODO: look into keyframes

const nextModal = (current, next) => {
    current.removeClass('fadeOutRight');
    next.removeClass('fadeInLeft');

    current.addClass('fadeOutLeft');
    next.addClass('fadeInRight');
    next.modal('show');
};

const WelcomeModal = props =>{
    return (
        <div className="modal animated" id={'welcome-modal'} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop={'false'}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Welcome to LinkUBank!</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus. Et tortor consequat id porta nibh venenatis cras. Ac felis donec et odio pellentesque diam volutpat. Viverra accumsan in nisl nisi scelerisque eu ultrices. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Ac turpis egestas integer eget aliquet nibh praesent tristique magna. Vitae justo eget magna fermentum iaculis eu non. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Sit amet justo donec enim diam vulputate ut. Facilisi nullam vehicula ipsum a arcu cursus. Et sollicitudin ac orci phasellus egestas.
                        </p>
                        <p>
                            Fermentum iaculis eu non diam phasellus vestibulum lorem sed risus. Amet massa vitae tortor condimentum lacinia quis vel. Sed ullamcorper morbi tincidunt ornare massa eget. Faucibus a pellentesque sit amet porttitor eget dolor. Nullam non nisi est sit amet facilisis magna etiam. Scelerisque purus semper eget duis. Quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus. Facilisis magna etiam tempor orci eu lobortis elementum nibh. Rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Feugiat nisl pretium fusce id velit ut tortor.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className={'btn btn-one'} onClick={() => nextModal(window.$('#welcome-modal'), window.$('#change-password-modal'))}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const passwordsMeetCriteria = (passwordField, confPasswordField) => {
    const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()/\\\-_=+[\](){}<>?;:'"|`~]{6,}$/i;
    return pattern.test(passwordField) && pattern.test(confPasswordField);
}

const validatePassword = (password, confirmPassword) => {

    if(password !== confirmPassword) {
        window.$('#password').addClass('invalid');
        window.$('#confirm-password').addClass('invalid');

        return false;
    } else if(!passwordsMeetCriteria(password, confirmPassword)) {
        window.$('#password').addClass('invalid');
        window.$('#confirm-password').addClass('invalid');

        return false;
    }

    window.$('#password').addClass('valid');
    window.$('#confirm-password').addClass('valid');

    return true
}

const ChangePasswordModal = props => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMsg, setPasswordMsg] = useState();
    const [isSixChars, setIsSixChars] = useState(false);
    const [hasChar, setHasChar] = useState(false);
    const [hasNum, setHasNum] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    const handleNext = () => {
        const passwordStatus = validatePassword(password, confirmPassword);
        if(passwordStatus){
            window.$('#password').removeClass('invalid');
            window.$('#confirm-password').removeClass('invalid');

            api.put('/consultants/update-password/', {
                password,
                confirm_password: confirmPassword
            },{
                headers: {
                    Authorization: 'Token ' + Cookies.get('auth_token')
                },
                withCredentials: true
            }).then(res => {
                nextModal(window.$('#change-password-modal'), window.$('#profile-modal'));
            }).catch(err => {
            //    update to show notification on error
                console.log(err.response.data);
            })

        }
    }

    useEffect(() => {
        setIsSixChars(password.length >= 6);
        setHasChar(/[A-Za-z]+/i.test(password));
        setHasNum(/[0-9]+/i.test(password));
        setPasswordsMatch(password !== '' && confirmPassword !== '' && password === confirmPassword);
    })

    return (
        <div className="modal animated" id={'change-password-modal'} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop={'false'}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Change Password</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            <div className="row">
                                <div className="col">
                                    First things first, lets update your password<br/>
                                    <small>Upon clicking Next your password will be changed</small>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row password-row">
                                        <div className="col">
                                            <div className={'md-form'}>
                                                <input type="password" id={'password'} className="form-control" defaultValue={password}
                                                       onChange={e => setPassword(e.target.value)}
                                                />
                                                <label htmlFor={'password'}>Password</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row password-row">
                                        <div className="col">
                                            <div className="md-form">
                                                <input type="password" id={'confirm-password'} className="form-control" defaultValue={confirmPassword}
                                                       onChange={e => setConfirmPassword(e.target.value)}
                                                />
                                                <label htmlFor={'confirm-password'} data-error={''}>Confirm Password</label>
                                                <div className={'col'}>
                                                    <small className={'error-msg'}>{passwordMsg}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 criteria-col">
                                    <ul className={'criteria-list'}>
                                        <li className={isSixChars ? 'valid' : 'invalid'}><small>Password is at least 6 characters/digits</small></li>
                                        <li className={hasChar ? 'valid' : 'invalid'}><small>Includes atleast one character</small></li>
                                        <li className={hasNum ? 'valid' : 'invalid'}><small>Includes atleast one number</small></li>
                                        <li className={passwordsMatch ? 'valid' : 'invalid'}><small>Passwords match</small></li>
                                    </ul>
                                </div>
                            </div>
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className={'btn btn-one'} onClick={handleNext}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProfileModal = props => {
    // const {profilePicUrl, setProfilePicUrl, setProfilePic} = props;

    const [profilePicUrl, setProfilePicUrl] = useState(defaultProfilePic)
    const [profilePic, setProfilePic] = useState(profilePicUrl)
    const [phoneNumber, setPhoneNumber] = useState()
    const [bio, setBio] = useState('');

    const handleChange = e => {
        if (e.target.files && e.target.files[0])
            batch(() => {
                setProfilePicUrl(URL.createObjectURL(e.target.files[0]));
                setProfilePic(e.target.files[0])
            })
    }

    const handleNext = () => {

        const formData = new FormData();

        if(phoneNumber)
            formData.append('phone_number', formatPhoneNumber(phoneNumber));

        if(bio)
            formData.append('biography', bio);

        if(profilePic && profilePic !== defaultProfilePic)
            formData.append('profile', profilePic)

        api.put('consultants/profile/', formData, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token'),
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        }).then(res => {
            nextModal(window.$('#profile-modal'), window.$('#end-modal'));
        }).catch(err => {
            props.createNotifications('error', Object.keys(err.response.data).map(key => {
                return {
                    title: 'Error Occurred',
                    body: err.response.data[key]
                }
            }));
        })
    }

    return (
        <div className="modal animated" id={'profile-modal'} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop={'false'}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Update Profile</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            <div className="row">
                                <div className="col">
                                    Lets update your profile a bit <br/>
                                    <small>(This can be changed later)</small>
                                </div>
                            </div>
                            <div className="row profile-content">
                                <div className="col-md-5">
                                    <div>
                                        <img
                                            src={profilePicUrl}
                                            alt="Profile pic"
                                            className={'img-thumbnail'}
                                            id={'profile-pic'}
                                        />
                                        <div>
                                            <input
                                                type="file"
                                                id="profile-pic-input"
                                                name={'profile-pic-input'}
                                                accept="image/png, image/jpeg, image/jpg"
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="profile-pic-input">Update profile</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-5">Email</div>
                                        <div className="col">{props.userInfo.email}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-10">
                                            <div className="md-form">
                                                <input type={'text'} id={'phone-number'} className="form-control"
                                                        maxLength={10} onChange={e => setPhoneNumber(e.target.value)}/>
                                                <label htmlFor={'phone-number'}>Phone Number</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    Bio
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <textarea className={'form-control'}
                                                              rows={8}
                                                              id={'change-bio'}
                                                              onChange={e => setBio(e.target.value)}
                                                    >{props.bio}</textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className={'btn btn-one'} onClick={() => handleNext()}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const endOnboarding = (createNotifications, dispatch) => {
    api.put('onboarding/',
        {'is_onboarding_active': false},
        {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token'),
                Cookies: 'user_token=' + Cookies.get('user_token')
            },
            withCredentials: true
        }
    ).then(res => {
        batch(() => {
            dispatch(disableOnBoarding());
            dispatch(setUserSession());
        })
        window.$('#end-modal').addClass('fadeOutLeft');
        window.$('.modal-overlay').css('display', 'none')
    }).catch(err => {
        createNotifications('error', Object.keys(err.response.data).map(key => {
            return {
                title: 'Error',
                body: err.response.data[key]
            }
        }));
    })
};

const EndModal = props => (
    <div className="modal animated" id={'end-modal'} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop={'false'}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>You're all set!</h2>
                </div>
                <div className="modal-body">
                    <p>
                        Now that you have set your password and taken a look at your profile you can use
                        LinkUBank and start linking with clients. Below provides a brief overview of each button
                        on the navigation bar:
                        <ul>
                            <li>
                                <strong>Home</strong> - The hope page lets you see all of you the clients that
                                you are linked with. From here you can plan their budgets, view client profiles,
                                or link up with new clients.
                            </li>
                            <li>
                                <strong>Calendar</strong> - The calendar lets you view all of your events,
                                schedule new events with linked clients, and modify the events you organized.
                            </li>
                            <li>
                                <strong>Settings</strong> - The settings page lets you change your password,
                                update your profile, and update your address.
                            </li>
                        </ul>
                    </p>
                </div>
                <div className="modal-footer">
                    <button className={'btn btn-one'} onClick={() => endOnboarding(props.createNotifications, props.dispatch)}>Close</button>
                </div>
            </div>
        </div>
    </div>
);

const ConsultantOnBoarding = props => {

    const dispatch = useDispatch();

    useEffect(() => {
        window.$('#welcome-modal').modal('show');
        $('.modal-overlay').css('display', 'block');
    });

    return (
        <div id={'client-modals'}>
            <div className={'modal-overlay'} />
            <WelcomeModal />
            <ChangePasswordModal/>
            <ProfileModal userInfo={props.userInfo} createNotifications={props.createNotifications}/>
            <EndModal createNotifications={props.createNotifications} dispatch={dispatch}/>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        collaboratorInfo : state.collaboratorInfo,
        userInfo: state.userInfo
    }
};

export default connect(mapStateToProps)(ConsultantOnBoarding)