import moment, {Moment} from "moment";
import {
    setActiveEventById,
    setAttendeesList,
    setEditableFromDate,
    setEditableToDate,
    setEventsList,
    setIsEditModeToggled
} from "../../../redux/actions/event";
import {batch, connect, useDispatch} from "react-redux";
import React from "react";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Cookies from 'js-cookie';
import "./ConsultantCalendar.css";
import api from "../../../api/api";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from "@material-ui/core";
import Loading from "../../Loading/Loading";

declare var $: any;

// TODO: event not updating to changes when changing from all day to timeframe
// TODO: update code so that edit mode is displayed as its own component and not jquery changing display
//       jquery changing design is bad practice

const toggleEditMode = (activeEvent: any, dispatch: any, isEditModeToggled?: boolean) => {
    if (!isEditModeToggled) {
        dispatch(setIsEditModeToggled());

        $('#event-details-textarea').val(activeEvent.details);
        $('#event-details').css('display', 'none');
        $('#edit-event-details').css('display', 'block');

        if (activeEvent.allDay)
            $('#all-day-details-box').prop('checked', true);
        else
            $('#all-day-details-box').prop('checked', false);

        $('#all-day-details-row').css('display', 'none');
        $('#edit-all-day-details-row').css('display', 'block');

        $('.date-col').css('display', 'none');
        $('.edit-date-col').css('display', 'block');
        toggleDetailsAllDay();

        $('#edit-event-title').val(activeEvent.title);
        $('#event-title').css('display', 'none');
        $('#edit-event-title').css('display', 'block');

        $('#details-btn-group').css('display', 'none');
        $('#edit-details-btn-group').css('display', 'flex');

        $('#attendee-list').css('display', 'none');
        $('#editable-attendee-list').css('display', 'block');

    } else {
        dispatch(setIsEditModeToggled());

        $('#edit-event-details').css('display', 'none');
        $('#event-details').css('display', 'block');

        $('#edit-all-day-details-row').css('display', 'none');
        if (activeEvent.allDay)
            $('#all-day-details-row').css('display', 'block');

        $('.date-col').css('display', 'block');
        $('.edit-date-col').css('display', 'none');

        $('#edit-event-title').css('display', 'none');
        $('#event-title').css('display', 'block');

        $('#edit-details-btn-group').css('display', 'none');
        $('#details-btn-group').css('display', 'flex');

        $('#editable-attendee-list').css('display', 'none');
        $('#attendee-list').css('display', 'block');
    }
};

const toggleDetailsAllDay = () => {
    if ($('#all-day-details-box').prop('checked') === true) {
        $('.details-date-time-container').css('display', 'none');
        $('.details-date-container').css('display', 'block');
    } else {
        $('.details-date-time-container').css('display', 'block');
        $('.details-date-container').css('display', 'none');
    }
};

const areAttendeesChanged = (initialAttendees : any[] | null, activeEvent : any) => {
    //1. checks if one is null and the other is a list
    //2. null checks then compares lengths
    //3. if the lengths are equal, then check that they aren't 0
    let areAttendeesChanged = typeof(initialAttendees) !== typeof(activeEvent.attendees) ||
        (initialAttendees && activeEvent.attendees && initialAttendees.length !== activeEvent.attendees.length);

    if(areAttendeesChanged) return true;

    let initialAttendeesIds = []
    if(initialAttendees && initialAttendees)
         initialAttendeesIds = initialAttendees.map((attendee : any) => attendee.user_id);

    // checks if each of the attendees is in the initial list of attendees
    let i = 0;
    for(; i < activeEvent.attendees.length; i++){
        if(!initialAttendeesIds.includes(activeEvent.attendees[i].user_id))
            return true;
    }

    return false;
}

const hasChanges = (activeEvent: any, initialAttendees : any[] | null, fromDate?: Moment | null, toDate?: Moment | null) => {
    if (!activeEvent) return true;

    if (!fromDate) return false;

    if (!toDate) return false;

    let areDatesChanged: boolean;

    if ($('#all-day-details-box').prop('checked'))
        areDatesChanged = moment(activeEvent.start).format('YYYYMMDD') === fromDate.format('YYYYMMDD') &&
            moment(activeEvent.end).format('YYYYMMDD') === toDate.format('YYYYMMDD');
    else {
        areDatesChanged = moment(activeEvent.start).format('YYYYMMDDHHmmss') === fromDate.format('YYYYMMDDHHmmss') &&
            moment(activeEvent.end).format('YYYYMMDDHHmmss') === toDate.format('YYYYMMDDHHmmss');
    }

    return !(activeEvent.title === $('#edit-event-title').val().trim() &&
        activeEvent.allDay === $('#all-day-details-box').prop('checked') &&
        areDatesChanged && activeEvent.details === $('#event-details-textarea').val().trim() &&
        !areAttendeesChanged(initialAttendees, activeEvent));
};

// This function triggers upon clicking close and checks if the user has any changes made to their currently viewed
// event the responds accordingly
const toggleChangesCheck = (isEditModeToggled : boolean, initialAttendees : any[] | null, activeEvent?: any, fromDate?: Moment | null,
                                 toDate?: Moment | null, dispatch? : Function) => {

    // TODO: returns that changes have occurred no matter
    if(!isEditModeToggled) { // if the user is not editing then just close the modal
        $('#event-details-modal').modal('hide');
    } // if the user does not have changes then toggle back to view mode
    else if ($('#event-details-modal').is(':visible') && dispatch && !hasChanges(activeEvent, initialAttendees, fromDate, toDate)) {

        toggleEditMode(activeEvent, dispatch, isEditModeToggled);
    } // this is only be reached if the user has changes, if so show prompt and close event view modal
    else if ($('#event-details-modal').is(':visible')) {
        $('#event-details-modal').modal('hide');
        $('#discard-changes-modal').modal('show');
    } // cancel was clicked so hide the save changes prompt and show the current event in edit mode
    else {
        $('#discard-changes-modal').modal('hide');
        $('#event-details-modal').modal('show');
    }
};

const deleteChanges = (activeEvent: any, dispatch: Function, isEditModeToggled: boolean) => {
    toggleEditMode(activeEvent, dispatch, isEditModeToggled);
    $('#discard-changes-modal').modal('hide');
};

export const DiscardChangesModal = (props: any) => {

    const dispatch = useDispatch();

    const fromDate = props.eventsInfo.editableFromDate;
    const toDate = props.eventsInfo.editableToDate;
    const {isEditModeToggled, activeEvent, eventsList, initialAttendees} = props.eventsInfo;

    return (
        <div className="modal fade" id="discard-changes-modal" tabIndex={-2} role="dialog" aria-hidden="true"
             data-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Save Changes?</h4>
                        <button type="button" className="close" aria-label="Close" onClick={()=>toggleChangesCheck(isEditModeToggled, initialAttendees)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body container-fluid border-bottom">
                        Any changes you made will not be saved. Are you sure you want to discard your changes?
                    </div>
                    <div className={'modal-footer d-flex justify-content-center'}>
                        <button className={'btn btn-two'}
                                onClick={() => deleteChanges(activeEvent, dispatch, isEditModeToggled)}>Discard
                        </button>
                        <button className={'btn btn-two'} onClick={()=>toggleChangesCheck(isEditModeToggled, initialAttendees)}>Cancel</button>
                        <button className={'btn btn-one'} onClick={()=>saveEventChanges(activeEvent, dispatch, isEditModeToggled, fromDate, toDate, eventsList, initialAttendees)}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

const isObjEmpty = (obj: object) => {
    return obj && Object.keys(obj).length === 0;
};

const getUpdatedEventsObj = (activeEvent: any, fromDate: Moment, toDate: Moment, initialAttendees : any[] | null) => {
    if (!hasChanges(activeEvent, initialAttendees, fromDate, toDate))
        return null; // no changes made

    let changesObject: any = {};

    if (activeEvent.title !== $('#edit-event-title').val().trim())
        changesObject.title = $('#edit-event-title').val().trim();

    changesObject.is_all_day = $('#all-day-details-box').prop('checked');

    const timezoneOffset = fromDate.toDate().getTimezoneOffset();
    const millisOffset = timezoneOffset * 60 * 1000;
    changesObject.start_date = fromDate.toDate().getTime() + millisOffset;
    changesObject.end_date = toDate.toDate().getTime() + millisOffset;

    if (activeEvent.details !== $('#event-details-textarea').val().trim())
        changesObject.details = $('#event-details-textarea').val().trim();

    changesObject.organizer = Cookies.get('user_token');
    changesObject.id = activeEvent.id;
    changesObject.attendees = activeEvent.attendees;

    return changesObject;
};

export const isNewEventValid = (event: any, activeEvent: any) => {

    if (moment(event.end_date).isBefore(moment(event.start_date))) {
        return false;
    }

    if(event.title === activeEvent.title)
        return false;

    if(event.details === activeEvent.details)
        return false;

    return true;
};

//TODO: here the events list and active event are set but the active event isn't updated when setting the text
// of the html using the jquery below
const updateEvents = async (dispatch : Function, response : any) => {
    let updatedEvent : any = await new Promise((resolve, reject) => {
        dispatch(setEventsList(response.id, resolve))
    });

    if (updatedEvent.allDay) {
        $('#event-from-date').text(moment(updatedEvent.start).format('MMM Do YYYY'));
        $('#event-to-date').text(moment(updatedEvent.end).format('MMM Do YYYY'));

        $('#all-day-details-row').css('display', 'block');
    } else {
        $('#event-from-date').text(moment(updatedEvent.start).format('MMM Do YYYY, h:mm a'));
        $('#event-to-date').text(moment(updatedEvent.end).format('MMM Do YYYY, h:mm a'));

        $('#all-day-details-row').css('display', 'none');
    }

    $('#event-title').text(updatedEvent.title);
    $('#event-details').text(updatedEvent.details);
};

const saveEventChanges = (activeEvent: any, dispatch : Function, isEditModeToggled : boolean, fromDate: Moment | null, toDate: Moment | null, eventsList : any, initialAttendees : any[] | null) => {

    // TODO: display errors, listed below
    if (!(fromDate && toDate)) {
        return; // invalid date field
    }

    // check if changes made
    let changesObj = getUpdatedEventsObj(activeEvent, fromDate, toDate, initialAttendees);

    if (isObjEmpty(changesObj) || changesObj === null) {
        return; // null or empty object
    }

    // validate changes
    if (!isNewEventValid(changesObj, activeEvent)) {
        return; // invalid fields in event
    }

    // only put changed fields
    api.put('events/', changesObj, {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        // TODO: output success
        // setEventModalContentById(dispatch, res.data.id, eventsList);
        updateEvents(dispatch, res.data);

        $('.notification-modal-header').text('Success');
        $('.notification-modal-body').text('Event successfully updated');
        $('.notification-modal').modal({backdrop: false, show: true});
        setTimeout(() => {$('.notification-modal').modal('hide')}, 7000);

        // return to updated active event
        if($('#discard-changes-modal').is(':visible')){
            $('#discard-changes-modal').modal('hide');
            $('#event-details-modal').modal('show');
        }
    }).catch(err => {
        $('.notification-modal-header').text('Error');
        $('.notification-modal-body').text(err.response.data['details']);
        $('.notification-modal').modal({backdrop: false, show: true});
        setTimeout(() => {$('.notification-modal').modal('hide')}, 7000);
    }).then(() => {toggleEditMode(activeEvent, dispatch, isEditModeToggled)})
};

const NotificationsModal = () => (
    <div className="notification-modal fade" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-xs" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <span className={'modal-title notification-modal-header'}>Success</span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body notification-modal-body">
                    Event successfully updated
                </div>
            </div>
        </div>
    </div>
);

export const setEventModalContentById = (dispatch : Function, eventId : number, eventsList : any) => {
    const event = eventsList.find( (event : any) => event.id === eventId);

    // TODO: handle gracefully if event is null

    batch(() => {
        dispatch(setActiveEventById(eventId));
        dispatch(setEditableFromDate(moment(event.start)));
        dispatch(setEditableToDate(moment(event.end)));
    })
};

// TODO: lots of attendee bugs to fix
const EventDetailsModal = (props: any) => {

    const dispatch = useDispatch();

    const fromDate = props.eventsInfo.editableFromDate;
    const toDate = props.eventsInfo.editableToDate;
    const {eventsList, isEditModeToggled, activeEvent, isCalendarLoading, isActiveEventLoading, initialAttendees} = props.eventsInfo;

    const defaultProps = {
        options: props.connectedList.connectedClientsList.map((contact : any) => ({
            name: contact.name,
            user_id: contact.user_id
        })),
        getOptionLabel: (option : any) => option.name,
    };

    if(isCalendarLoading) {
        return (
            <div className="modal fade" id="event-details-modal" tabIndex={-1} role="dialog" aria-hidden="true"
                 data-backdrop={'static'}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/*TODO:NATHAN*/}
                            <Loading />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // TODO: remove circular-json npm dependency
    return (
        <div className="modal fade" id="event-details-modal" tabIndex={-1} role="dialog" aria-hidden="true"
             data-backdrop={'static'}>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content animated fadeInLeft">
                    <div className="modal-header">
                        <h3 className="modal-title" id="event-title"></h3>
                        <input type={'text'} id={'edit-event-title'} className={'form-control'}/>
                        <div className={'text-right'}>
                            {
                                Cookies.get('user_token') === activeEvent.organizerId && !isEditModeToggled ?
                                    <button type={'button'} className={'btn title-btn'}
                                            data-toggle="tooltip" title="Change event"
                                            onClick={() => toggleEditMode(activeEvent, dispatch, isEditModeToggled)}>
                                        <i className="fa fa-cog fa-lg" aria-hidden="true"></i>
                                    </button>
                                    :
                                    null
                            }
                            <button type="button" className="btn title-btn" onClick={()=>toggleChangesCheck(isEditModeToggled, initialAttendees, activeEvent, fromDate, toDate, dispatch)}>
                                <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body container-fluid border-bottom">
                        <div className="row">
                            <div className="col text-left"><b>Organizer</b></div>
                            <div className="col text-right" id="event-organizer"></div>
                        </div>
                    </div>
                    <div className="modal-body container-fluid border-bottom">
                        <div className="row">
                            <div className="col text-left"><b>Attendees</b></div>
                            <ul className="col text-right" id="attendee-list">
                                {
                                    activeEvent.attendees ?
                                        activeEvent.attendees.map((attendee:any) => (
                                            <li className={'attendee-tag'}>{attendee.name}</li>
                                        ))
                                    : null
                                }
                            </ul>
                            {/*
                                may have to move to separate component so that active event list may be built
                                prior to getting contacts
                            */}
                            <div className="col" id={'editable-attendee-list'}>
                                {
                                    activeEvent.attendees && !isActiveEventLoading?
                                        <Autocomplete
                                            multiple
                                            id="multiple-limit-tags"
                                            {...defaultProps}
                                            onChange={(event: any, attendees: any) => {
                                                dispatch(setAttendeesList(attendees))
                                            }}
                                            renderInput={(params) => {
                                                return <TextField {...params} variant="standard" label="Attendees"/>
                                            }}
                                        />
                                        :null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-body container-fluid border-bottom" id="event-time">
                        <div className="row" id={'edit-all-day-details-row'}>
                            <div className="col custom-control custom-checkbox">
                                <input type="checkbox" id={'all-day-details-box'} className="custom-control-input"
                                       onClick={toggleDetailsAllDay}/>
                                <label className="custom-control-label" id={'edit-all-day-details-label'}
                                       htmlFor={'all-day-details-box'}>Set all day?</label>
                            </div>
                        </div>
                        <div className="row" id={'all-day-details-row'}>
                            <div className="col">
                                <label id={'all-day-details-label'}>All day</label>
                            </div>
                        </div>
                        <div className="row" id="event-from-row">
                            <div className="col-md-3 text-left">From</div>
                            <div className="col date-col" id="event-from-date"></div>
                            <div className="col-md-9 edit-date-col">
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <div className="details-date-time-container">
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Date"
                                            format="MM/DD/YYYY"
                                            style={{width: '150px', padding: '0 5px 0 0'}}
                                            value={fromDate}
                                            onChange={(date: Moment | null) => dispatch(setEditableFromDate(date))}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Time"
                                            style={{width: '150px'}}
                                            value={fromDate}
                                            onChange={(date: Moment | null) => dispatch(setEditableFromDate(date))}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    </div>
                                    <div className="details-date-container">
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Date"
                                            format="MM/DD/YYYY"
                                            style={{width: '150px'}}
                                            value={fromDate}
                                            onChange={(date: Moment | null) => dispatch(setEditableFromDate(date))}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="row" id="event-to-row">
                            <div className="col-md-3 text-left">To</div>
                            <div className="col date-col" id="event-to-date"></div>
                            <div className="col-md-9 edit-date-col">
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <div className="details-date-time-container">
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Date"
                                            format="MM/DD/YYYY"
                                            style={{width: '150px', padding: '0 5px 0 0'}}
                                            value={toDate}
                                            onChange={(date: Moment | null) => dispatch(setEditableToDate(date))}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Time"
                                            style={{width: '150px'}}
                                            value={toDate}
                                            onChange={(date: Moment | null) => dispatch(setEditableToDate(date))}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    </div>
                                    <div className="details-date-container">
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Date"
                                            format="MM/DD/YYYY"
                                            style={{width: '150px'}}
                                            value={toDate}
                                            onChange={(date: Moment | null) => dispatch(setEditableToDate(date))}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body" id={'event-details'}></div>
                    <div className="modal-body" id="edit-event-details">
                        <textarea className={'form-control'} id="event-details-textarea"/>
                    </div>
                    <div className="modal-footer" id={'details-btn-group'}>
                        <button type="button" className="btn btn-two" data-dismiss="modal">Close</button>
                    </div>
                    <div className="modal-footer" id={'edit-details-btn-group'}>
                        <button type={'button'} className={'btn btn-two'} id={"discard-changes-btn"}
                                onClick={() => toggleChangesCheck(isEditModeToggled, initialAttendees, activeEvent, fromDate, toDate, dispatch)}>
                            Cancel
                        </button>
                        <button type="button" className="btn btn-one"
                                onClick={() => saveEventChanges(activeEvent, dispatch, isEditModeToggled, fromDate, toDate, eventsList, initialAttendees)}>Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state : any) => {
    return {
        eventsInfo : state.eventsInfo,
        connectedList : state.connectedList
    }
};

export default {
    EventDetailsModal: connect(mapStateToProps)(EventDetailsModal),
    DiscardChangesModal: connect(mapStateToProps)(DiscardChangesModal),
    NotificationsModal
};