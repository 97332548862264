import './Register.css';

import React, {useEffect, useState} from 'react';
import {batch, connect, useDispatch} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import $ from 'jquery';

import {
    clearClientRegistrationInfo,
    setAddressType,
    setAddressTypeDetails,
    setCity,
    setLastFourSsn,
    setOwnsCar,
    setSalary,
    setStateCode,
    setStreetAddress,
    setZipCode
} from '../../redux/actions/clientRegistration';
import api from '../../api/api';

// TODO: Redirect to basic info registration if user gets here
// TODO: send both password and confirm to backend to confirm on backend

const formatNumber = (num : String) =>{
    return num.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const formatCurrency = (blur : boolean) => {
    let salaryVal = String($('#salary').val());

    if ((!salaryVal || salaryVal === '$' || (blur && salaryVal === '$.00')) && !$('#salary').is(':focus') ) {
        $('#salary').val('');
        $('#salary').next('label').removeClass('active');
        return;
    }

    const originalLen = salaryVal.length;
    let caretPos = $('#salary').prop('selectionStart');

    if (salaryVal.indexOf('.') >= 0){
        const decimalPos = salaryVal.indexOf('.');
        let leftSide = salaryVal.substring(0, decimalPos);
        let rightSide = salaryVal.substring(decimalPos);

        leftSide = formatNumber(leftSide);
        rightSide = formatNumber(rightSide)

        if (blur) rightSide += "00";

        rightSide = rightSide.substring(0, 2);

        salaryVal = "$" + leftSide + "." + rightSide;
    } else {
        salaryVal = formatNumber(salaryVal);
        salaryVal = "$" + salaryVal;

        if (blur) salaryVal += ".00";
    }

    $('#salary').val(salaryVal);

    const updatedLen = salaryVal.length;
    caretPos = updatedLen - originalLen + caretPos;

    let salaryInput = $('#salary').keyup()[0] as HTMLInputElement;
    salaryInput.setSelectionRange(caretPos, caretPos);

}

const handleAddressTypeChange = (dispatch? : Function) => {
    if($('#address-type option:selected').val() === 'Other')
        $('#explain-other-row').css('display', 'block');

    else {
        $('#explain-other-row').css('display', 'none');
    }

    if(dispatch) {
        dispatch(setAddressType($('#address-type option:selected').val()));
    }
}

const handleAddressTypeDetailsChange = (dispatch : Function) => {
    if ($('#address-type option:selected').val() === 'Other')
        dispatch(setAddressTypeDetails($('#explain-other').val()))
}

const setDefaultAddressType = (addressType : string) => {
    if(addressType === 'House' || addressType === 'Apartment' || addressType === 'Condominium' ||
            addressType === 'Military Base' || addressType === 'Dormitory' || addressType === 'Other') {
        $('#address-type').find('option[value="' + addressType + '"]').attr('selected', 'true');
    } else {
        $('#address-type').find('option[value="default"]').attr('selected', 'true');
    }
}

// const setDefaultOwnsCar = (ownsCar : boolean) => {
//     if (ownsCar) {
//         $('#yes-toggle-btn').addClass('active');
//         $('#no-toggle-btn').removeClass('active');
//     } else {
//         $('#yes-toggle-btn').removeClass('active');
//         $('#no-toggle-btn').addClass('active');
//     }
// }

const lastFourSsnChecker = (lastFourSsnField : any) => {
    const pattern = /^$|\d{4}/i;


    if(!pattern.test(lastFourSsnField.val())) {
        lastFourSsnField.addClass('invalid');
        lastFourSsnField.next('label').attr('data-error', 'Invalid')
    } else {
        lastFourSsnField.next('label').attr('data-error', '');
        lastFourSsnField.removeClass('invalid');
    }
}

const validatePersonalInfo = (dispatch: Function, dataField : any) => {
    if (dataField.attr('id') === 'salary') {
        
        formatCurrency(true);
        dispatch(setSalary($('#salary').val()));

    } else if (dataField.attr('id') === 'last-four-ssn') {
        
        dispatch(setLastFourSsn(dataField.val()))
        lastFourSsnChecker(dataField);
        
    }
}

const setDefaultStateCode = (stateCode : string, stateCodeArr : string[]) => {
    if(stateCodeArr.includes(stateCode))
        $('#state-code').find('option[value="' + stateCode + '"').attr('selected', 'true');
    else
        $('#state-code').find('option[value="default"]').attr('selected', 'true');
}

const registerUser = (dispatch : Function, props : any, setIsSuccess : Function) => {
    const { /*username, */firstName, lastName, email, password, confirmPassword } = props.userInfo;
    const { salary, addressType, addressTypeDetails, ownsCar, lastFourSsn, streetAddress, city, stateCode, zipCode } = props.clientRegistrationInfo;
    
    const salaryAsDecimal : number = parseFloat(salary.substring(1).replace(',', ''));

    const registrationData = {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        salary: salaryAsDecimal,
        address_type: addressType,
        address_type_details: addressTypeDetails || null,
        owns_car: ownsCar,
        ssn_last_four: lastFourSsn,
        street_address: streetAddress,
        city,
        state_code: stateCode,
        zip_code: zipCode
    };
    
    api.post('clients/register/', registrationData)
        .then( res => {
            if(res.status === 200) {
                batch(() => {
                    setIsSuccess(true);
                    dispatch(clearClientRegistrationInfo());
                    props.createNotifications('success', [{
                        body: '',
                        title: 'Registration Success!'
                    }])
                })
            }
            //TODO: add else statement and output errors where necessary
        })
    
}

const PersonalInfoRegistration = (props : any) => {
    
    const dispatch = useDispatch();

    const [ isSuccess, setIsSuccess ] = useState(false);

    const { /*username, */firstName, lastName, email, password, confirmPassword, salary, addressType, 
        addressTypeDetails, ownsCar, lastFourSsn, streetAddress, city, stateCode, zipCode } = props.userInfo
    const addressTypeArr = ['House', 'Apartment', 'Condominium', 'Military Base', 'Dormitory', 'Other']
    let addressTypeSelectedIndex = 0;
    
    const stateCodeArr = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']


    if(addressType === addressTypeArr[1]) addressTypeSelectedIndex = 1;
    else if(addressType === addressTypeArr[2]) addressTypeSelectedIndex = 2;
    else if(addressType === addressTypeArr[3]) addressTypeSelectedIndex = 3;

    useEffect(() => {
        // initialize hidden please explain input depending on address type
        setDefaultAddressType(addressType); 
        handleAddressTypeChange();

        // initialize selected option
        // setDefaultOwnsCar(ownsCar);

        //initialize default statecode
        setDefaultStateCode(stateCode, stateCodeArr);
     })

    if(!(/*username && */firstName && lastName && email && password && confirmPassword))
        return <Redirect to='/register/basic' />

    if(isSuccess)
     return <Redirect to='/login/client' />

    return (
        <div className="register form-group" id="personal-registration">
            <div id="header">
                <h2>Register - Personal Info</h2>
                <hr />
            </div>
            <div className="row">
                <div className="col">
                    <div className={'md-form'}>
                        <input type="text" id={'salary'} pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" data-type="currency" 
                            className="form-control validate" defaultValue={ salary ? salary : '' } onKeyUp={() => formatCurrency(false)}
                            onBlur={() => validatePersonalInfo(dispatch, $('#salary'))} />
                        <label htmlFor={'salary'} className={ salary ? 'active' : '' }>Salary</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">Address type</div>
            </div>
            <div className="row">
                <div className="col-md">
                    <select id="address-type" className="browser-default custom-select validate" 
                        onChange={() => handleAddressTypeChange(dispatch)}>
                        <option value="default" disabled>Choose one</option>
                        {
                            addressTypeArr.map( (value) => {
                                return (
                                    <option value={value}>{value}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="row" id="explain-other-row">
                <div className="col-md">
                    <div className="md-form">
                        <div className={'md-form'}>
                            <input type="text" id={'explain-other'} className="form-control validate" defaultValue={ addressTypeDetails ? addressTypeDetails : '' }
                                onBlur={() => handleAddressTypeDetailsChange(dispatch)} />
                            <label htmlFor={'explain-other'} className={ addressTypeDetails ? 'active' : '' } >Please explain</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="row" id="car-ownership-form">
                        <div className="col-md-6">Do you own a car?</div>
                    </div>
                    <div className="row">
                        <div className="col-lg">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="btn btn-one" id="yes-toggle-btn" onClick={() => dispatch(setOwnsCar(true))}>
                                    <input type="radio" name="options" autoComplete="off"/>Yes
                                </label>
                                <label className="btn btn-one active" id="no-toggle-btn" onClick={() => dispatch(setOwnsCar(false))}>
                                    <input type="radio" name="options" autoComplete="off" />No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className={'md-form'}>
                        <input type="text" id={'last-four-ssn'} className="form-control validate" defaultValue={ lastFourSsn } 
                            maxLength={4} onBlur={ () => validatePersonalInfo(dispatch, $('#last-four-ssn')) } />
                        <label htmlFor={'last-four-ssn'} className={ lastFourSsn ? 'active' : '' } >SSN Last Four</label>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col-md">
                    <div className={'md-form'}>
                        <input type="text" id={'street-address'} className="form-control validate" defaultValue={ streetAddress } onBlur={ () => dispatch(setStreetAddress($('#street-address').val())) } />
                        <label htmlFor={'street-address'} className={ streetAddress ? 'active' : '' } >Street Address</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md">
                    <div className={'md-form'}>
                        <input type="text" id={'city'} className="form-control validate" defaultValue={ city } onBlur={ () => dispatch(setCity($('#city').val())) } />
                        <label htmlFor={'city'} className={ city ? 'active' : '' } >City</label>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-6">State code</div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <select id="state-code" className="browser-default custom-select validate" 
                                onChange={ () => dispatch(setStateCode($('#state-code option:selected').val())) }>
                                <option value="default" disabled>Choose one</option>
                                {
                                    stateCodeArr.map( (value) => {
                                        return (
                                            <option value={value}>{value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={'md-form'}>
                        <input type="text" id={'zip-code'} className="form-control validate" defaultValue={ zipCode } onBlur={ () => dispatch(setZipCode($('#zip-code').val())) } />
                        <label htmlFor={'zip-code'} className={ zipCode ? 'active' : '' } >Zip Code</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={'col-xl-5'}>
                    <Link className="btn btn-outline-secondary" to="/register/basic" id="back-btn">
                        <i className="fa fa-arrow-left" aria-hidden="true"></i><span>&nbsp;Go Back</span>
                    </Link>
                </div>
                <div className={'col-xl'}>
                    <button className="btn" id="register-btn" onClick={() => registerUser(dispatch, props, setIsSuccess)}>Register</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state : any) => {
    return {
        userInfo: state.userInfo,
        clientRegistrationInfo: state.clientRegistrationInfo
    }
}

export default connect(mapStateToProps)(PersonalInfoRegistration)