import {
    CLEAR_USER_INFO,
    CONFIRM_PASSWORD,
    EMAIL,
    FIRST_NAME, IS_ON_BOARDING, IS_PROFILE_LOADING, IS_USER_LOADING,
    LAST_NAME,
    PASSWORD, PROFILE_PICTURE,
    USER_TYPE
} from "./actionTypes";
import api from "../../api/api";
import Cookies from "js-cookie";
import {batch} from "react-redux";
import defaultProfilePic from '../../media/images/default-profile-pic.png';

export const setFirstName = (payload: any) => ({
    type: FIRST_NAME,
    payload
});

export const setLastName = (payload: any) => ({
    type: LAST_NAME,
    payload
});

export const setEmail = (payload: any) => ({
    type: EMAIL,
    payload
});

export const setPassword = (payload: any) => ({
    type: PASSWORD,
    payload
});

export const setConfirmPassword = (payload: any) => ({
    type: CONFIRM_PASSWORD,
    payload
});

// is used to set the user type if not yet set
export const setUserSession = (skipStateCheck? : boolean) => (dispatch : Function, getState : Function) => {

    if(!skipStateCheck)
        if(!getState().userInfo.isUserLoading)
            dispatch({
                type: IS_USER_LOADING,
                payload: true
            })

    api.get('session/', {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        if(res.data['user_type'] !== 'admin') {

            api.get('profile/', {
                headers: {
                    Authorization: 'Token ' + Cookies.get('auth_token')
                },
                withCredentials: true
            }).then(res1 => {
                batch(() => {
                    dispatch({
                        type: USER_TYPE,
                        payload: res.data['user_type']
                    })
                    dispatch({
                        type: EMAIL,
                        payload: res.data['email']
                    })
                    dispatch({
                        type: FIRST_NAME,
                        payload: res.data['first_name']
                    })
                    dispatch({
                        type: LAST_NAME,
                        payload: res.data['last_name']
                    })
                    dispatch({
                        type: IS_ON_BOARDING,
                        payload: res.data['is_onboarding_active'] ? res.data['is_onboarding_active'] : false
                    })
                    dispatch({
                        type: PROFILE_PICTURE,
                        payload: res1.data['profile'] ? res1.data['profile'] : defaultProfilePic
                    })
                    dispatch({
                        type: IS_PROFILE_LOADING,
                        payload: false
                    })
                });
            }).catch(err => {
                // Cookies.remove('user_token');
                // Cookies.remove('auth_token');
            })
        } else {
            batch(() => {
                dispatch({
                    type: USER_TYPE,
                    payload: res.data['user_type']
                })
                dispatch({
                    type: EMAIL,
                    payload: res.data['email']
                })
                dispatch({
                    type: FIRST_NAME,
                    payload: res.data['first_name']
                })
                dispatch({
                    type: LAST_NAME,
                    payload: res.data['last_name']
                })
                dispatch({
                    type: IS_ON_BOARDING,
                    payload: res.data['is_onboarding_active'] ? res.data['is_onboarding_active'] : false
                })
                dispatch({
                    type: IS_PROFILE_LOADING,
                    payload: false
                })
            });
        }
    }).catch(err => {
        // Cookies.remove('user_token');
        // Cookies.remove('auth_token');
    }).finally(() =>
        dispatch({
            type: IS_USER_LOADING,
            payload: false
        })
    )
}

export const clearUserInfo = () => ({
    type: CLEAR_USER_INFO
});

export const disableOnBoarding = () => ({
     type: IS_ON_BOARDING,
     payload: false
})

// export const setProfilePicture = () => (dispatch : Function) => {
//
//     api.get('profile/', {
//         headers: {
//             Authorization: 'Token ' + Cookies.get('auth_token')
//         },
//         withCredentials: true
//     }).then(res => {
//         batch(() => {
//             dispatch({
//                 type: PROFILE_PICTURE,
//                 payload: res.data['profile']
//             })
//             dispatch({
//                 type: IS_PROFILE_LOADING,
//                 payload: false
//             })
//         });
//     }).catch(err => {
//         Cookies.remove('user_token');
//         Cookies.remove('auth_token');
//         dispatch({
//             type: IS_PROFILE_LOADING,
//             payload: false
//         })
//     })
// }