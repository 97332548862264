import React from "react";


const NotificationsModal = (props: any) => (
    <div className="settings-notification-modal fade show" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-xs" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <span className={'modal-title settings-notification-modal-header'}>{props.notification.title}</span>
                    <button type="button" className="close" onClick={() => props.closeNotification(props.notification)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body settings-notification-modal-body">
                    {props.notification.body}
                </div>
            </div>
        </div>
    </div>
);

const NotificationList = (props : any) => {

    const closeNotification = (notification: any) => {
        props.setNotificationsList(props.notificationsList.filter((item : any) => item !== notification))
    }

    return (
        <ul className={'notification-column'}>
            {
                props.notificationsList.map((notification : any) => (
                    <li><NotificationsModal notification={notification} closeNotification={closeNotification}/></li>
                ))
            }
        </ul>
    )
}

export default NotificationList;