import React, {useEffect, useState} from 'react';
import {Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import {batch, connect, useDispatch} from 'react-redux';
import Cookies from 'js-cookie';
import {clearInfo} from '../redux/actions/client';
import api from '../api/api';
import './NavMenu.css';
import {clearUserInfo, setUserSession} from "../redux/actions/user";
import linkubankLogo from '../media/images/linkubankLogo.png';

declare var $ : any;

const DefaultMenu = () => (
    <ul className="navbar-nav flex-grow">
        <NavItem>
            <NavLink tag={Link} className="text-dark" to="/login/client">Login</NavLink>
        </NavItem>
        <NavItem>
            <NavLink tag={Link} className="text-dark" to="/register/basic">Register</NavLink>
        </NavItem>
    </ul>
)

const NavMenu = (props : any) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const { userType, isUserLoading, profilePicture, isProfilePictureLoading, firstName } = props.userInfo;

    const userTypesList = ['admin', 'agent', 'consultant', 'client'];

    if(isLoading) {
        // validates there are cookies
        // if user type does not exist then true
        // if existing user type is not in the valid list then true
        if (Cookies.get('user_token') && Cookies.get('auth_token') && !(userType && userTypesList.includes(userType))) {
            dispatch(setUserSession());
            // if one or both cookies are missing then clear them both and set user type to empty string
            // checks that user type exists then if it is not in valid list clear cookies and set user type to empty string
        } else if (!(Cookies.get('user_token') && Cookies.get('auth_token')) || (userType && !userTypesList.includes(userType))) {
            Cookies.remove('user_token');
            Cookies.remove('auth_token');
            dispatch(clearUserInfo());
        }

        setIsLoading(false);
    }

    if(!isUserLoading) {
        return (
            // <header>
            <Navbar id={'header-nav'} className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow" light>
                <Container>
                    <NavbarBrand tag={Link} to="/"><img src={linkubankLogo} className={'logo'}
                                                        alt={'LinkUBank'}/></NavbarBrand>
                    {/*<NavbarToggler onClick={() => setIsOpen(!isOpen)} className="mr-2"/>*/}
                    {/*<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>*/}
                    <MenuItems userType={userType} profilePicture={profilePicture} firstName={firstName}/>
                    {/*</Collapse>*/}
                </Container>
            </Navbar>
            // {/*</header>*/}
        );
    } else return(
        <Navbar id={'header-nav'} className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow" light>
            <Container>
                <NavbarBrand tag={Link} to="/"><img src={linkubankLogo} className={'logo'} alt={'LinkUBank'} /></NavbarBrand>
                <NavbarToggler onClick={() => setIsOpen(!isOpen)} className="mr-2"/>
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                    <DefaultMenu />
                </Collapse>
            </Container>
        </Navbar>
    );
}

const MenuItems = (props : any) => {

    const dispatch = useDispatch();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth
                                                    || document.documentElement.clientWidth
                                                    || document.body.clientWidth);

    //TODO: change from clients/logout/ to generic call
    const signOut = () => {
        api.post('logout/', {}, {
            headers: {
                Authorization: 'Token ' + Cookies.get('auth_token')
            },
            withCredentials: true
        }).finally(() => {
            Cookies.remove('user_token');
            Cookies.remove('auth_token');
            Cookies.remove('user_name');
            batch(() => {
                dispatch(clearInfo());
                dispatch(clearUserInfo());
            });
            window.location.href = '/';
        });
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth);
        })
    },[window])

    //TODO: signout link going to relative path instead of exact
    //TODO: works for admin and not client
    if(props.userType === 'client')
        return (
            <div className="btn-group">
                <button type="button" className="btn dropdown-toggle drop-menu" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <img src={props.profilePicture} className={'mini-profile-pic'}/>
                    {
                        windowWidth > 615 ?
                            <span className={'drop-menu-name'}>{props.firstName}</span>
                        : null
                    }
                </button>
                <div className="dropdown-menu dropdown-menu-right menu-items">
                    <Link className="text-dark dropdown-item" to="/client/home">Home</Link>
                    <Link className="text-dark dropdown-item" to="/client/calendar">Calendar</Link>
                    <Link className="text-dark dropdown-item" to="/client/links/consultants">Links</Link>
                    <Link className="text-dark dropdown-item" to="/client/settings/general/change-password">Settings</Link>
                    <Link className="text-dark dropdown-item" to={'#'} onClick={signOut}>Sign Out</Link>
                </div>
            </div>
        );
    else if(props.userType === 'agent')
        return (
            <div className="btn-group">
                <button type="button" className="btn dropdown-toggle drop-menu" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <img src={props.profilePicture} className={'mini-profile-pic'}/>
                    {
                        windowWidth > 615 ?
                            <span className={'drop-menu-name'}>{props.firstName}</span>
                            : null
                    }
                </button>
                <div className="dropdown-menu dropdown-menu-right menu-items">
                    <Link className="text-dark dropdown-item" to="/agent/home">Home</Link>
                    <Link className="text-dark dropdown-item" to="/agent/calendar">Calendar</Link>
                    <Link className={'text-dark dropdown-item'} to={'/agent/search'}>Search</Link>
                    <Link className="text-dark dropdown-item" to="/agent/settings/change-password">Settings</Link>
                    <Link className="text-dark dropdown-item" to={'#'} onClick={signOut}>Sign Out</Link>
                </div>
            </div>
        );
    else if(props.userType === 'admin')
        return (
            <ul className="navbar-nav flex-grow">
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/admin/home/create-consultant">Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/admin/settings/general/change-password">Settings</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" onClick={signOut}>Sign Out</NavLink>
                </NavItem>
            </ul>
        );
    else if(props.userType === 'consultant')
        return (
            <div className="btn-group">
                <button type="button" className="btn dropdown-toggle drop-menu" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <img src={props.profilePicture} className={'mini-profile-pic'}/>
                    {
                        windowWidth > 615 ?
                            <span className={'drop-menu-name'}>{props.firstName}</span>
                            : null
                    }
                </button>
                <div className="dropdown-menu dropdown-menu-right menu-items">
                    <Link className="text-dark dropdown-item" to="/consultant/home">Home</Link>
                    <Link className="text-dark dropdown-item" to="/consultant/calendar">Calendar</Link>
                    <Link className={'text-dark dropdown-item'} to={'/consultant/search'}>Search</Link>
                    <Link className="text-dark dropdown-item" to="/consultant/settings/change-password">Settings</Link>
                    <Link className="text-dark dropdown-item" to={'#'} onClick={signOut}>Sign Out</Link>
                </div>
            </div>
        );
    else
        return <DefaultMenu />;
}

const mapStateToProps = (state : any) => {
    return { userInfo : state.userInfo }
}

export default connect(mapStateToProps)(NavMenu);