import {batch, connect, useDispatch} from "react-redux";
import {Route} from "react-router";
import React, {useState} from "react";
import Cookies from 'js-cookie';
import './Links.css';
import {Link} from "react-router-dom";
import linkReview from '../../../api/linkReview';
import {setCollaboratorsList, setConnectedLists, setLinkRequests,} from "../../../redux/actions/connectedLists";
import api from "../../../api/api";
import {clearUserInfo, setUserSession} from "../../../redux/actions/user";
import Loading from "../../Loading/Loading";

declare let $ : any;

const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

const AgentProfessions = (props : any) => {
    if(props.userType === 'agent') {
        return props.professions.slice(0,2).map((profession: any) => {
            return <li>{profession}</li>
        })
    } else return null;
}

const CollaboratorItem = (props: any) => {

    const mailTo = 'mailto:' + props.collaborator.email;
    const userType : string = props.collaborator.type;

    return (
        <div className={'row'}>
            <div className="col-9" id={'collaborator-item-col'}>
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-5 text-center">
                                <Link
                                    to={'/' + userType + '/profile/' + props.collaborator.user_id}
                                    className={'btn-link card-title'}
                                >
                                    {props.collaborator.name}
                                </Link>
                                <p className="card-text">{formatPhoneNumber(props.collaborator.phone_number)}</p>
                            </div>
                            <div className="col-md-4 text-left">
                                <ul className={'card-text text-center'} id={'professions'}>
                                    <AgentProfessions userType={userType} professions={props.collaborator.professions} />
                                </ul>
                            </div>
                            <div className="col-md text-center p-0">
                                <p className="card-text">
                                    <button
                                        className={'btn'}
                                        id={'email-icon-button'}
                                        onClick={()=>window.location.href=mailTo}
                                    >
                                        <i className="far fa-envelope fa-3x"></i>
                                    </button>
                                </p>
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="col-md text-center p-0">*/}
                        {/*        <p className="card-text">*/}
                        {/*            <button className={'btn'} id={'email-icon-button'}>*/}
                        {/*                <i className="far fa-envelope fa-3x"></i>*/}
                        {/*            </button>*/}
                        {/*        </p>*/}
                        {/*    </div>*/}
                        {/*    /!*<div className="col-md text-center p-0">*!/*/}
                        {/*    /!*    <p className="card-text">*!/*/}
                        {/*    /!*        <button className={'btn'} id={'msg-icon-button'}>*!/*/}
                        {/*    /!*            <i className="far fa-comment-alt fa-3x"></i>*!/*/}
                        {/*    /!*        </ button>*!/*/}
                        {/*    /!*    </p>*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const searchCollaborators = (dispatch: Function, collaboratorType : string, setIsSearchActive : Function, searchQuery : string) => {

    if(searchQuery && searchQuery.trim()) {
        batch(()=>{
            // setSearchQuery(query);
            setIsSearchActive(true);
            dispatch(setCollaboratorsList(collaboratorType + '/search/1/' + searchQuery + '/'));
        })
    }
}

const onEnterClicked = (e: any, dispatch: Function, setIsSearchActive : Function, collaboratorType : string, searchQuery : string) => {
    if (e.keyCode === 13)
        searchCollaborators(dispatch, collaboratorType, setIsSearchActive, searchQuery);
};

const clearSearch = (dispatch : Function, setSearchIsActive : Function, setSearchQuery : Function, collaboratorType : string) => {
    // $('#clear-search-btn').css('visibility', 'hidden');
    $('#search-collaborators-input').val('');
    batch(() => {
        dispatch(setCollaboratorsList(collaboratorType + '/search/1'))
        setSearchQuery('');
        setSearchIsActive(false);
    })
}

const viewPage = (pageNum : number, dispatch : Function, collaboratorType : string, searchQuery? : string) => {
    if(searchQuery && searchQuery.trim())
        dispatch(setCollaboratorsList(collaboratorType + '/search/' + pageNum + '/' + searchQuery.trim() + '/'));
    dispatch(setCollaboratorsList(collaboratorType + '/search/' + pageNum));
}

const CollaboratorsComponent = (props: any) => {

    const dispatch = useDispatch();
    const { collaboratorList, totalPages, currentPageNum, isLoading, isCollaboratorsLoading } = props.connectedList;
    const [isListRequestSubmitted, setIsListRequestSubmitted] = useState(false);
    const [pageSpan, setPageSpan] = useState([1]);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const headerText = props.type[0].toUpperCase() + props.type.slice(1);
    let cancelBtnVisiblility : any = isSearchActive ? 'visible' : 'hidden';

    if (!isListRequestSubmitted) {
        batch(() => {
            viewPage(1, dispatch, props.type)
            setIsListRequestSubmitted(true);
        })
    }

    if(!isLoading && totalPages != pageSpan.length){
        let temp : number[] = [];
        let i = currentPageNum - 2 >= 1 ? currentPageNum - 2 : 1;

        for(; i <= totalPages && i <= 5; i++)
            temp.push(i)

        batch(()=> {
            setPageSpan(temp);
        })
    }

    if(isLoading || isCollaboratorsLoading)
        return null;

    return (
        <div>
            <div className="row mt-2">
                <div className="col">
                    <h3 className={'m-0 text-center'}>{headerText}</h3>
                </div>
            </div>
            <div className="row collaborators-headers">
                <div className={'md-form'}>
                    <div className={'row'}>
                        <input
                            type={'text'}
                            id={'search-collaborators-input'}
                            className={'form-control'}
                            defaultValue={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) =>
                                onEnterClicked(e, dispatch, setIsSearchActive, props.type, searchQuery)}
                        />
                        <label htmlFor={'search-collaborators-input'} className={searchQuery ? 'active': ''}>Search</label>
                        <button
                            className={'btn'}
                            id={'clear-search-btn'}
                            style={{visibility: cancelBtnVisiblility}}
                            onClick={() => {clearSearch(dispatch, setIsSearchActive, setSearchQuery, props.type);}}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                        <button
                            className={'btn'}
                            id={'collaborator-search-btn'}
                            onClick={() => searchCollaborators(dispatch, props.type, setIsSearchActive, searchQuery)}
                        >
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row collaborators-list">
                <div className="col text-center">
                    {
                        collaboratorList.length > 0 ?
                            collaboratorList.map((collaborator: any) => (
                                <CollaboratorItem collaborator={collaborator}/>
                            ))
                        :
                            <span>There are no {props.type} that match your search</span>
                    }
                </div>
            </div>
            <div className="row" id={'pagination-footer'}>
                <div className="col text-center">
                    <span className={'page-arrow'}>
                        {
                            currentPageNum - 1 > 0 && collaboratorList.length > 0?
                                <button className={'btn paging-btn'} onClick={() => viewPage(currentPageNum - 1, dispatch, props.type, searchQuery)}>
                                    <i className="fas fa-caret-left"></i>
                                </button>
                            : null
                        }
                    </span>
                    {
                        collaboratorList.length > 0 ?
                            pageSpan.map(pageNum => {
                                if(pageNum == currentPageNum)
                                    return (
                                        <span className={'page-number selected-page'}>{pageNum}</span>
                                    )
                                return (
                                    <button className={'btn btn-link paging-btn'} onClick={() => viewPage(pageNum, dispatch, props.type, searchQuery)}>
                                        <span className={'page-number'}>{pageNum}</span>
                                    </button>
                                )
                            })
                        : null
                    }
                    <span className={'page-arrow'}>
                        {
                            currentPageNum + 1 <= totalPages && collaboratorList.length > 0 ?
                                <button className={'btn paging-btn'} onClick={() => viewPage(currentPageNum + 1, dispatch, props.type, searchQuery)}>
                                    <i className="fas fa-caret-right"></i>
                                </button>
                            : null
                        }
                    </span>
                </div>
            </div>
        </div>
    );
}

const ClientContacts = (props: any) => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    if (isLoading)
        batch(() => {
            dispatch(setConnectedLists());
            setIsLoading(false);
        });

    if(props.connectedList.isConnectedListLoading) {
        return <Loading />;
    }

    return (
        <div>
            <div className="row collaborators-headers">
                <div className={'md-form'}>
                    <div className={'row'}>
                    </div>
                </div>
            </div>
            <div className="row collaborators-headers">
                <div className={'md-form'}>
                    <div className={'row'}>
                        <h3>Consultants</h3>
                    </div>
                </div>
            </div>
            <div className="row collaborators-list pt-3 border-top">
                <div className="col text-center">
                    {
                        props.connectedList.connectedConsultantsList.length > 0 ?
                            props.connectedList.connectedConsultantsList.map((consultant: any) => (
                                <CollaboratorItem collaborator={consultant}/>
                            ))
                        :
                            <span>You are not linked with any consultants</span>
                    }
                </div>
            </div>
            <div className="row collaborators-headers">
                <div className={'md-form'}>
                    <div className={'row'}>
                        <h3>Agents</h3>
                    </div>
                </div>
            </div>
            <div className="row collaborators-list pt-3 border-top">
                <div className="col text-center">
                    {
                        props.connectedList.connectedAgentsList.length > 0 ?
                            props.connectedList.connectedAgentsList.map((agent: any) => (
                                <CollaboratorItem collaborator={agent}/>
                            ))
                            :
                            <span>You are not linked with any agents</span>
                    }
                </div>
            </div>
        </div>
    );
}

// const userLinkResponse = (user_id : any, dispatch : Function, action : boolean) => {
//     const request = {
//         user_id,
//         is_link_accepted: action
//     }
//     api.put('links/', request, {
//         headers: {
//             Authorization: 'Token ' + Cookies.get('auth_token'),
//         },
//         withCredentials: true
//     }).then(res => {
//         if(res.status === 200) {
//             dispatch(setCollaboratorsList('links/requests/'));
//         }
//     }, err => {
//         console.log(err);
//     })
// }

const deleteLink = (userId: any, dispatch : Function) => {
    api.delete('links/' + userId + '/', {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        if (res.status === 200) {
            dispatch(setLinkRequests());
        }
    })
}

const OutgoingRequestItem = (props: any) => {

    const dispatch = useDispatch();

    return (
        <div className={'row'}>
            <div className="col-9" id={'collaborator-item-col'}>
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 text-center">
                                <Link
                                    to={'/consultant/profile/' + props.collaborator.user_id}
                                    className={'btn-link card-title'}
                                >
                                    {props.collaborator.first_name + ' ' + props.collaborator.last_name}
                                </Link>
                                <p className="card-text">{formatPhoneNumber(props.collaborator.phone_number)}</p>
                            </div>
                            <div className="col-md text-center">
                                <p className="card-text"></p>
                            </div>
                            <div className="col-md text-center">
                                <button
                                    className="btn btn-link btn-md"
                                    onClick={() => deleteLink(props.collaborator.user_id, dispatch)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        {/*<div className="row" id={'request-action-btns'}>*/}
                        {/*    <div className="col-md text-center p-0">*/}
                        {/*        <button*/}
                        {/*            className={'btn btn-success'}*/}
                        {/*            id={'approve-request-button'}*/}
                        {/*            onClick={()=>linkReview(props.collaborator.user_id, dispatch, true)}*/}
                        {/*        >*/}
                        {/*            Approve*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-md text-center p-0">*/}
                        {/*        <button*/}
                        {/*            className={'btn btn-danger'}*/}
                        {/*            id={'reject-request-button'}*/}
                        {/*            onClick={()=>linkReview(props.collaborator.user_id, dispatch, false)}*/}
                        {/*        >*/}
                        {/*            Reject*/}
                        {/*        </ button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const IncomingRequestItem = (props: any) => {

    const dispatch = useDispatch();

    return (
        <div className={'row'}>
            <div className="col-9" id={'collaborator-item-col'}>
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-5 text-center">
                                <Link
                                    to={'/consultant/profile/' + props.collaborator.user_id}
                                    className={'btn-link card-title'}
                                >
                                    {props.collaborator.first_name + ' ' + props.collaborator.last_name}
                                </Link>
                                <p className="card-text">{formatPhoneNumber(props.collaborator.phone_number)}</p>
                            </div>
                            <div className="col-md text-center">
                                <p className="card-text"></p>
                            </div>
                        </div>
                        <div className="row" id={'request-action-btns'}>
                            <div className="col-md text-center p-0">
                                <button
                                    className={'btn btn-success'}
                                    id={'approve-request-button'}
                                    onClick={() => linkReview(props.collaborator.user_id, dispatch, true)}
                                >
                                    Approve
                                </button>
                            </div>
                            <div className="col-md text-center p-0">
                                <button
                                    className={'btn btn-danger'}
                                    id={'reject-request-button'}
                                    onClick={() => linkReview(props.collaborator.user_id, dispatch, false)}
                                >
                                    Reject
                                </ button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ConnectionRequests = (props: any) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const {incomingRequestsList, outgoingRequestsList} = props.connectedList

    if (isLoading)
        dispatch(setLinkRequests(setIsLoading));

    return (
        <div>
            <div className="row collaborators-headers">
                <div className={'md-form'}>
                    <div className={'row'}>
                        <h3>Incoming</h3>
                    </div>
                </div>
            </div>
            <div className="row border-top pt-3 collaborators-list">
                <div className="col">
                    {
                        incomingRequestsList.length > 0 ?
                            incomingRequestsList.map((collaborator: any) => (
                                <IncomingRequestItem collaborator={collaborator}/>
                            ))
                            :
                            <div className={'text-center'}>No incoming requests</div>
                    }
                </div>
            </div>
            <div className="row collaborators-headers">
                <div className={'md-form'}>
                    <div className={'row'}>
                        <h3>Outgoing</h3>
                    </div>
                </div>
            </div>
            <div className="row border-top pt-3 collaborators-list">
                <div className="col">
                    {
                        outgoingRequestsList.length > 0 ?
                            outgoingRequestsList.map((collaborator: any) => (
                                <OutgoingRequestItem collaborator={collaborator} />
                            ))
                        :
                            <div className={'text-center'}>No outgoing requests</div>
                    }
                </div>
            </div>
        </div>
    );
}

const ClientLinks = (props: any) => {

    const dispatch = useDispatch();

    if (props.userInfo.isUserLoading) {
        dispatch(setUserSession());

        // TODO: animate loading
        return <Loading />
    } else {
        if (Cookies.get('user_token') && Cookies.get('auth_token'))
            return (
                <div id="connections-container" className={'row'}>
                    <div className="col-3" id={'connections-links'}>
                        <nav className={'nav flex-column card'} id={'connections-side-nav'}>
                            <Link className="nav-link" to={'/client/links/consultants'}>Consultants</Link>
                            <Link className="nav-link" to={'/client/links/agents'}>Agents</Link>
                            <Link className="nav-link" to={'/client/links/contacts'}>My Contacts</Link>
                            <Link className="nav-link" to={'/client/links/requests'}>Requests</Link>
                        </nav>
                    </div>
                    <div className="col-7">
                        <Route exact path={'/client/links/consultants'}
                               render={() => <CollaboratorsComponent connectedList={props.connectedList} type={'consultants'}/>}/>
                        <Route exact path={'/client/links/agents'}
                               render={() => <CollaboratorsComponent connectedList={props.connectedList} type={'agents'} />}/>
                        <Route path={'/client/links/contacts'}
                               render={() => <ClientContacts connectedList={props.connectedList}/>}/>
                        <Route path={'/client/links/requests'}
                               render={() => <ConnectionRequests connectedList={props.connectedList} />}/>
                    </div>
                </div>
            );
        else {
            Cookies.remove('user_token');
            Cookies.remove('auth_token');
            dispatch(clearUserInfo());

            window.location.href = '/';
            return null;
        }
    }
};

const mapStateToProps = (state: any) => {
    return {
        userInfo: state.userInfo,
        connectedList: state.connectedList
    }
};

export default connect(mapStateToProps)(ClientLinks);