import api from "../../api/api";
import Cookies from "js-cookie";
import {SET_CLIENT_BUDGET, TOGGLE_IS_BUDGET_LOADING} from "./actionTypes";


export const setClientBudget = () => (dispatch : Function) => {

    api.get('budgets/', {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        dispatch({
            type: SET_CLIENT_BUDGET,
            payload: res.data
        })
    }).catch(err => {
        // TODO: handle error
    }).finally(() => {
        dispatch({
            type: TOGGLE_IS_BUDGET_LOADING,
            payload: false
        })
    })
}