import {
    ADD_TODO_LIST_ITEM,
    CLEAR_SEARCH_FILTER,
    DELETE_TASK_ITEMS,
    FILTER_TASK_LIST,
    SET_IS_TASK_LIST_LOADING,
    SET_SELECTED_TASK_INDEX,
    SET_TASK_LIST,
    UPDATE_TASK_LIST
} from '../actions/actionTypes';

const intitialState = {
    selectedTaskIndex: -1,
    taskList: [],
    filteredTaskList: [],
    isLoading: true
};

const userTasksReducer = (state = intitialState, action) => {

    switch(action.type){
        case SET_SELECTED_TASK_INDEX:
            return {
                ...state,
                selectedTaskIndex: action.payload
            };
        case ADD_TODO_LIST_ITEM:
            return {
                ...state,
                taskList: [...state.taskList, action.payload.newTaskItem],
                filteredTaskList: [...state.filteredTaskList, action.payload.newTaskItemIndex]
            }
        case SET_IS_TASK_LIST_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_TASK_LIST:
            return {
                ...state,
                taskList: action.payload,
                filteredTaskList: action.payload.map((task, index) => index)
            }
        case CLEAR_SEARCH_FILTER:
            return {
                ...state,
                filteredTaskList: state.taskList.map((task, index) => index)
            }
        case FILTER_TASK_LIST:
            return {
                ...state,
                filteredTaskList: action.payload
            }
        case UPDATE_TASK_LIST:
            return {
                ...state,
                taskList: action.payload
            }
        case DELETE_TASK_ITEMS:
            return {
                ...state,
                taskList: action.payload.updatedTaskList,
                filteredTaskList: action.payload.updatedTaskIndexes
            }
        default:
            return state;
    }
};

export default userTasksReducer;