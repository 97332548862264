import './Login.css';

import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {batch, connect, useDispatch} from 'react-redux';
import api from '../../api/api';
import {setUserSession} from "../../redux/actions/user";

const submitLogin = (dispatch : Function, setIsSuccess : Function) => {

    const loginData = {
        email: $('#email').val(),
        password: $('#password').val()
    }

    if (loginData.email && loginData.password) {
        api.post('consultants/login/', loginData, { withCredentials : true })
            .then(res => {
                $('#error-msg').addClass('alert alert-success');
                $('#error-msg').text('Success!');
                batch(() => {
                    dispatch(setUserSession());
                    setIsSuccess(true);

                });
            }, err => {
                $('#error-msg').addClass('alert alert-danger');
                $('#error-msg').text('Invalid credentials');
            })
    }
};

const onEnterClicked = (e : any, dispatch : Function, setIsSuccess : Function) => {
    if (e.keyCode === 13)
        submitLogin(dispatch, setIsSuccess);
};

const ConsultantsLogin = () => {

    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();

    if(isSuccess)
        return <Redirect to={'/consultant/home'} />
    else
        return (
            <div className="login form-group" onKeyDown={(e) => onEnterClicked(e, dispatch, setIsSuccess)}>
                <div id="header">
                    <h2>Consultant Login</h2>
                </div>
                <hr/>
                <div className='row'>
                    <div className="col">
                        <div id="error-msg"></div>
                    </div>
                </div>
                <div className="md-form">
                    <input type="text" id={'email'} className="form-control" />
                    <label htmlFor={'email'}>Email</label>
                </div>
                <div className="md-form">
                    <input type="password" id={'password'} className="form-control" />
                    <label htmlFor={'password'}>Password</label>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-one" id="login-btn" onClick={() => submitLogin(dispatch, setIsSuccess)}>Login</button>
                    </div>
                </div>
                <div className="row">   
                    <div className="col text-center">
                        <Link to={'/forgot-password'} className="form-control" id="forgot-password-link">Forgot password?</Link>
                    </div>
                </div>
                <hr />
            </div>
        )
};


export default connect()(ConsultantsLogin)