import {
    ADD_TODO_LIST_ITEM,
    CLEAR_SEARCH_FILTER,
    DELETE_TASK_ITEMS,
    FILTER_TASK_LIST,
    SET_IS_TASK_LIST_LOADING,
    SET_SELECTED_TASK_INDEX,
    SET_TASK_LIST,
    UPDATE_TASK_LIST
} from "./actionTypes";
import api from "../../api/api";
import Cookies from "js-cookie";
import qs from 'querystring';

export const setSelectedTaskIndex = taskIndex => ({
    type: SET_SELECTED_TASK_INDEX,
    payload: taskIndex
});

export const addTodoListItem = (title, clientId) => (dispatch, getState) => {

    const taskItem = {
        title,
        is_favorite: false
    }

    let requestUrl = clientId ? `tasks/${clientId}/` : 'tasks/'

    return api.post(requestUrl, taskItem, {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        dispatch({
            type: ADD_TODO_LIST_ITEM,
            payload: {
                newTaskItem: res.data,
                newTaskItemIndex: getState().userTasks.taskList.length
            }
        })

        return res.data
    })
}

export const setTaskList = clientId => (dispatch, getState) => {

    if(!getState().userTasks.isLoading)
        dispatch({
            type: SET_IS_TASK_LIST_LOADING,
            payload: true
        })

    let requestUrl = clientId ? `tasks/${clientId}/` : 'tasks/'

    return api.get(requestUrl, {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        const timezoneOffset = new Date().getTimezoneOffset();
        const millisOffset = timezoneOffset * 60 * 1000;

        dispatch({
            type: SET_TASK_LIST,
            payload: res.data.map(task => {
                if(task.deadline)
                    task.deadline = task.deadline - millisOffset
                return task
            })
        })
    }).finally(() =>
        dispatch({
            type: SET_IS_TASK_LIST_LOADING,
            payload: false
        })
    )
}

export const toggleTaskIsFavorite = (taskIndex, clientId) => (dispatch, getState) => {

    let taskList = getState().userTasks.taskList
    const task = taskList[taskIndex]
    const request = {
        id: task.id,
        is_favorite: !task.is_favorite
    }

    let requestUrl = clientId ? `tasks/${clientId}/` : 'tasks/'

    return api.put(requestUrl, request, {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        taskList[taskIndex].is_favorite = res.data.is_favorite

        dispatch({
            type: UPDATE_TASK_LIST,
            payload: taskList
        })

        return res.data
    })
}

export const setTaskDeadline = (taskIndex, deadline, clientId) => (dispatch, getState) => {

    let millisOffset = null;
    let millisDeadline = null

    if(deadline) {
        const timezoneOffset = new Date().getTimezoneOffset();
        millisOffset = timezoneOffset * 60 * 1000;
        millisDeadline = deadline.toDate().getTime() + millisOffset;
    }

    let taskList = getState().userTasks.taskList
    const task = taskList[taskIndex]
    const request = {
        id: task.id,
        deadline: millisDeadline
    }

    let requestUrl = clientId ? `tasks/${clientId}/` : 'tasks/'

    return api.put(requestUrl, request, {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        if(millisOffset)
            res.data.deadline = res.data.deadline - millisOffset

        taskList[taskIndex].deadline = res.data.deadline

        return dispatch({
            type: UPDATE_TASK_LIST,
            payload: taskList
        })
    })
}

export const setTaskDetails = (taskIndex, taskDetails, clientId) => (dispatch, getState) => {

    let taskList = getState().userTasks.taskList
    const task = taskList[taskIndex]
    const request = {
        id: task.id,
        details: taskDetails
    }

    let requestUrl = clientId ? `tasks/${clientId}/` : 'tasks/'

    return api.put(requestUrl, request,  {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        taskList[taskIndex].details = res.data.details

        return dispatch({
            type: UPDATE_TASK_LIST,
            payload: taskList
        })
    })
}

export const setTaskTitle = (taskIndex, title, clientId) => (dispatch, getState) => {

    let taskList = getState().userTasks.taskList
    const task = taskList[taskIndex]
    const request = {
        id: task.id,
        title
    }

    let requestUrl = clientId ? `tasks/${clientId}/` : 'tasks/'

    return api.put(requestUrl, request,  {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        taskList[taskIndex].title = res.data.title

        return dispatch({
            type: UPDATE_TASK_LIST,
            payload: taskList
        })
    })
}

export const toggleTaskCheckmark = taskIndex => (dispatch, getState) => {

    let taskList = getState().userTasks.taskList
    taskList[taskIndex].isChecked = taskList[taskIndex].isChecked ? false : true

    dispatch({
        type: UPDATE_TASK_LIST,
        payload: taskList
    })
}

export const setTasksCompleted = clientId => (dispatch, getState) => {

    let taskList = getState().userTasks.taskList
    let filteredTaskList = getState().userTasks.filteredTaskList.map(taskIndex => taskList[taskIndex])
    const request = {
        task_id_list: filteredTaskList.filter(task => task.isChecked).map(task => task.id)
    }

    let requestUrl = clientId ? `tasks/complete/${clientId}/` : 'tasks/complete/'

    return api.put(requestUrl, request, {
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {
        taskList.forEach(task => {
            if(request.task_id_list.includes(task.id) && task.isChecked) {
                task.is_completed = true
                task.isChecked = false
            }
        })
    }).then(() => dispatch({
        type: UPDATE_TASK_LIST,
        payload: taskList
    }))
}

const getQueriedTaskList = (trimmedQuery, taskList) => {

    let filteredList = []

    if(/^\*+$/.test(trimmedQuery))
        taskList.forEach((task, index) => {
            if (task.is_favorite)
                filteredList.push(index)
        })
    else if (/^!\*+$/.test(trimmedQuery))
        taskList.forEach((task, index) => {
            if (!task.is_favorite)
                filteredList.push(index)
        })
    else
        taskList.forEach((task, index) => {
            if (task.title.toLowerCase().includes(trimmedQuery) ||
                (task.details && task.details.toLowerCase().includes(trimmedQuery)) ||
                task.organizer.includes(trimmedQuery))
                filteredList.push(index)
        })

    return filteredList
}

export const filterTaskList = untrimmedQuery => (dispatch, getState) => {

    const searchQuery = untrimmedQuery.trim().toLowerCase()

    if(!searchQuery) {
        dispatch({
            type: CLEAR_SEARCH_FILTER
        })
    } else {

        const taskList = getState().userTasks.taskList
        const filteredList = getQueriedTaskList(searchQuery, taskList)

        dispatch({
            type: FILTER_TASK_LIST,
            payload: filteredList
        })
    }
}

export const deleteCheckedTasks = (searchQuery, clientId) => (dispatch, getState) => {

    const taskList = getState().userTasks.taskList
    const filteredTaskList = getState().userTasks.filteredTaskList
    const trimmedSearchQuery = searchQuery.trim()
    let completedCheckedTasks = []

    filteredTaskList.forEach(taskIndex => {
        if(taskList[taskIndex].is_completed && taskList[taskIndex].isChecked) {
            completedCheckedTasks.push(taskList[taskIndex])
        }
    })
    const params = { task_id: completedCheckedTasks.map(task => task.id) }

    let requestUrl = clientId ? `tasks/${clientId}/` : 'tasks/'

    return api.delete(requestUrl, {
        params,
        paramsSerializer: params => {
            return qs.stringify(params)
        },
        headers: {
            Authorization: 'Token ' + Cookies.get('auth_token')
        },
        withCredentials: true
    }).then(res => {

        let updatedTaskIndexes
        let updatedTaskList = taskList.filter((task, index) =>
            !(task.isChecked && task.is_completed && filteredTaskList.includes(index))
        )

        if(!trimmedSearchQuery)
            updatedTaskIndexes = updatedTaskList.map((task, index) => index)
        else
            updatedTaskIndexes = getQueriedTaskList(trimmedSearchQuery, updatedTaskList)

        return dispatch({
            type: DELETE_TASK_ITEMS,
            payload: {
                updatedTaskList,
                updatedTaskIndexes
            }
        })
    })
}