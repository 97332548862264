import {
    IS_CALENDAR_LOADING,
    EVENTS_LIST,
    CLEAR_CALENDAR,
    IS_EDIT_MODE_TOGGLED,
    EDITABLE_FROM_DATE,
    EDITABLE_TO_DATE,
    ACTIVE_EVENT, IS_ACTIVE_EVENT_LOADING, SET_ATTENDEES_LIST, SET_INITIAL_ATTENDEES, IS_EVENT_LIST_REQUEST_PROCESSING
} from "../actions/actionTypes";
import moment from "moment";

const initialState = {
    isCalendarLoading: true,
    eventsList: [],
    isEditModeToggled: false,
    editableFromDate: moment(),
    editableToDate: moment(),
    activeEvent: {
        attendees: null
    },
    isActiveEventLoading: true,
    initialAttendees: null,
    isEventRequestProcessing: false
};

const eventsReducer = (state = initialState, action : any) => {
    switch (action.type) {
        case IS_CALENDAR_LOADING:
            return {
                ...state,
                isCalendarLoading: action.payload
            };
        case EVENTS_LIST:
            return {
                ...state,
                eventsList: action.payload
            };
        case CLEAR_CALENDAR:
            return initialState;
        case IS_EDIT_MODE_TOGGLED:
            return {
                ...state,
                isEditModeToggled: !state.isEditModeToggled
            };
        case EDITABLE_FROM_DATE:
            return {
                ...state,
                editableFromDate: action.payload
            };
        case EDITABLE_TO_DATE:
            return {
                ...state,
                editableToDate: action.payload
            };
        case ACTIVE_EVENT:
            return {
                ...state,
                activeEvent: action.payload
            };
        case IS_ACTIVE_EVENT_LOADING:
            return {
                ...state,
                isActiveEventLoading: action.payload
            }
        case SET_ATTENDEES_LIST:
            if(action.payload && action.payload.length > 0)
                return {
                    ...state,
                    activeEvent: {
                        ...state.activeEvent,
                        attendees: [...action.payload]
                    }
                }
            else if(action.payload.length == 0)
                return {
                    ...state,
                    activeEvent: {
                        ...state.activeEvent,
                        attendees: [...action.payload]
                    }
                }
            else return state;
        case IS_EVENT_LIST_REQUEST_PROCESSING:
            return {
                ...state,
                isEventRequestProcessing: action.payload
            }
        case SET_INITIAL_ATTENDEES:
            return {
                ...state,
                initialAttendees: action.payload
            }
        default:
            return state;
    }
};

export default eventsReducer;